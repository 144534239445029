import { Field, FormikProvider, useFormik } from 'formik'
import { useEffect, useMemo } from 'react'
import SimpleBar from 'simplebar-react'

import { ReactComponent as SearchIcon } from 'assets/svg/search-icon.svg'
import {
	useCopyFolderMutation,
	useLazyGetDocumentPackagesQuery,
} from 'modules/documents/document-package-api'
import { Button } from 'theme/ui/forms'
import Spinner from 'theme/ui/spinner'
import { useDebounce } from 'use-debounce'
import notification from 'utilities/notification'
import DocumentList from './document-list'

const CopyToFolderForm = ({ document, folder, onClose }) => {
	const [copyFolder, { isLoading: isSaving }] = useCopyFolderMutation()

	const formik = useFormik({
		initialValues: {
			search: null,
			selectedFolder: null,
			folderTitle: folder?.title || document?.title,
		},
		onSubmit: async (value) => {
			try {
				let payload = {}
				if (folder) {
					payload = {
						parentDocumentPackage: value?.selectedFolder,
						documentPackage: folder?.id,
					}
				} else {
					payload = {
						parentDocumentPackage: value?.selectedFolder,
						document: document?.id,
					}
				}

				const response = await copyFolder(payload).unwrap()
				if (response?.status === 200) {
					notification(
						'success',
						`${document ? 'Document' : 'Folder'} successfully copied`,
					)
					onClose()
				}
			} catch (error) {
				notification('error', error?.message)
			}
		},
	})
	const [search] = useDebounce(formik?.values?.search, 300)
	const [fetchFolder, { data, isUninitialized, isLoading }] = useLazyGetDocumentPackagesQuery()

	useEffect(() => {
		const query = { page: 1, limit: 20 }
		if (search && search.length >= 3) {
			Object.assign(query, { search: search })
		}
		fetchFolder(query)
	}, [search])

	const folders = useMemo(
		() =>
			Array.isArray(data?.data)
				? data?.data.map((folder) => ({
						id: folder?.id,
						title: folder?.title,
						documents: Array.isArray(folder?.documents)
							? folder?.documents.map((d) => d?.id)
							: [],
				  }))
				: [],
		[data?.data],
	)

	return (
		<FormikProvider value={formik}>
			<form className='mb-3 flex-1' onSubmit={formik.handleSubmit}>
				<div className='flex h-[56px] w-full items-center space-x-1 rounded px-2.5 shadow'>
					<div className='px-2'>
						<SearchIcon height={20} width={20} />
					</div>
					<Field name='search'>
						{({ field }) => (
							<input
								type='text'
								{...field}
								className='flex-1 px-1 py-2 text-base font-normal text-gray2-dark'
								placeholder='Search folders'
							/>
						)}
					</Field>
				</div>
				<div className='mt-3 flex flex-col'>
					<div className='mb-3 flex flex-col justify-start gap-1'>
						<SimpleBar style={{ maxHeight: '300px' }}>
							{isUninitialized || isLoading ? (
								<Spinner />
							) : (
								<DocumentList
									folders={folders}
									document={document}
									formik={formik}
								/>
							)}
						</SimpleBar>
					</div>

					<div className='mt-3 flex w-full flex-col gap-2'>
						<Button
							isLoading={isSaving}
							isLoadingText='Copying...'
							type='submit'
							block={true}
							disabled={folders.length === 0}>
							Copy Now
						</Button>
					</div>
				</div>
			</form>
		</FormikProvider>
	)
}

export default CopyToFolderForm
