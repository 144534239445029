import { Fragment } from 'react'

import { MultiDocumentsDropdown } from './documents-dropdown'

const AssignDocuments = ({ formik }) => {
	return (
		<Fragment>
			<MultiDocumentsDropdown formik={formik} />

			{/* <DocumentPackageDropdown /> */}
		</Fragment>
	)
}

export default AssignDocuments
