import classNames from 'classnames'
import { Field, getIn } from 'formik'
import { capitalize } from 'lodash'
import { useState } from 'react'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

import Badge from 'theme/ui/badge'
import FilterButton from 'theme/ui/filter-button'
import { getStatusOptions } from '.'

const StatusDropdown = ({ name }) => {
	const [isOpen, setIsOpen] = useState(false)
	const toggleDropdown = () => setIsOpen(!isOpen)

	const statusOption = () => {
		return getStatusOptions().map((option) => ({
			label: <Badge variant={option?.variant}>{option?.label}</Badge>,
			value: option?.value,
		}))
	}

	return (
		<Field name={name}>
			{({ form }) => {
				const values = getIn(form?.values, name) || []
				const isActive = values?.length > 0

				const handleSelect = (option) => {
					const currentValues = Array.isArray(values) ? [...values] : []
					const index = currentValues.findIndex((item) => item.value === option.value)
					if (index > -1) {
						currentValues.splice(index, 1)
					} else {
						currentValues.push(option)
					}
					form.setFieldValue(name, currentValues)
				}

				return (
					<Dropdown isOpen={isOpen} toggle={() => toggleDropdown()}>
						<FilterButton
							isActive={isActive}
							onClear={() => form.setFieldValue(name, [])}>
							<DropdownToggle
								tag='button'
								type='button'
								className={classNames(
									'form-control z-10 max-w-fit',
									isActive ? '!bg-main/20 !text-main' : 'bg-white !text-gray-500',
								)}>
								<div className='flex items-center'>
									<span className='!ml-4'>Status</span>
									{isActive ? (
										<div className='flex items-center space-x-1'>
											<span className='ml-1 text-gray-500'>|</span>
											<span className='space-x-1 font-bold'>
												{values.length <= 1 ? (
													<span>
														{values
															.map((v) => capitalize(v?.value))
															.join(', ')}
													</span>
												) : (
													<span>{values.length} selected</span>
												)}
											</span>
										</div>
									) : undefined}
								</div>
							</DropdownToggle>
						</FilterButton>
						<DropdownMenu className='mt-2 px-0 py-2 shadow-md'>
							<ul className='m-0 p-0'>
								{statusOption().map((option, idx) => (
									<li
										key={idx}
										className={classNames(
											values.some((item) => item.value === option.value)
												? 'exp-dropdown-item-active'
												: 'exp-dropdown-item',
										)}>
										<label className='m-0 flex items-center space-x-1'>
											<input
												type='checkbox'
												className='form-check-input'
												checked={values.some(
													(item) => item.value === option.value,
												)}
												onChange={() => handleSelect(option)}
											/>
											<span>{option?.label}</span>
										</label>
									</li>
								))}
							</ul>
						</DropdownMenu>
					</Dropdown>
				)
			}}
		</Field>
	)
}

export default StatusDropdown
