import SearchBar from './search-bar'

export const getFormValues = (values) => {
	const $values = {}

	if (values?.search) {
		Object.assign($values, {
			search: values?.search,
		})
	}

	if (values?.status?.value) {
		Object.assign($values, {
			status: values?.status?.value,
		})
	}
	return $values
}

export default SearchBar
