import { FormikProvider, useFormik } from 'formik'
import { useEffect, useMemo } from 'react'
import { useDebounce } from 'use-debounce'

import { useAppRouter } from 'hooks'
import ViewByDropdown from 'modules/file-manager/view-by-dropdown'
import { useDataTable } from 'theme/ui/data-table'
import TableFiltersCard from 'theme/ui/table-filters-card'
import { getPaginationParams } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import { getActiveFilters, getFormikFilterValues, getInitialValues } from '.'

const Filters = ({ setPagination }) => {
	const { parseQuery, navigate, location } = useAppRouter()
	const initialValues = getInitialValues(parseQuery)
	const formik = useFormik({ initialValues: initialValues })
	const tableContext = useDataTable()

	const formValues = useMemo(() => formik?.values, [formik?.values])
	const [debounceValues] = useDebounce(formValues, 800)

	const resetFilters = () => {
		return new Promise((resolve) => {
			const filterObj = { page: 1, limit: 10 }
			const queryString = toQueryString(filterObj)
			navigate(`${window.location.pathname}?${queryString}`)
			resolve()
		})
	}

	const onResetFilters = async () => {
		resetFilters().then(() => {
			formik.handleReset()
		})
	}

	useEffect(() => {
		if (debounceValues) {
			const paginationParams = getPaginationParams(location?.search)
			const filterObj = {
				...getFormikFilterValues(debounceValues),
				page: 1,
				limit: paginationParams.limit,
			}
			const queryString = toQueryString(filterObj)
			navigate(`${window.location.pathname}?${queryString}`)
			if (tableContext) {
				tableContext.resetPageIndex()
			}
			setPagination({
				pageIndex: 0,
				pageSize: 10,
			})
		}
	}, [debounceValues])

	useEffect(() => {
		if (location?.search) {
			const paginationParams = getPaginationParams(location?.search)
			const currentPage = paginationParams?.page ? parseInt(paginationParams.page, 10) : 1
			const currentLimit = paginationParams?.limit ? parseInt(paginationParams.limit, 10) : 10
			if (currentPage > 1) {
				setPagination({
					pageIndex: currentPage - 1,
					pageSize: currentLimit,
				})
			}
		}
	}, [location?.search])

	const activeFilters = getActiveFilters(parseQuery)

	return (
		<FormikProvider value={formik}>
			<form>
				<div className='flex flex-col space-y-3'>
					{activeFilters.length > 0 ? (
						<TableFiltersCard
							filters={activeFilters}
							formik={formik}
							onResetFilters={onResetFilters}
						/>
					) : undefined}
					<div className='flex items-center justify-between pb-3'>
						<h3 className='m-0 text-base'>Starred</h3>

						<ViewByDropdown />
					</div>
				</div>
			</form>
		</FormikProvider>
	)
}

export default Filters
