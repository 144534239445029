import classNames from 'classnames'

const TbodySekeleton = ({ table, isCompact }) => {
	const pageSize = table.getState().pagination.pageSize
	const headerGroups = table.getHeaderGroups()[0].headers

	return (
		<tbody className='animate-pulse'>
			{Array(pageSize)
				.fill(0)
				.map((headerGroup, idx) => {
					return (
						<tr key={idx}>
							{headerGroups.map((header, idx) => {
								return (
									<td
										key={idx}
										style={{ width: `${header.getSize()}px` }}
										className={classNames(
											'w-full',
											isCompact ? '!px-2 py-2' : 'h-[48px] !px-4 py-2',
										)}>
										<span className='block !h-[20px] w-full rounded bg-slate-200'></span>
									</td>
								)
							})}
						</tr>
					)
				})}
		</tbody>
	)
}

export default TbodySekeleton
