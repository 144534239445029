import { createColumnHelper } from '@tanstack/react-table'
import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'

import DataTable from 'theme/ui/data-table'
import { formatDate } from 'utilities/helpers'
import TeamActions from './team-actions'
import ToggleTeamStatus from './toggle-team-status'

const TeamTable = ({ data, meta, isLoading, pagination, setPagination }) => {
	const [sorting, setSorting] = useState([])

	const columnHelper = createColumnHelper()

	const columns = [
		columnHelper.accessor('index', {
			size: 20,
			header: () => <span>#</span>,
			cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		}),

		columnHelper.accessor((row) => row.team_name, {
			id: 'team_name',
			size: 200,
			header: () => <span>Team</span>,
			cell: (info) => (
				<span className='line-clamp-1 font-bold' title={info.getValue()}>
					{info.getValue()}
				</span>
			),
		}),

		columnHelper.accessor((row) => row?.teamOwner, {
			id: 'owner_details',
			enableSorting: false,
			header: () => <span>Owner Details</span>,
			cell: (info) => {
				return (
					<div className='flex flex-col'>
						<Link
							to={`/users/${info.getValue()?.id}/show`}
							target='_blank'
							className='text-sm font-bold text-main hover:!underline'>
							{info.getValue()?.fullName}
						</Link>
						<span className='text-xs text-gray-400'>{info.getValue()?.email}</span>
					</div>
				)
			},
		}),

		// columnHelper.accessor((row) => row.subscription, {
		// 	id: 'subscription',
		// 	size: 220,
		// 	header: () => <span>Subscription</span>,
		// 	cell: (info) =>
		// 		info.getValue() ? (
		// 			<div>
		// 				<div className='flex items-baseline space-x-2'>
		// 					<span className='font-bold capitalize text-gray-800'>
		// 						{info.getValue()?.plan?.plan_type}
		// 					</span>
		// 					<Badge
		// 						variant={
		// 							info.getValue()?.status === 'Active' ? 'success' : 'danger'
		// 						}>
		// 						{info.getValue()?.status}
		// 					</Badge>
		// 				</div>
		// 				<div className='flex items-baseline space-x-1 text-xs text-gray-400'>
		// 					<span>{formatPrice(info.getValue()?.plan_price, 'USD')}</span>
		// 					<span>{getDuration(info.getValue()?.plan?.duration)}</span>
		// 				</div>
		// 			</div>
		// 		) : (
		// 			'-'
		// 		),
		// }),
		columnHelper.accessor((row) => row, {
			id: 'status',
			size: 100,
			header: () => <span>Status</span>,
			cell: (info) => <ToggleTeamStatus team={info.getValue()} />,
		}),
		columnHelper.accessor((row) => row, {
			id: 'created_at',
			enableSorting: false,
			header: () => (
				<div className='!w-full'>
					<span className='float-right'>Created At</span>
				</div>
			),
			cell: (info) => (
				<div className='!w-full'>
					<span className='float-right capitalize'>
						{formatDate(info?.getValue()?.created_at)}
					</span>
				</div>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'actions',
			enableSorting: false,
			header: () => (
				<div className='flex !w-full items-center justify-end'>
					<span className='float-right'>Actions</span>
				</div>
			),
			cell: (info) => <TeamActions team={info.getValue()} />,
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={data}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			setSorting={setSorting}
			sorting={sorting}
			meta={meta}
		/>
	)
}

export default TeamTable
