import { createApi } from '@reduxjs/toolkit/query/react'

import apiEndpoints from 'helpers/apiEndpoints'
import axiosBaseQuery from 'store/axios-base-query'
const API_URL = apiEndpoints.group

export const roleApi = createApi({
	reducerPath: 'Groups',
	refetchOnFocus: true,
	keepUnusedDataFor: 0,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['Groups'],
	endpoints: (builder) => ({
		getRoles: builder.query({
			keepUnusedDataFor: 0,
			query: (params) => ({
				url: API_URL.groups,
				method: 'GET',
				params: params,
			}),
			providesTags: ['Groups'],
			transformResponse: (response, _, arg) => {
				const roleData = Array.isArray(response?.data?.groups)
					? response?.data?.groups.map((role, ind) => ({
							ind: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + ind + 1,
							id: role.id,
							name: role.name,
							description: role.description,
							is_active: role.is_active ? 'Active' : 'Inactive',
							created_at: role.created_at,
					  }))
					: []
				return {
					roleData,
					meta: response?.data?.meta,
				}
			},
		}),
	}),
})

export const { useLazyGetRolesQuery } = roleApi
