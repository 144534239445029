import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

import chevronDownIcon from 'assets/images/svg-icons/chevron-down-icon.svg'
import AssigneeFilter from './AssigneeFilter'

const AssigneeDropdown = () => {
	const [isOpen, setIsOpen] = useState(false)
	const values = useSelector((state) => state?.form?.kanban_filter?.values)
	const numberOfFilters = useMemo(
		() => Array.isArray(values?.assignee_id) && values?.assignee_id?.length > 0,
		[values],
	)

	return (
		<ButtonDropdown
			isOpen={isOpen}
			toggle={() => setIsOpen(!isOpen)}
			className='p-0 alignment_full'
			direction='left'>
			<DropdownToggle caret className='btn btn-sm btn-outline-secondary relative !px-1'>
				<div className='flex items-center justify-between space-x-2'>
					<span>Assignee</span>
					<span>
						<img src={chevronDownIcon} className='h-4 w-4' />
					</span>
				</div>

				{numberOfFilters ? (
					<div className='bg-primary absolute -right-2 -top-2 inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-white text-xs font-medium text-white '>
						1
					</div>
				) : undefined}
			</DropdownToggle>
			<DropdownMenu onClick={() => setIsOpen(true)} className='!mt-10 !p-0'>
				<DropdownItem
					tag='div'
					className='rounded !p-0 shadow-[2px_4px_8px_1px_rgba(73,80,87,0.14)]'>
					<AssigneeFilter />
				</DropdownItem>
			</DropdownMenu>
		</ButtonDropdown>
	)
}

export default AssigneeDropdown
