import { SpinLoader } from 'components/Common'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Card, CardBody } from 'reactstrap'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'
import Badge from 'theme/ui/badge'
import { formatDate } from 'utilities/helpers'
import apiEndpoints from '../../helpers/apiEndpoints'
import * as actions from '../../store/common/actions'
import UnAuthorize from '../StaticPages/UnAuthorize'

const UserDetails = (props) => {
	const { id } = props.match.params
	const dispatch = useDispatch()
	const [user, setUser] = useState(null)
	const { loading } = useSelector((state) => state.common)
	const isSuperAdmin = useSelector((state) => state.Permissions.isSuperAdmin)
	const history = useHistory()
	useEffect(() => {
		if (id && isSuperAdmin) {
			dispatch(actions.find(apiEndpoints.users, id)).then((response) => {
				if (response && response.status === 200) {
					setUser(response.data)
				}
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	const groups = user && user.groups ? user.groups : []

	return (
		<AppLayout
			meta={{
				title: 'User Details',
			}}>
			{isSuperAdmin ? (
				<div className='page-content  px-0 !pt-[70px]'>
					<div className='page-container'>
						<PageHeader title='User Details' hasBackButton={true} />
						<div className='page-body'>
							<Card className='rounded-lg border border-gray-200 shadow-md'>
								<CardBody className='rounded-b-lg bg-white p-6'>
									{loading ? (
										<div className='flex items-center justify-center'>
											<SpinLoader />
										</div>
									) : (
										<>
											<div className='mb-6 rounded border border-gray-200 bg-gray-50 p-6'>
												<div className='flex flex-col justify-between gap-2  md:!flex-row  '>
													<div>
														<span className='block text-sm font-medium text-gray-600'>
															Name
														</span>
														<p className='text-md mt-1 font-bold text-gray-800'>
															{user?.first_name && user?.last_name
																? `${user.first_name} ${user.last_name}`
																: '-'}
														</p>
													</div>
													<div>
														<span className='block text-sm font-medium text-gray-600'>
															Email
														</span>
														<p className='text-md mt-1 font-bold text-gray-800'>
															{user?.email || '-'}
														</p>
													</div>
													<div>
														<span className='block text-sm font-medium text-gray-600'>
															Phone Number
														</span>
														<p className='text-md mt-1 font-bold text-gray-800'>
															{user?.phone_number || '-'}
														</p>
													</div>
													<div>
														<span className='block text-sm font-medium text-gray-600'>
															Created At
														</span>
														<p className='text-md mt-1 font-bold text-gray-800'>
															{formatDate(user?.created_at)}
														</p>
													</div>
													<div>
														<span className='block text-sm font-medium text-gray-600'>
															Status
														</span>
														<p className='text-md mt-1 font-bold'>
															<Badge
																variant={
																	user?.status === 1
																		? 'success'
																		: 'danger'
																}
																className={` px-3 py-1 ${
																	user?.status === 1
																		? 'bg-green-100 text-green-800'
																		: 'bg-red-100 text-red-800'
																}`}>
																{user?.status === 1
																	? 'Active'
																	: 'Inactive'}
															</Badge>
														</p>
													</div>
												</div>
											</div>

											<div className='mt-6'>
												<div className='mb-4 text-lg font-semibold text-gray-800'>
													Team Details
												</div>
												<div className='overflow-x-auto rounded-lg border border-gray-200 bg-gray-50'>
													<table className='w-full table-auto border-collapse'>
														<thead className=' bg-gray-100'>
															<tr>
																<th className='border border-gray-300 px-4 py-3 text-left text-sm font-medium text-gray-600'>
																	#
																</th>
																<th className='border border-gray-300 px-4 py-3 text-left text-sm font-medium text-gray-600'>
																	Team
																</th>
																<th className='border border-gray-300 px-4 py-3 text-left text-sm font-medium text-gray-600'>
																	Role
																</th>
															</tr>
														</thead>
														<tbody>
															{groups.map((group, index) => (
																<tr
																	key={index}
																	className='scale-[0.99] transform border border-gray-300 transition-transform hover:scale-[1] hover:shadow-lg'>
																	<td className='px-4 py-3 text-sm text-gray-700'>
																		{index + 1}
																	</td>
																	<td className='px-4 py-3 text-sm text-gray-700'>
																		{group?.team?.team_name ||
																			'-'}
																	</td>
																	<td className='px-4 py-3 text-sm text-gray-700'>
																		{group?.group?.name || '-'}
																	</td>
																</tr>
															))}
														</tbody>
													</table>
												</div>
											</div>
										</>
									)}
								</CardBody>
							</Card>
						</div>
					</div>
				</div>
			) : (
				<UnAuthorize />
			)}
		</AppLayout>
	)
}

export default UserDetails
