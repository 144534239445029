import { useState } from 'react'

import { useLazyCheckEditEligibilityQuery } from 'modules/documents/document-api'
import RenamePopup from '../rename-popup'
import OptionsList from './option-list'

const Actions = ({ document }) => {
	const [isOpen, setIsOpen] = useState(false)
	const [checkEditEligibility] = useLazyCheckEditEligibilityQuery()

	const onRenameOpen = async (id) => {
		const response = await checkEditEligibility(id).unwrap()
		if (response?.status === 200) {
			setIsOpen(true)
		}
	}

	return (
		<div className='float-right'>
			<RenamePopup document={document} isOpen={isOpen} onClose={() => setIsOpen(false)} />
			{/* {isActionActive ? (
				<div className='flex w-full items-center space-x-1'>
					<span
						className='text-sm font-medium text-main hover:cursor-pointer'
						onClick={() => attachInWorkflow(document, 'document')}>
						<span>Use in Workflow</span>
						<i className='uil-angle-right text-base '></i>
					</span>
				</div>
			) : undefined} */}
			<OptionsList document={document} onRenameOpen={() => onRenameOpen(document?.id)} />
		</div>
	)
}

export default Actions
