import { Fragment } from 'react'

import { useAppRouter } from 'hooks'
import { FileManagerLayout } from 'modules/file-manager'
import { SearchFoldersListingContainer } from 'modules/file-manager/all-folders-listing'
import MyDocumentsListingContainer, {
	SearchDocumentsListingContainer,
} from 'modules/file-manager/my-documents-listing'
import { TableProvider } from 'theme/ui/data-table'

const GlobalSearch = () => {
	const { parseQuery } = useAppRouter()

	return (
		<FileManagerLayout
			meta={{
				title: 'File Manager - Search',
			}}>
			<div>
				{parseQuery?.status === 'documents' ? (
					<Fragment>
						{parseQuery?.search ? (
							<SearchDocumentsListingContainer />
						) : (
							<MyDocumentsListingContainer />
						)}
					</Fragment>
				) : (
					<TableProvider>
						<SearchFoldersListingContainer />
					</TableProvider>
				)}
			</div>
		</FileManagerLayout>
	)
}

export default GlobalSearch
