import { TEAM_APP_ROUTES } from 'modules/teams/routes'
import { useRemoveUserMutation } from 'modules/teams/teams-api'
import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import DeleteConfirmAlert from 'theme/ui/delete-confirm-alert'
import notification from 'utilities/notification'

const ClientAction = ({ user, team }) => {
	const [isOpen, setIsOpen] = useState(false)
	const onOpen = () => setIsOpen(true)
	const onClose = () => setIsOpen(false)

	const [removeUser, { isLoading }] = useRemoveUserMutation()
	const onRemoveUser = async (teamId, userId) => {
		try {
			const response = await removeUser({ teamId, userId })
			if (response?.data?.status === 200) {
				notification('success', response?.data?.message)
			}
		} catch (error) {
			notification('warn', error?.message)
		}
	}
	return (
		<Fragment>
			<DeleteConfirmAlert
				isDeleting={isLoading}
				isOpen={isOpen}
				onClose={onClose}
				onConfirm={() => onRemoveUser(team?.id, user?.id)}
			/>
			<div className='flex space-x-2'>
				<Link
					// to={{
					// 	pathname: `/teams/users/${user.id}/edit`,
					// 	state: { team: { id: Id, name: team?.team_name } },
					// }}
					to={TEAM_APP_ROUTES.editUser(team?.id, user?.id)}
					className='action-btn btn-info-outline'>
					<i className='uil uil-pen' style={{ fontSize: 16 }} />
					<span>Edit</span>
				</Link>
				&nbsp;
				<button className='action-btn btn-red-outline' onClick={onOpen}>
					<i className='uil uil-trash-alt' style={{ fontSize: 16 }} />
					<span>Delete</span>
				</button>
			</div>
		</Fragment>
	)
}

export default ClientAction
