const TagList = ({ tags }) => {
	const colors = [
		'bg-blue-100 text-blue-800',
		'bg-green-100 text-green-800',
		'bg-gray-100 text-gray-800',
		'bg-red-100 text-red-800',
		'bg-purple-100 text-purple-800',
	];

	return (
		<div className='flex flex-row gap-2 items-center'>
			{/* Display the first tag */}
			{tags.length > 0 && (
				<span
					className={`tag ${
						colors[0 % colors.length]
					} rounded px-2 py-1 text-xs font-medium`}>
					{tags[0]?.tag}
				</span>
			)}

			{/* If there are multiple tags, show the count badge */}
			{tags.length > 1 && (
				<span className="count-badge bg-blue-100 text-blue-800 rounded-full px-2 py-1 text-xs font-medium">
					+{tags.length - 1}
				</span>
			)}
		</div>
	);
};

export default TagList;
