import { Fragment, useState } from 'react'
import { FileIcon, defaultStyles } from 'react-file-icon'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { PdfViewer } from 'theme/ui/document-preview'

const FileCard = ({ file }) => {
	let [isOpen, setIsOpen] = useState(false)

	function openModal() {
		setIsOpen(true)
	}
	const toggle = () => setIsOpen(!isOpen)

	const [type, extension] = file?.files?.mime_type.split('/') || []
	return (
		<Fragment>
			<Modal isOpen={isOpen} toggle={toggle} size='lg' fullscreen='lg' backdrop='static'>
				<ModalHeader toggle={toggle}>Document Preview</ModalHeader>
				<ModalBody className='p-0'>
					{type === 'image' ? (
						<div
							className='mx-auto flex items-center
                         justify-center p-3'>
							<img src={file?.files?.path} className='h-auto w-[600px]' />
						</div>
					) : undefined}

					{type === 'application' && extension === 'pdf' ? (
						<PdfViewer src={file?.files?.path} />
					) : undefined}
				</ModalBody>
			</Modal>
			<div className='flex min-w-[200px] items-center justify-between space-x-3 rounded-md !border bg-white px-3 py-2'>
				<div className='flex items-center space-x-3 '>
					<div className='h-auto w-9'>
						<FileIcon extension={file?.extension} {...defaultStyles[file?.extension]} />
					</div>
					<div className='flex flex-col'>
						<span className='font-semibold'>{file?.title}</span>
						{/* <span
							className='line-clamp-1 text-xs text-gray-500'
							title={file?.files?.original_name}>
							{file?.files?.original_name}
						</span> */}
					</div>
				</div>

				<a
					onClick={(e) => {
						e.preventDefault()
						openModal()
					}}
					href={file?.files?.path}
					className='link'>
					View
				</a>
			</div>
		</Fragment>
	)
}

export default FileCard
