import classNames from 'classnames'
import ToolTip from 'theme/ui/tool-tip'
import { isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'

const Actions = ({ document, toggle, index }) => {
	const fileUrl = document?.file_url

	const documentObj = {
		fileUrl,
		id: document?.documentId,
		workflow_step_document_id: document?.workflow_step_id,
	}

	const canDownload = isAllowTo(permissions?.completedDocument?.download) && fileUrl
	const canView = isAllowTo(permissions?.completedDocument?.view) && fileUrl

	return (
		<div className='flex w-full space-x-3'>
			<ToolTip targetId={`view_${index}`} tooltipText='View Document' />
			<ToolTip targetId={`download_${index}`} tooltipText='Download Document' />
			<button
				type='button'
				id={`view_${index}`}
				disabled={!canView}
				className={classNames('action-btn', {
					'btn-primary-outline': canView,
					'btn-gray-outline': !canView,
				})}
				onClick={() => canView && toggle(documentObj)}>
				<i className='uil uil-eye' style={{ fontSize: 16 }} />
			</button>
			<a
				href={fileUrl}
				download
				id={`download_${index}`}
				className={`action-btn ${
					fileUrl && canDownload ? 'btn-info-outline cursor-pointer' : 'btn-gray-outline'
				}`}>
				<i className='uil uil-import' style={{ fontSize: 16 }} />
			</a>
		</div>
	)
}

export default Actions
