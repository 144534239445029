import { Field, FormikProvider, useFormik } from 'formik'
import apiEndpoints from 'helpers/apiEndpoints'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, CheckboxField, InputField, isRequiredField } from 'theme/ui/forms'
import notification from 'utilities/notification'
import request from 'utilities/request'
import { getInitialValues, getPayload, validationSchema } from '.'
import { ROUTE_KEY } from '..'
import TeamDropdownField from './team-dropdown-field'

const SystemFieldForm = ({ field }) => {
	const history = useHistory()
	const initialValues = useMemo(() => getInitialValues(field), [field])

	const onCreate = async (values) => {
		const payload = getPayload(values)
		return request.post(apiEndpoints.systemFields, payload).then((response) => {
			if (response?.status === 200) {
				notification('success', response?.message)
				history.push(ROUTE_KEY)
			}
		})
	}

	const onUpdate = async (values, id) => {
		const payload = getPayload(values)
		return request.patch(`${apiEndpoints.systemFields}/${id}`, payload).then((response) => {
			if (response?.status === 200) {
				notification('success', response?.message)
				history.push(ROUTE_KEY)
			}
		})
	}

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema: validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true)
				if (field?.id) {
					await onUpdate(values, field?.id)
				} else {
					await onCreate(values)
				}
				setSubmitting(false)
			} catch (error) {
				setSubmitting(false)
			}
		},
	})

	const formValues = useMemo(() => formik?.values, [formik?.values])

	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				<div className='flex flex-col divide-y items-center'>
					<div className='flex items-start justify-center py-[20px]'>
						{/* <div>
							<h3 className='text-lg font-bold'>Field Details</h3>
							<p className='text-xs text-[#74788D]'>Enter the fields details here</p>
						</div> */}
						<div className='flex w-[440px] alignment_full flex-col space-y-6 rounded bg-white p-3'>
							<Field
								type='text'
								label='Enter Field Name'
								name='field_name'
								id='field_name'
								component={InputField}
								isRequiredField={isRequiredField(validationSchema, 'field_name')}
							/>

							<Field
								type='checkbox'
								label='Is System Field'
								name='is_system_field'
								id='is_system_field'
								helperText='To add a field related to the users profile parameter, please check the checkbox.'
								component={CheckboxField}
								isRequiredField={true}
							/>

							<TeamDropdownField
								isDisabled={formValues?.is_system_field}
								isRequiredField={true}
							/>
						</div>
					</div>

					<div className='flex w-[440px] alignment_full justify-end py-[20px]'>
						<Button
							type='submit'
							onClick={formik.handleSubmit}
							isLoading={formik?.isSubmitting}>
							Save
						</Button>
					</div>
				</div>
			</form>
		</FormikProvider>
	)
}

export default SystemFieldForm
