import { AppLayout } from 'theme/layouts'
import SearchBar from '../search-bar'
import Sidebar from '../sidebar'

const FileManagerLayout = ({ children, meta }) => {
	return (
		<AppLayout
			meta={{
				title: meta?.title,
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					{/* <PageHeader title='File Manager' /> */}
					<SearchBar />
					<div
						className='page-body navbar_padding_full'
						style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}>
						<div className='upload_doc_alignment flex min-h-[70vh] gap-4'>
							<div className='min-w-[240px]'>
								<Sidebar />
							</div>
							<div className='extra_padding flex-1 overflow-auto'>{children}</div>
						</div>
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default FileManagerLayout
