import { forwardRef, useState } from 'react'
import DatePicker from 'react-datepicker'

// import CustomLabel from './custom-label'
import moment from 'moment'
import './react-datepicker.css'

const Datepicker = ({ onChange }) => {
	const [dateRange, setDateRange] = useState([null, null])
	const [startDate, endDate] = dateRange

	const CustomLabel = forwardRef(({ onClick }, ref) => (
		<button
			className='exp-dropdown-item w-full text-left'
			type='button'
			onClick={onClick}
			ref={ref}>
			Custom Range
		</button>
	))
	CustomLabel.displayName = 'CustomLabel'

	return (
		<DatePicker
			selectsRange={true}
			startDate={startDate}
			endDate={endDate}
			onChange={(update) => {
				const [start, end] = update
				setDateRange(update)
				return onChange(
					[moment(start).format('yyyy-MM-DD'), moment(end).format('yyyy-MM-DD')].join(
						',',
					),
				)
			}}
			customInput={<CustomLabel />}
			wrapperClassName='w-full'
		/>
	)
}

export default Datepicker
