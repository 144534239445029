import { useEffect, useRef } from 'react'

import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import { useAppRouter } from 'hooks'
import { fieldsTabs } from '.'
import PdfFields from './pdf-fields'
import DeletedPdfFields from './pdf-fields/delete-pdf-fields'
import PdfPreview from './pdf-viewer'

const FieldMapping = ({ formik, document, onAutoSave, isAutoSaving }) => {
	const pdfPreviewRef = useRef()
	const { params } = useAppRouter()
	const documentId = document?.id || params?.id

	useEffect(() => {
		onAutoSave()
	}, [formik?.values?.delete_document_mapping])

	return (
		<div className='flex justify-between gap-6 field_mapping'>
			<div className='w-3/5 space_full'>
				{document?.file_path && (
					<PdfPreview
						isAutoSaving={isAutoSaving}
						url={document?.file_path}
						documentId={documentId}
						ref={pdfPreviewRef}
					/>
				)}
			</div>
			<div className='w-2/5 space_full'>
				<Tab.Group>
					<Tab.List className='grid grid-cols-2 divide-x rounded-t !border '>
						{fieldsTabs.map((tab, idx) => (
							<Tab
								key={idx}
								className={({ selected }) =>
									classNames('m-0 !py-3 text-lg font-bold', {
										'bg-[#EDEFFB] ': selected,
									})
								}>
								{tab.label}
							</Tab>
						))}
					</Tab.List>
					<Tab.Panels>
						<Tab.Panel>
							<PdfFields
								formik={formik}
								onAutoSave={onAutoSave}
								onFieldHighlight={pdfPreviewRef.current}
								document={document}
							/>
						</Tab.Panel>
						<Tab.Panel>
							<DeletedPdfFields
								formik={formik}
								onAutoSave={onAutoSave}
								onFieldHighlight={pdfPreviewRef.current}
								document={document}
							/>
						</Tab.Panel>
					</Tab.Panels>
				</Tab.Group>
			</div>
		</div>
	)
}

export default FieldMapping
