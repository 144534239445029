import { useAppRouter } from 'hooks'
import { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import * as actions from 'store/file-manager/actions'
import OptionsList from './option-list'

const Actions = ({ isActionActive, type, data }) => {
	const isMobile = useMediaQuery({query: '(max-width: 1024px'})
	const shouldShowActions = isMobile || isActionActive
	const dispatch = useDispatch()
	const { navigate } = useAppRouter()
	const attachInWorkflow = (data) => {
		const item = {
			title: data?.title,
			value: JSON.stringify({
				id: data?.id,
				minClients: data?.minClients,
			}),
		}

		dispatch(actions.setSelectedDocuments(item))
		navigate(`/workflows/create?document=true`)
	}
	return (
		<div
			className='float-end flex w-[150px] items-center justify-end space-x-2  text-right'
			onClick={(event) => event.stopPropagation()}>
			{shouldShowActions ? (
				<Fragment>
					{type === 'document' ? (
						<Link
							className='text-sm font-medium'
							onClick={() => attachInWorkflow(data)}>
							<span>Use in workflow</span>
							<i className='uil-angle-right text-base'></i>
						</Link>
					) : (
						<>{/* <AddOptions data={data} /> */}</>
					)}

					<OptionsList data={data} type={type} />
				</Fragment>
			) : undefined}
		</div>
	)
}

export default Actions
