import { Field } from 'formik'
import { useState } from 'react'

import { Button } from 'theme/ui/forms'
import SystemFieldDropdown from '../system-field-dropdown'
import UserDropdown from '../user-dropdown'
import DocumentBoundField from './document-bound-field'

const FormRow = ({
	field,
	formik,
	onRemove,
	pdf_field,
	fieldName,
	onAutoSave,
	formValues,
	onHighlight,
	userOptions,
}) => {
	const [isDeleting, setIsDeleting] = useState(false)
	const onMoveToTrash = ($field) => {
		return new Promise((resolve) => {
			const delete_document_mapping = formValues?.delete_document_mapping
			formik.setFieldValue('delete_document_mapping', [...delete_document_mapping, $field])
			resolve({ message: 'field successfully moved to trash' })
		})
	}

	const onDelete = async ($field) => {
		try {
			setIsDeleting(true)
			onRemove()
			await onMoveToTrash($field)
			setIsDeleting(false)
		} catch (error) {
			setIsDeleting(false)
		}
	}

	return (
		<div className='rounded !px-2 !py-4 transition-all hover:bg-slate-50'>
			<div className='!mb-2 flex items-start justify-between gap-2 fields_system'>
				<SystemFieldDropdown
					label={field?.pdf_field}
					name={`${fieldName}.field`}
					onAutoSave={onAutoSave}
					onHighlight={() => onHighlight(pdf_field)}
				/>
				<Field
					type='hidden'
					name={`${fieldName}.pdf_field`}
					value={field?.pdf_field}
					component='input'
				/>
				<UserDropdown
					name={`${fieldName}.user`}
					options={userOptions}
					onAutoSave={onAutoSave}
				/>
				<div className='pt-[33px] remove_topspacing'>
					<Button
						type='button'
						size='xs'
						isLoading={isDeleting}
						hideLoadingText={true}
						variant='danger-outline'
						onClick={() => onDelete(field)}>
						<i className='uil-trash-alt' />
					</Button>
				</div>
			</div>

			<DocumentBoundField fieldName={`${fieldName}.is_document_bound`} />
		</div>
	)
}

export default FormRow
