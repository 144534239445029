import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import classNames from 'classnames'
import { useAppRouter } from 'hooks'
import { useState } from 'react'
import ReactDOM from 'react-dom'
import { Button } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { useUpdateEmailTemplateMutation } from '../email-templates-api'
import { EMAIL_TEMPLATES_APP_ROUTES } from '../routes'
import EmailTemplatePreview from './email-template-preview'
const EmailTemplatesForm = ({ emailTemplate }) => {
	const [emailData, setEmailData] = useState(emailTemplate?.content)
	const [emailSubject, setEmailSubject] = useState(emailTemplate?.subject)
	const initialData = emailTemplate?.content
	const initialSubject = emailTemplate?.subject
	const { goBack, navigate, hasHistory } = useAppRouter()
	const handleEmailChange = (event, editor) => {
		const data = editor.getData()
		setEmailData(data)
	}
	const handleEmailSubject = (e) => {
		setEmailSubject(e.target.value)
	}
	const [updateEmailTemplate] = useUpdateEmailTemplateMutation()
	const convertEmailData = (data) => {
		return data
			.replace(/&lt;/g, '<')
			.replace(/&gt;/g, '>')
			.replace(/<\/p><p>/g, '</p>\n\n<p>')
	}
	const resetEmailData = () => {
		setEmailData(initialData)
		setEmailSubject(initialSubject)
	}
	const saveTemplate = async () => {
		try {
			const convertedData = convertEmailData(emailData)
			const payload = {
				content: convertedData,
				subject: emailSubject,
			}
			let response
			if (emailTemplate) {
				response = await updateEmailTemplate({ payload: payload, id: emailTemplate?.id })
			}

			if (response?.data?.status === 200) {
				notification('success', response?.data?.message)
				return hasHistory ? goBack() : navigate(EMAIL_TEMPLATES_APP_ROUTES.findAll())
			}
		} catch (error) {
			notification('warn', error?.message)
		}
	}
	const handleButtonClick = () => {
		const popup = window.open('', 'Popup Window', 'width=600,height=400')

		if (popup) {
			popup.document.title = emailSubject
			ReactDOM.render(
				<EmailTemplatePreview emailData={emailData} title={emailSubject} />,
				popup.document.body,
			)
		}
	}

	return (
		<div>
			<div className=''>
				<h5 className='mb-2 text-lg '>Email Subject : </h5>
				<input
					type='text'
					value={emailSubject}
					onChange={(e) => handleEmailSubject(e)}
					placeholder='Enter email subject'
					className='w-full rounded !border border-slate-300 px-2 py-2'
				/>
			</div>
			<div className='mt-3'>
				<CKEditor editor={ClassicEditor} data={emailData} onChange={handleEmailChange} />
				<div className='flex justify-between gap-3'>
					<div>
						<Button
							variant={'primary'}
							className='mt-3 flex items-center rounded !border-gray-500 px-3 py-1'
							onClick={handleButtonClick}>
							Preview
						</Button>
					</div>
					<div className='flex gap-3'>
						<Button
							variant={'primary'}
							className='mt-3 flex items-center rounded !border-gray-500 px-3 py-1'
							onClick={saveTemplate}>
							Save
						</Button>
						<Button
							variant={'primary-outline'}
							className={classNames(
								'mt-3 flex items-center rounded !border-gray-500 px-3 py-1 !font-semibold',
							)}
							onClick={resetEmailData}>
							Reset
						</Button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default EmailTemplatesForm
