import { useUser } from 'hooks'
import { useLazyGetAnnouncementListQuery } from 'modules/announcements/announcements-api'
import { Fragment, useEffect, useMemo } from 'react'
import Announcements from './announcements'

const AnnouncementsList = () => {
	const [fetchAnnouncement, { data }] = useLazyGetAnnouncementListQuery()
	const { team, user } = useUser()
	useEffect(() => {
		if (team?.id && user?.id) {
			const payload = { team_id: team?.id, user_id: user?.id }
			fetchAnnouncement(payload)
		}
	}, [user])

	const announcementData = useMemo(
		() => (data?.announcement.length > 0 ? data?.announcement : []),
		[data],
	)

	return (
		<Fragment>
			{announcementData.length > 0 ? (
				<div className='p-2'>
					{announcementData?.map((announcement) => {
						return <Announcements key={announcement?.id} announcement={announcement} />
					})}
				</div>
			) : undefined}
		</Fragment>
	)
}

export default AnnouncementsList
