import Badge from 'theme/ui/badge'
import PopoverButtons from 'theme/ui/popover'

const FoldersColumn = ({ folders }) => {
	return (
		<div>
			{folders.length > 0 ? (
				<div className='flex max-w-[165px] flex-wrap items-center gap-1'>
					{folders?.length > 0 && (
						<div className='flex flex-wrap items-center gap-2'>
							<Badge
								variant='primary-outline'
								className='cursor-pointer'
								rounded={false}>
								{folders[0]}
							</Badge>
							{folders?.length > 1 ? (
								<PopoverButtons
									rounded={false}
									variant='primary-outline'
									data={folders}
									title={'Folders'}
								/>
							) : undefined}
						</div>
					)}
				</div>
			) : undefined}
		</div>
	)
}

export default FoldersColumn
