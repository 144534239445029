import { ReactComponent as AlignIcon } from 'assets/svg/align-justify-icon.svg'
import { ReactComponent as DocumentIcon } from 'assets/svg/document-icon.svg'

const DocumentBadge = ({ title, onRemove, idx }) => {
	return (
		<div
			key={idx}
			className='document-item flex items-center rounded !border border-[#F5F6F8] font-normal text-gray-700'>
			<div className='drag-selector flex h-full cursor-grab items-center justify-center bg-[#F5F6F8] px-[12px] py-2 active:cursor-grabbing'>
				<span>
					<AlignIcon />
				</span>
			</div>
			<div className='flex w-full items-center justify-between  px-2 py-1.5'>
				<div className='flex items-center space-x-2'>
					<span>
						<DocumentIcon />
					</span>
					<span className='text-xs font-medium text-[#495057]'>{title}</span>
				</div>
				<button
					type='button'
					className='inline-flex items-center rounded-sm  p-1 text-sm text-gray-400 hover:border-gray-300 hover:bg-red-300 hover:text-red-600 '
					onClick={onRemove}>
					<svg
						className='h-2 w-2'
						aria-hidden='true'
						xmlns='http://www.w3.org/2000/svg'
						fill='none'
						viewBox='0 0 14 14'>
						<path
							stroke='currentColor'
							strokeLinecap='round'
							strokeLinejoin='round'
							strokeWidth='2'
							d='m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6'
						/>
					</svg>
					<span className='sr-only'>Remove badge</span>
				</button>
			</div>
		</div>
	)
}

export default DocumentBadge
