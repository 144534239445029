import { useEffect, useState } from 'react'

import { useAppRouter } from 'hooks'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import { useLazyGetStarredFilesQuery } from '../file-manager-api'
import Filters from './filters'
import StarredExpandableTable from './starred-listing-table/starred-expandable-table'

const StarredListingContainer = () => {
	const { location, navigate } = useAppRouter()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination

	const [fetchStarredFiles, { data, isUninitialized, isLoading }] = useLazyGetStarredFilesQuery()
	useEffect(() => {
		if (pagination) {
			const parseQueryString = getParseQueryString(location?.search)
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			navigate(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	useEffect(() => {
		if (location?.search) {
			fetchStarredFiles(getParseQueryString(location?.search))
		}
	}, [location?.search])
	return (
		<div className='divide-y'>
			<Filters setPagination={setPagination} />

			<div className='pt-4'>
				<StarredExpandableTable
					data={data?.data}
					pagination={pagination}
					meta={data?.meta}
					setPagination={setPagination}
					isLoading={isUninitialized || isLoading}
				/>
			</div>
		</div>
	)
}

export default StarredListingContainer
