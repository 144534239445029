import { Fragment, useRef } from 'react'
import { Progress } from 'reactstrap'

import fileIcon from 'assets/svg/file-icon.svg'
import classNames from 'classnames'
import moment from 'moment'
import { Button } from 'theme/ui/forms'
import { formatBytes } from 'utils/commonFunctions'

const UploadFileCard = ({
	file,
	progress,
	uploadedSize = 0,
	time,
	onDelete,
	disabled,
	isDeleting,
	document,
}) => {
	const fileSize = formatBytes(file?.size)
	const fileName = file?.original_name
	const containerRef = useRef(null)

	const overlayStyle = {
		width: containerRef.current?.offsetWidth || 0,
		height: containerRef.current?.offsetHeight || 0,
	}

	return (
		<div
			className={classNames('upload-card rounded bg-white', {
				disabled: disabled,
			})}
			ref={containerRef}>
			<div
				className={classNames('absolute h-[140px]', {
					'flex cursor-not-allowed select-none items-center justify-center rounded bg-white bg-opacity-50':
						disabled,
				})}
				style={disabled ? overlayStyle : {}}>
				{disabled ? (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='20'
						height='20'
						viewBox='0 0 24 24'>
						<path
							fill={'#5b73e8'}
							d='M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z'
							opacity='.25'
						/>
						<path
							fill={'#5b73e8'}
							d='M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z'>
							<animateTransform
								attributeName='transform'
								dur='0.75s'
								repeatCount='indefinite'
								type='rotate'
								values='0 12 12;360 12 12'
							/>
						</path>
					</svg>
				) : undefined}
			</div>

			<div className='rounded-t bg-[#EDEFFB] !px-4 !py-3'>
				<div className='flex items-center justify-between'>
					<h3 className='!m-0 text-sm font-bold'>Document Upload</h3>
					<span className='rounded bg-white !px-2 !py-1 text-xs text-green-500'>
						Uploaded
					</span>
				</div>
			</div>
			<div className='!p-6'>
				<div className='flex items-center gap-2.5 doc_listing_flex'>
					<img src={fileIcon} />
					<div className='flex w-full flex-col'>
						<div className='flex w-full items-center justify-between'>
							<h5 className='text-base font-normal'>{fileName}</h5>
						</div>
						<p className='m-0 flex items-center whitespace-nowrap text-[#74788D] extra_smaller_devices'>
							{
								<span>{`${
									uploadedSize ? `${uploadedSize} /` : ''
								} ${fileSize}`}</span>
							}
							<div className='flex'>
								<span className='mx-0.5 dot_margin_remove'>&nbsp;•&nbsp;</span>
								<span>{document ? moment(file?.created_at).fromNow() : time}</span>
							</div>
						</p>
					</div>
					<div className='card-section-2 flex w-full items-center justify-end gap-3 align_left'>
						{!document ? (
							<Button
								type='button'
								variant='primary-outline'
								size='sm'
								onClick={() => onDelete(file?.id)}>
								Re-upload
							</Button>
						) : undefined}

						{!progress || progress <= 0 || progress >= 100 ? (
							<Fragment>
								{!document ? (
									<Button
										type='button'
										variant='danger-outline'
										size='sm'
										onClick={() => onDelete(file?.id)}
										disabled={disabled}
										isLoading={isDeleting}>
										<i className='uil-trash text-base text-red-500' />
									</Button>
								) : undefined}
							</Fragment>
						) : (
							<h5 className='m-0 text-base font-semibold'>{progress}%</h5>
						)}
					</div>
				</div>
			</div>
			{progress > 0 && (
				<Progress
					className='!rounded-tl-none !rounded-tr-none !bg-[#B5B5C366]'
					color={progress === 100 ? 'success' : 'primary'}
					value={progress}></Progress>
			)}
		</div>
	)
}

export default UploadFileCard
