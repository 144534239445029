import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import { useMemo } from 'react'

const DocumentsTab = ({ id }) => {
	const URL = useMemo(() => (id ? `${apiEndpoints.workflow}/review/${id}/documents` : null), [id])
	const { data, isLoading } = useQuery(URL)

	const documents = useMemo(
		() =>
			data?.data.map((document) => ({
				id: document?.id,
				title: document?.title,
				url: document?.file?.path,
			})) || [],
		[data, isLoading],
	)

	return (
		<div className='table-responsive'>
			<table className='table-border table-1 border text-sm'>
				<thead>
					<tr className='divide-x'>
						<th>#</th>
						<th>Title</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{documents.length > 0 ? (
						documents.map((document, idx) => (
							<tr key={idx} className='divide-x'>
								<td className='vertical-middle'>{idx + 1}</td>
								<td className='vertical-middle'>{document?.title}</td>
								<td className='vertical-middle'>
									{document?.url ? (
										<a
											href={document?.url}
											target='_blank'
											rel='noreferrer'
											className='btn btn-primary btn-sm flex items-center space-x-1'>
											<i className='fas fa-download' />
											<span>Download</span>
										</a>
									) : (
										<button
											disabled
											className='btn btn-primary btn-sm disbabled:cursor-not-allowed pointer-event-auto flex items-center space-x-1'>
											<i className='fas fa-download' />
											<span>Download</span>
										</button>
									)}
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan={5}>
								<p className='m-0 text-center'>No documents found here</p>
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	)
}

export default DocumentsTab
