import { Field } from 'formik'
import { useState } from 'react'

import { CreateUserOptionLabel } from 'modules/shared/users-dropdown'
import { SelectField } from 'theme/ui/forms'
import CoApplicantsQuery from './co-applicants-query'

const CoApplicantDropdown = ({ name, label, formValues, client_applicants }) => {
	const [search, setSearch] = useState('')
	const { clients, isLoading } = CoApplicantsQuery(search, formValues, client_applicants, name)

	const filterOption = ({ data }, search) => {
		if (
			data.fullName.toLowerCase().includes(search.toLowerCase()) ||
			data.email.toLowerCase().includes(search.toLowerCase())
		) {
			return true
		} else {
			return false
		}
	}

	return (
		<Field
			label={label}
			type='select'
			name={name}
			className='min-w-[88%]'
			options={clients}
			isLoading={isLoading}
			onInputChange={(value) => setSearch(value)}
			component={SelectField}
			isRequiredField={true}
			labelHelperText={false}
			placeholder={label}
			getOptionLabel={(option) => <CreateUserOptionLabel user={option} />}
			filterOption={filterOption}
		/>
	)
}

export default CoApplicantDropdown
