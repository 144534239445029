import { Link } from 'react-router-dom'
import { routes } from '.'

const Header = ({ title }) => {
	return (
		<div className='flex w-full flex-col'>
			<div className='doc_listing_flex mb-3 flex w-full justify-between'>
				<div className='max-w-[733px]'>
					{title ? <h4 className='page-title'>{title}</h4> : undefined}
				</div>

				<Link
					to={routes.create()}
					className='h-fit min-w-max rounded bg-main px-3 py-2 text-white'>
					Create new
				</Link>
			</div>
		</div>
	)
}

export default Header
