import { Field, FormikProvider, useFormik } from 'formik'
import { useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDebounce } from 'use-debounce'

import classNames from 'classnames'
import { OnBoardStatuses } from 'modules/clients/invited-clients'
import BulkActions from 'modules/clients/invited-clients/bulk-actions'
import { useDataTable } from 'theme/ui/data-table'
import { DateRange } from 'theme/ui/filters'
import { Button, SearchField } from 'theme/ui/forms'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import { getActiveFilters, getFormikFilterValues, getInitialValues } from '.'
import StatusDropdown from './status-dropdown'
import TagsDropdown from './tags-dropdown/tags-dropdown'

const Filters = ({
	tagList,
	selectedTags,
	setSelectedTags,
	handleTagChange,
	applyTags,
	createTag,
	assignWorkflow,
	setTagSearch,
	tagSearch,
	selectedRecords,
	checkedTags,
	isAssignDisabled,
	uniqueTagList,
	previousData,
	setPagination,
	rowSelection,
	setRowSelection,
}) => {
	const history = useHistory()
	const location = useLocation()
	const parseQueryString = getParseQueryString(location?.search)
	const initialValues = getInitialValues(parseQueryString)
	const formik = useFormik({ initialValues: initialValues })
	const tableContext = useDataTable()
	const formValues = useMemo(() => formik?.values, [formik?.values])
	const [debounceValues] = useDebounce(formValues, 400)
	const $selectedRows = useMemo(
		() => Object.keys(rowSelection).map((k) => JSON.parse(k)?.id),
		[rowSelection],
	)
	const selectedStatuses = useMemo(
		() => Object.keys(rowSelection).map((k) => JSON.parse(k)?.status),
		[rowSelection],
	)
	const isNonInvitedSelected =
		selectedStatuses.includes(OnBoardStatuses.NON_INVITED) ||
		selectedStatuses.includes(OnBoardStatuses.PENDING) ||
		selectedStatuses.includes(OnBoardStatuses.ONBOARDING) ||
		selectedStatuses.includes(OnBoardStatuses.REJECTED)

	const resetFilters = () => {
		return new Promise((resolve) => {
			const filterObj = { page: 1, limit: 10 }
			const queryString = toQueryString(filterObj)
			history.push(`${window.location.pathname}?${queryString}`)
			resolve()
		})
	}

	const onResetFilters = async () => {
		resetFilters().then(() => {
			formik.handleReset()
		})
	}

	useEffect(() => {
		if (debounceValues) {
			const paginationParams = getPaginationParams(location?.search)
			const filterObj = {
				...getFormikFilterValues(debounceValues),
				page: 1,
				limit: paginationParams.limit,
			}
			const queryString = toQueryString(filterObj)
			history.push(`${window.location.pathname}?${queryString}`)
			if (tableContext) {
				tableContext.resetPageIndex()
			}
			setPagination({
				pageIndex: 0,
				pageSize: 10,
			})
		}
	}, [debounceValues])

	useEffect(() => {
		if (location?.search) {
			const paginationParams = getPaginationParams(location?.search)
			const currentPage = paginationParams?.page ? parseInt(paginationParams.page, 10) : 1
			const currentLimit = paginationParams?.limit ? parseInt(paginationParams.limit, 10) : 10
			if (currentPage > 1) {
				setPagination({
					pageIndex: currentPage - 1,
					pageSize: currentLimit,
				})
			}
		}
	}, [location?.search])

	const activeFilters = getActiveFilters(parseQueryString)

	return (
		<FormikProvider value={formik}>
			<form className='mb-3'>
				{/* {activeFilters.length > 0 ? (
						<TableFiltersCard
							filters={activeFilters}
							formik={formik}
							onResetFilters={onResetFilters}
						/>
					) : undefined} */}

				<div
					className={classNames(
						'grid items-center justify-between ',
						$selectedRows.length > 0
							? 'grid-cols-1 gap-y-2 md:grid-cols-3 md:gap-x-3 md:gap-y-0'
							: 'grid-cols-1',
					)}>
					<div className='col-span-2 flex w-full flex-wrap items-center gap-1'>
						<Field
							className='form-control'
							type='search'
							name='search'
							component={SearchField}
							placeholder='Search clients...'
						/>

						<TagsDropdown
							formik={formik}
							setTagSearch={setTagSearch}
							tagSearch={tagSearch}
							tagList={tagList}
							selectedTags={selectedTags}
							setSelectedTags={setSelectedTags}
							handleTagChange={handleTagChange}
							applyTags={applyTags}
							assignWorkflow={assignWorkflow}
							selectedRecords={selectedRecords}
							createTag={createTag}
							checkedTags={checkedTags}
							isAssignDisabled={isAssignDisabled}
							uniqueTagList={uniqueTagList}
							previousData={previousData}
						/>

						<StatusDropdown name='onBoardStatus' />

						<DateRange name='date_range' label='Added On' />

						{activeFilters.length > 0 ? (
							<Button size='sm' variant='ghost' onClick={onResetFilters}>
								Clear filters
							</Button>
						) : undefined}
					</div>

					<div>
						<div className='flex w-full flex-wrap items-center gap-2 lg:float-right'>
							<div className='flex items-center divide-x divide-solid divide-gray-200'>
								{($selectedRows.length > 0 && isNonInvitedSelected) ||
								(checkedTags?.length > 0 &&
									isAssignDisabled &&
									selectedRecords?.length > 0) ? (
									<span className='min-w-fit pr-3 text-sm text-gray-500'>
										{$selectedRows.length} selected
									</span>
								) : undefined}

								{$selectedRows.length > 0 ? (
									<div className='pl-3'>
										<span
											className='h-full cursor-pointer !border-b border-dashed border-gray-400 !px-1 font-semibold hover:border-solid'
											onClick={() => setRowSelection({})}>
											Deselect
										</span>
									</div>
								) : undefined}
							</div>

							{$selectedRows.length > 0 && isNonInvitedSelected ? (
								<BulkActions
									assignWorkflow={assignWorkflow}
									checkedTags={checkedTags}
									isAssignDisabled={isAssignDisabled}
									selectedRecords={selectedRecords}
									selectedRows={$selectedRows}
								/>
							) : undefined}
						</div>
					</div>
				</div>
			</form>
		</FormikProvider>
	)
}

export default Filters
