import { Fragment } from 'react'
import Badge from 'theme/ui/badge'
import PopoverButtons from 'theme/ui/popover'

const FoldersColumn = ({ folders }) => {
	const folderTitles = folders?.map((folder) => {
		return folder?.title
	})
	return (
		<Fragment>
			<div className='flex max-w-[165px] flex-wrap items-center gap-1'>
				{folderTitles?.length > 0 && (
					<div className='flex flex-wrap items-center gap-2'>
						<Badge
							variant='primary-outline'
							className='line-clamp-1 cursor-pointer'
							rounded={false}>
							{folderTitles[0]?.slice(0, 10)} {folderTitles[0]?.length > 10 && '...'}
						</Badge>
						{folderTitles?.length > 1 ? (
							<PopoverButtons
								rounded={false}
								variant='primary-outline'
								data={folderTitles}
								title={'Folders'}
							/>
						) : undefined}
					</div>
				)}
			</div>
		</Fragment>
	)
}

export default FoldersColumn
