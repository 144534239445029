import { Transition } from '@headlessui/react'
import { capitalize } from 'lodash'
import { useState } from 'react'
import ProductCard from './product-card'

const PricingTable = ({ plans, activePriceId, onSubscribe }) => {
	const plan_types = Array.isArray(Object.keys(plans)) ? Object.keys(plans) : []
	const [billingPeriod, setBillingPeriod] = useState(plan_types[0] || 'monthly')
	const activePlans = Array.isArray(plans[billingPeriod]) ? plans[billingPeriod] : []

	return (
		<section className='py-1'>
			<div className='mx-auto w-full px-4 sm:px-6 lg:px-8'>
				<div className='mb-12 text-center'>
					<h2 className='text-2xl font-bold capitalize leading-6 text-gray-900'>
						Change your subscription plan
					</h2>
					<p className='mt-4 text-sm text-gray-600'>
						Our pricing is modular and team-based, designed to accommodate teams of all
						sizes. Smaller teams have some flexibility before needing to purchase
						additional seats. Our goal is to ensure our product scales seamlessly with
						the growth and evolving needs of your team.
					</p>
				</div>

				{/* Billing Period Buttons */}
				<div className='mx-auto mb-8 flex max-w-fit justify-center rounded-[8px] !border border-[#dddddd] bg-[#f3f3f3] p-[2px]'>
					{plan_types.map((plan_type, idx) => (
						<button
							key={idx}
							type='button'
							onClick={() => setBillingPeriod(plan_type)}
							className={`rounded-md border border-gray-300 !px-8 !py-2 text-sm ${
								billingPeriod === plan_type
									? 'bg-main font-medium text-white'
									: 'text-gray-500 hover:text-gray-700'
							}`}>
							{capitalize(plan_type)}
						</button>
					))}
				</div>

				{/* Animated Product Cards */}
				<div className='grid grid-cols-1 gap-8 md:grid-cols-3'>
					{activePlans.map((activePlan, idx) => {
						const isActive = activePriceId === activePlan?.priceId
						return (
							<Transition
								key={activePlan?.priceId || idx}
								appear={true}
								show={true}
								enter='transition-all duration-700 ease-in-out'
								enterFrom='opacity-0 translate-y-8 scale-95'
								enterTo='opacity-100 translate-y-0 scale-100'
								leave='transition-all duration-500 ease-in-out'
								leaveFrom='opacity-100 translate-y-0 scale-100'
								leaveTo='opacity-0 translate-y-4 scale-95'>
								<ProductCard
									activePlan={activePlan}
									isActive={isActive}
									onSubscribe={onSubscribe}
								/>
							</Transition>
						)
					})}
				</div>
			</div>
		</section>
	)
}

export default PricingTable
