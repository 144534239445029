import { Field } from 'formik'
import { useState } from 'react'

import { CreateUserOptionLabel } from 'modules/shared/users-dropdown'
import { SelectField } from 'theme/ui/forms'
import FinalReviewersQuery from './final-reviewers-query'

const FinalReviewerDropdown = ({ formValues, advisor_reviewer }) => {
	const [search, setSearch] = useState('')
	const { advisors, isLoading } = FinalReviewersQuery(
		search,
		formValues,
		advisor_reviewer,
		'final_reviewer',
	)

	const filterOption = ({ data }, search) => {
		if (
			data.fullName.toLowerCase().includes(search.toLowerCase()) ||
			data.email.toLowerCase().includes(search.toLowerCase())
		) {
			return true
		} else {
			return false
		}
	}

	return (
		<Field
			label='Select Final Reviewer'
			type='select'
			name='final_reviewer'
			options={advisors}
			isLoading={isLoading}
			onInputChange={(value) => setSearch(value)}
			component={SelectField}
			getOptionLabel={(option) => <CreateUserOptionLabel user={option} />}
			filterOption={filterOption}
		/>
	)
}

export default FinalReviewerDropdown
