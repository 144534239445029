import { createColumnHelper } from '@tanstack/react-table'
import { DataTable } from 'components/Common'
import { getDuration } from 'modules/payments/payments-listing'
import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import Badge from 'theme/ui/badge'
import { formatDate, formatPrice, getStatusVariant } from 'utilities/helpers'
import PendingSubActions from './pending-sub-action'
import ToggleTeamStatus from './toggle-team-status'

const PendingSubTable = ({ data, meta, isLoading, pagination, setPagination }) => {
	const [actionActiveId, setActionActiveId] = useState(null)
	const onMouseEnter = (id) => setActionActiveId(id)
	const onMouseLeave = () => setActionActiveId(null)
	const [sorting, setSorting] = useState([])
	const columnHelper = createColumnHelper()

	const columns = [
		columnHelper.accessor('index', {
			size: 20,
			header: () => <span>#</span>,
			cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		}),

		columnHelper.accessor((row) => row, {
			id: 'plan',
			size: 200,
			header: () => <span>Subscription Plan</span>,
			cell: (info) => {
				return (
					<div className='flex flex-col'>
						<span className='text-sm font-bold capitalize'>
							{info.getValue()?.plan?.plan_type}
						</span>
						<span className='text-xs text-gray-400'>
							{formatPrice(info.getValue()?.plan_price)}/{' '}
							{getDuration(info.getValue()?.plan?.duration)}
						</span>
					</div>
				)
			},
		}),

		columnHelper.accessor((row) => row?.user, {
			id: 'user',
			size: 180,
			header: () => <span>Owner Details</span>,
			cell: (info) => {
				return (
					<div className='flex flex-col'>
						<Link
							to={`/users/${info.getValue()?.id}/show`}
							target='_blank'
							className='text-sm font-bold text-main hover:!underline'>
							{info.getValue()?.fullName}
						</Link>
						<span className='line-clamp-1 text-xs text-gray-400'>
							{info.getValue()?.email}
						</span>
					</div>
				)
			},
		}),
		columnHelper.accessor((row) => row?.team, {
			id: 'team',
			size: 200,
			header: () => <span>Team Name</span>,
			cell: (info) => {
				return <span className='font-bold'>{info.getValue()?.team_name}</span>
			},
		}),

		columnHelper.accessor((row) => row?.team, {
			id: 'team_status',
			size: 150,
			enableSorting: false,
			header: () => <span>Team Status</span>,
			cell: (info) => <ToggleTeamStatus team={info.getValue()} />,
		}),

		columnHelper.accessor((row) => row?.payments, {
			id: 'payment_status',
			size: 170,
			header: () => <span>Payment Status</span>,
			cell: (info) => {
				return (
					<Badge variant={getStatusVariant(info.getValue()[0]?.status)}>
						{info.getValue()[0]?.status}
					</Badge>
				)
			},
		}),
		columnHelper.accessor((row) => row, {
			id: 'end_data',
			size: 230,
			header: () => (
				<div className='!w-full'>
					<span className='float-right'>End Date</span>
				</div>
			),
			cell: (info) => (
				<>
					<div className='!w-full'>
						<span className='float-right capitalize'>
							{formatDate(info.getValue()?.payment?.end_date)}
						</span>
					</div>
				</>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'actions',
			size: 230,
			enableSorting: false,
			header: () => (
				<div className='flex !w-full items-center justify-end'>
					<span className='float-right'>Actions</span>
				</div>
			),
			cell: (info) => <PendingSubActions payment={info.getValue()} />,
		}),
	]

	return (
		<>
			<DataTable
				columns={columns}
				data={data}
				isLoading={isLoading}
				pagination={pagination}
				setPagination={setPagination}
				setSorting={setSorting}
				sorting={sorting}
				meta={meta}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			/>
		</>
	)
}

export default PendingSubTable
