import { useState } from 'react'
import { Link } from 'react-router-dom'
import ToolTip from 'theme/ui/tool-tip'
import { routes } from '..'
import DeleteConfirmAlert from './delete-confirm-alert'

const Actions = ({ field, mutate }) => {
	const [isOpen, setIsOpen] = useState(false)
	const onClose = () => setIsOpen(false)
	const ID = field?.id

	return (
		<div className=''>
			<DeleteConfirmAlert isOpen={isOpen} onClose={onClose} id={ID} mutate={mutate} />
			<div className='float-right flex justify-end space-x-2'>
				<ToolTip targetId={`edit-field-${ID}`} tooltipText='Edit' placement='top' />
				<Link
					id={`edit-field-${ID}`}
					to={routes.edit(ID)}
					className='action-btn btn-primary-outline'>
					<i className='uil-edit' style={{ fontSize: 16 }} />
				</Link>
				<ToolTip targetId={`delete-field-${ID}`} tooltipText='Delete' placement='top' />

				<button
					id={`delete-field-${ID}`}
					type='button'
					className='action-btn btn-red-outline'
					onClick={() => setIsOpen(true)}>
					<i className='uil-trash-alt' style={{ fontSize: 16 }} />
				</button>
			</div>
		</div>
	)
}

export default Actions
