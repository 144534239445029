import StarredListingContainer from './starred-listing-container'

export default StarredListingContainer

export const getFormValues = (values) => {
	return {
		...values,
		search_type: values?.search_type?.value,
	}
}
