import { Field } from 'formik'
import { SelectField } from 'theme/ui/forms'
import { createOptionLabel } from '.'
import ExpiredInQuery from './expired-in-query'

const ExpiredInDropdown = () => {
	const { options, isLoading } = ExpiredInQuery()
	return (
		<Field
			label='Expired In'
			name='expiredIn'
			type='select'
			isRequiredField
			options={options}
			component={SelectField}
			isLoading={isLoading}
			isSearchable={false}
			getOptionLabel={createOptionLabel}
			helperText="The invitation will expire at the selected time. Don’t worry, you’ll have the option to resend it later."
		/>
	)
}

export default ExpiredInDropdown
