import Badge from 'theme/ui/badge'

const FoldersColumn = ({ folders }) => {
	return (
		<div>
			{folders?.length > 0 ? (
				<div className='flex flex-wrap items-center gap-1'>
					<Badge variant='primary-outline' rounded={false}>
						{folders[0]?.title}
					</Badge>
					{folders?.slice(1).length > 1 && (
						<Badge variant='primary-outline' rounded={false}>
							+{folders?.slice(1).length}
						</Badge>
					)}
				</div>
			) : undefined}
		</div>
	)
}

export default FoldersColumn
