/* eslint-disable no-unused-vars */
import classNames from 'classnames'
import { Field, getIn } from 'formik'
import { capitalize } from 'lodash'
import { useMemo, useState } from 'react'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

import FilterButton from 'theme/ui/filter-button'
import Datepicker from './datepicker'

const DateRange = ({ name, label, options = [] }) => {
	const [isOpen, setIsOpen] = useState(false)
	const toggleDropdown = () => setIsOpen(!isOpen)
	const dateFilterOptions = useMemo(
		() => [
			{ label: 'Today', value: 'today' },
			{ label: 'Yesterday', value: 'yesterday' },
			{ label: 'Last 7 days', value: 'last_7_days' },
			{ label: 'Last 30 days', value: 'last_30_days' },
			{ label: 'Last 6 months', value: 'last_6_months' },
			...options,
		],
		[options],
	)

	return (
		<Field name={name}>
			{({ form }) => {
				const value = getIn(form?.values, name)
				const isActive = !!value
				const selected_date = value ? capitalize(value.replaceAll('_', ' ')) : undefined

				return (
					<Dropdown isOpen={isOpen} toggle={() => toggleDropdown()}>
						<FilterButton
							isActive={isActive}
							onClear={() => form.setFieldValue(name, null)}>
							<DropdownToggle
								tag='button'
								type='button'
								className={classNames(
									'form-control z-10 max-w-fit',
									isActive ? '!bg-main/20 !text-main' : 'bg-white !text-gray-500',
								)}>
								<div className='flex items-center'>
									<span className='!ml-4'> {label || 'Select Date'}</span>
									{isActive ? (
										<div className='flex items-center space-x-1'>
											<span className='ml-1 text-gray-500'>|</span>
											<span className='space-x-1 font-bold'>
												{selected_date}
											</span>
										</div>
									) : undefined}
								</div>
							</DropdownToggle>
						</FilterButton>

						<DropdownMenu className='mt-2 px-0 py-2 shadow-md'>
							<ul className='m-0 p-0'>
								{dateFilterOptions.map((option, idx) => (
									<li
										key={idx}
										className='exp-dropdown-item'
										onClick={() => {
											form.setFieldValue(name, option?.value)
										}}>
										<span>{option?.label}</span>
									</li>
								))}

								<li className='w-full'>
									<Datepicker
										onChange={(value) => {
											const [_, end] = value.split(',')
											if (new Date(end) !== 'Invalid Date') {
												form.setFieldValue(name, value)
											}
										}}
										value={value}
									/>
								</li>
							</ul>
						</DropdownMenu>
					</Dropdown>
				)
			}}
		</Field>
	)
}

export default DateRange
