import { Fragment, useMemo, useState } from 'react'

import { useBatchDeleteDocumentsMutation } from 'modules/documents/document-api'
import DeleteConfirmAlert from 'theme/ui/delete-confirm-alert'
import { Button } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { getParsedRows } from '.'
import AddToFolderPopup from '../add-to-folder-popup'
import AttachInWorkflowPopup from '../attach-in-workflow-popup/attach-in-workflow-popup'

const Filters = ({ selectedRows, heading }) => {
	const [deleteDocuments, { isLoading: isDeleting }] = useBatchDeleteDocumentsMutation()
	const [isDeleteOpen, setIsDeleteOpen] = useState(false)
	const [isShortcutOpen, setIsShortcutOpen] = useState(false)
	const [attachWorkflowIsOpen, setAttachWorkflowIsOpen] = useState(false)

	const parsedRows = useMemo(() => getParsedRows(selectedRows), [selectedRows])

	const onDeleteDocuments = async (documentIds) => {
		try {
			const payload = {
				documentIds: documentIds,
			}
			const response = await deleteDocuments(payload).unwrap()
			if (response?.status === 200) {
				setIsDeleteOpen(false)
				notification('success', 'Selected documents successfully deleted')
			}
		} catch (error) {
			notification('error', error?.message)
		}
	}

	return (
		<Fragment>
			<DeleteConfirmAlert
				isDeleting={isDeleting}
				isOpen={isDeleteOpen}
				onClose={() => setIsDeleteOpen(false)}
				onConfirm={() => onDeleteDocuments(parsedRows.map((doc) => doc?.id))}
				title={`Delete (${selectedRows.length}) Documents?`}
				subtitle='Are you sure you want to delete the selected documents record?'
			/>

			<AddToFolderPopup
				documents={parsedRows.map((doc) => doc?.id)}
				isOpen={isShortcutOpen}
				onClose={() => setIsShortcutOpen(false)}
			/>

			<AttachInWorkflowPopup
				isOpen={attachWorkflowIsOpen}
				onClose={() => setAttachWorkflowIsOpen(false)}
				selectedRows={parsedRows}
			/>

			<div className='flex items-center justify-between pb-3'>
				<h3 className='m-0 mt-1 text-base font-semibold'>{heading || 'My Documents'}</h3>
				{selectedRows.length > 0 ? (
					<div className='flex items-center space-x-3'>
						<span className='text-sm font-semibold'>
							{selectedRows.length} documents selected
						</span>
						<div className='bg-white'>
							<Button
								type='button'
								size='sm'
								variant='primary-outline'
								onClick={() => setIsShortcutOpen(true)}>
								<i className='uil-plus'></i>
								<span>Add Shortcut</span>
							</Button>
						</div>

						<div className='bg-white'>
							<Button
								type='button'
								size='sm'
								variant='primary-outline'
								onClick={() => setAttachWorkflowIsOpen(true)}>
								<span>Attach in Workflow</span>
							</Button>
						</div>
						<div className='bg-white'>
							<Button
								type='button'
								size='sm'
								onClick={() => setIsDeleteOpen(true)}
								variant='danger-outline'>
								<span>Delete All</span>
							</Button>
						</div>
					</div>
				) : undefined}
			</div>
		</Fragment>
	)
}

export default Filters
