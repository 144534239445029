import { useMemo } from 'react'
import Badge from 'theme/ui/badge'
import { formatDate } from 'utilities/helpers'

const OwnerDetailsTab = ({ owner }) => {
	const $owner = useMemo(
		() => [
			{ label: 'Name', value: owner?.fullName || 'N/A' },
			{ label: 'Email', value: owner?.email || 'N/A' },
			{ label: 'Phone Number', value: owner?.phone_number || 'N/A' },
			{ label: 'Created At', value: formatDate(owner?.created_at) || 'N/A' },
			{
				label: 'Status',
				value:
					(
						<Badge
							variant={owner?.status === 1 ? 'success' : 'danger'}
							className={` px-3 py-1 ${
								owner?.status === 1
									? 'bg-green-100 text-green-800'
									: 'bg-red-100 text-red-800'
							}`}>
							{owner?.status === 1 ? 'Active' : 'Inactive'}
						</Badge>
					) || 'N/A',
			},
		],
		[owner],
	)

	return (
		<div className='rounded-lg !border bg-white p-6 shadow-md'>
			<h5 className='mb-6 text-lg font-bold text-gray-700'>Owner Details</h5>
			<div className='flex flex-col justify-between gap-2  md:!flex-row  '>
				{$owner.map((user, idx) => (
					<div key={idx} className='flex flex-col space-y-1'>
						<span className='text-sm text-gray-500'>{user?.label}</span>
						<span className='truncate text-base font-medium text-gray-800'>
							{user?.value}
						</span>
					</div>
				))}
			</div>
		</div>
	)
}

export default OwnerDetailsTab
