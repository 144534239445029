import { useEffect, useState } from 'react'

import apiEndpoints from 'helpers/apiEndpoints'
import { useAppRouter } from 'hooks'
import { useLazyTrashedDocumentPackageQuery } from 'modules/documents/document-package-api'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import TrashListingTable from './trash-listing-table'

const TrashListingContainer = () => {
	const { location, navigate } = useAppRouter()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination

	useEffect(() => {
		if (pagination) {
			const parseQueryString = getParseQueryString(location?.search)
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			navigate(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	const [fetchDocumentPackages, { data, isUninitialized, isLoading }] =
		useLazyTrashedDocumentPackageQuery()

	useEffect(() => {
		const stringUrl = `${apiEndpoints.documentPackage}/trashed`
		if (location?.search) {
			fetchDocumentPackages({
				params: getParseQueryString(location?.search),
				url: stringUrl,
			})
		}
	}, [location?.search])

	return (
		<div className='divide-y'>
			<div className='flex items-center justify-between pb-3'>
				<div className='flex items-center space-x-3'>
					<h3 className='m-0 text-base'>Trash</h3>
				</div>
			</div>

			<div className='pt-4'>
				<TrashListingTable
					data={data?.data}
					pagination={pagination}
					meta={data?.meta}
					setPagination={setPagination}
					isLoading={isUninitialized || isLoading}
				/>
			</div>
		</div>
	)
}

export default TrashListingContainer
