import { Field, FieldArray } from 'formik'
import { InputField, SelectField } from 'theme/ui/forms'
import { META_OPTIONS } from '..'

const MetaDataList = ({ formik }) => {
	return (
		<div className='pt-4'>
			<label className='m-0 p-0 font-medium text-gray-700'>Metadata</label>
			<p className='m-0 mb-1 p-0 text-[12px] italic text-gray-400'>
				Store additional, structured information.
			</p>
			<FieldArray
				name='metadata'
				render={({ push, remove, form }) => {
					const metadata = form.values.metadata
					const canAddNewField =
						metadata.length < META_OPTIONS.length &&
						(metadata.length === 0 || metadata[metadata.length - 1]?.key)

					const remainingOptions = META_OPTIONS.filter(
						(option) => !metadata.some((meta) => meta.key === option.value),
					)

					return (
						<div className='w-full'>
							{metadata.map((meta, index) => (
								<div key={index} className='mb-4 grid  grid-cols-11 gap-2'>
									<div className='col-span-6 flex flex-col'>
										<Field
											name={`metadata[${index}].key`}
											component={SelectField}
											options={META_OPTIONS}
											placeholder='Select Key'
											isRequiredField
											disabled={!!meta.key}
										/>
										{formik?.errors?.metadata?.[index]?.key &&
										formik?.touched?.metadata?.[index]?.key ? (
											<p className='m-0 mx-1 p-0 text-[12px] text-red-500'>
												{formik?.errors?.metadata[index]?.key}
											</p>
										) : null}
									</div>
									<div className='col-span-4 flex flex-col'>
										<Field
											name={`metadata[${index}].value`}
											placeholder='Value'
											component={InputField}
										/>
										{formik?.errors?.metadata?.[index]?.value &&
										formik?.touched?.metadata?.[index]?.value ? (
											<p className='m-0 mx-1 p-0 text-[12px] text-red-500'>
												{formik?.errors?.metadata[index]?.value}
											</p>
										) : null}
									</div>
									<div className='col-span-1 flex items-start justify-center'>
										<span
											onClick={() => remove(index)}
											className={`mt-1 cursor-pointer rounded !border !border-red-500 px-2 py-1 text-red-500`}>
											<i className='uil-trash text-red-600'></i>
										</span>
									</div>
								</div>
							))}
							{remainingOptions?.length > 0 && (
								<span
									onClick={() => {
										if (canAddNewField) {
											push({ key: '', value: '' })
										}
									}}
									className={`float-end mb-3 ${
										canAddNewField
											? 'cursor-pointer text-blue-500'
											: 'text-gray-400'
									}`}>
									+ Add
								</span>
							)}
						</div>
					)
				}}
			/>
		</div>
	)
}

export default MetaDataList
