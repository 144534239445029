import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import { snakeCase } from 'lodash'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { reduxForm, touch } from 'redux-form'

import { useAppRouter } from 'hooks'
import { useSaveAssignedWorkflowMutation } from 'modules/assigned-workflows/assigned-workflow-apis'
import { ASSIGN_WORKFLOW_APP_ROUTES } from 'modules/assigned-workflows/routes'
import { Button } from 'theme/ui/forms'
import { WorkflowStatuses } from 'utilities/helpers'
import notification from 'utilities/notification'
import { getTabs } from '.'
import { getDocumentsPayload, getPayload } from '../..'
import {
	getDocumentBoundFields,
	getDocumentsList,
	getField,
	getFieldsByUser,
	getFieldsList,
} from '../../helpers'
import { WORKFLOW_ACTIONS } from '../../popup'
import DocumentBoundFieldsTab from './document-bound-fields-tab'
import ProfileFieldsTab from './profile-fields-tab'
import UploadedFiles from './uploaded-files'

const FillDetailsTabForm = (props) => {
	const {
		id,
		setWorkflowAction,
		setIsOpen,
		user_type,
		uploads_id,
		handleSubmit,
		individual_document,
		screen_type,
		currentStatus,
		uploads,
	} = props

	const uploadedFiles = useMemo(
		() =>
			Array.isArray(uploads?.files)
				? uploads?.files.map((f) => ({ ...f, extension: f?.files?.name.split('.')[1] }))
				: [],
		[uploads?.files],
	)
	const { navigate } = useAppRouter()
	const [isWorkflowSaving, setIsWorkflowSaving] = useState(false)
	const [isDraftWorkflowSaving, setIsDraftWorkflowSaving] = useState(false)
	const [activeFieldTab, setActiveFieldTab] = useState(0)
	const [saveAssignedWorkflow] = useSaveAssignedWorkflowMutation()
	const isReadOnly = [
		WorkflowStatuses.IN_REVIEW,
		WorkflowStatuses.IN_FINAL_REVIEW,
		WorkflowStatuses.APPROVED,
		WorkflowStatuses.FINAL_REVIEWER_APPROVED,
	].includes(currentStatus)

	const { defaultGroup } = useSelector((state) => state.Permissions)
	const group = useMemo(() => defaultGroup?.group, [defaultGroup])
	const defaultGroupName = snakeCase(group?.name)
	const CLIENT_KEY = 'client'
	const URL = useMemo(
		() => (defaultGroupName === CLIENT_KEY ? `/client/workflow` : `/advisor/workflow`),
		[defaultGroupName],
	)
	const { syncErrors } = useSelector((state) => state.form.workflow_step_2)
	const [disableNext, setDisableNext] = useState(false)

	useEffect(() => {
		const checkErrors = async () => {
			if (Array.isArray(syncErrors?.fields) && syncErrors.fields.length > 0) {
				const allFields = syncErrors.fields
					.filter((item) => item !== undefined)
					.map((item) => Object.keys(item)[0])

				const field = await findCurrentField()
				const { key } = await getField(field)
				if (allFields.includes(key)) {
					setDisableNext(true)
				} else {
					setDisableNext(false)
				}
			} else {
				setDisableNext(false)
			}
		}

		checkErrors()
	}, [syncErrors, activeFieldTab])

	const [error, setError] = useState(null)
	const [isFieldSaved, setIsFieldSaved] = useState(false)

	const fieldsArray = useMemo(
		() => getFieldsList(individual_document?.documents),
		[individual_document?.documents],
	)

	const documentBoundFields = useMemo(
		() => getDocumentBoundFields(individual_document?.documents, user_type),
		[individual_document?.documents, user_type],
	)

	const tabsList = useMemo(() => getTabs(documentBoundFields.length > 0), [documentBoundFields])
	const fields = useMemo(() => {
		const $fieldsArray = fieldsArray.filter((f) => f?.is_document_bound === false)
		return getFieldsByUser($fieldsArray, user_type)
	}, [fieldsArray, user_type])

	const total_fields = fields.length
	const total_doc_bound_fields = documentBoundFields.length

	const [activeField, setactiveField] = useState(total_fields === 0 ? 0 : 1)
	const { workflow_step_2: formName } = useSelector((state) => state.form)
	const formValues = useMemo(() => formName?.values, [formName?.values])
	const fieldValues = formValues?.fields || []
	const documentBoundFieldValues = formValues?.document_bound_fields || []

	const documentList = useMemo(
		() => getDocumentsList(individual_document?.documents),
		[individual_document],
	)

	const [docs, setDocs] = useState([])

	const toggleField = (tab) => {
		if (activeField !== tab) {
			if (tab >= 1 && tab <= total_fields) {
				setactiveField(tab)
			}
		}
	}
	const { active } = useSelector((state) => state.form?.workflow_step_2)
	const $activeField = useMemo(() => {
		if (active) {
			// eslint-disable-next-line no-unused-vars
			const [_, fieldName] = active.split('.')
			return fields.find((field) => field?.name === fieldName)
		}
		return undefined
	}, [active])

	useEffect(() => {
		if ($activeField && fields.length > 0) {
			const $docs = $activeField?.documents || []
			setDocs($docs)
		}
	}, [$activeField])

	const saveFields = () => {
		return new Promise((resolve) => {
			if (total_fields === activeField) {
				setTimeout(() => {
					setIsFieldSaved(true)
					resolve(true)
				}, 1000)
			} else {
				setIsFieldSaved(false)
				resolve(true)
			}
		})
	}

	const findCurrentField = () => {
		return Promise.resolve(fieldValues[activeField - 1])
	}

	const handleNext = async () => {
		try {
			const field = await findCurrentField()
			const { value, key } = await getField(field)
			const field_name = `fields[${activeField - 1}].${key}`
			props.dispatch(touch('workflow_step_2', field_name))

			if (value !== '') {
				setError(null)
				toggleField(activeField + 1)
				await saveFields()
			}
		} catch (error) {
			notification('error', 'Something went wrong while saving the field')
		}
	}

	const onSubmit = async (save_type = 'submit') => {
		try {
			if (activeFieldTab === 0 && tabsList.length > 1) {
				setActiveFieldTab(1)
			} else {
				if (save_type === 'submit') {
					setIsWorkflowSaving(true)
				} else {
					setIsDraftWorkflowSaving(true)
				}
				const documentsPayload = getDocumentsPayload(
					documentList,
					documentBoundFieldValues,
					fieldValues,
					user_type,
				)
				const payload = getPayload(
					id,
					uploads_id,
					individual_document?.id,
					documentsPayload,
					save_type,
				)

				const response = await saveAssignedWorkflow({ payload, url: URL })
				if (response?.data?.status === 200) {
					if (save_type === 'submit') {
						setWorkflowAction(WORKFLOW_ACTIONS.SUBMITTED)
						setIsOpen(true)
					} else {
						navigate(ASSIGN_WORKFLOW_APP_ROUTES.findAll())
					}
				}
				if (save_type === 'submit') {
					setIsWorkflowSaving(false)
				} else {
					setIsDraftWorkflowSaving(false)
				}
			}
		} catch (error) {
			if (save_type === 'submit') {
				setIsWorkflowSaving(false)
			} else {
				setIsDraftWorkflowSaving(false)
			}
			notification('error', 'Something went wrong while saving workflow')
		}
	}
	// const isStepAvailable = !isLoading && documentList.length === 0
	// const $isStepAvailable = isStepAvailable || (total_fields === 0 && total_doc_bound_fields === 0

	const isInReview = [
		WorkflowStatuses.IN_REVIEW,
		WorkflowStatuses.IN_FINAL_REVIEW,
		WorkflowStatuses.APPROVED,
		WorkflowStatuses.FINAL_REVIEWER_APPROVED,
	].includes(currentStatus)

	return (
		<div>
			<form onSubmit={handleSubmit(onSubmit)}>
				{isInReview && uploadedFiles.length > 0 ? (
					<UploadedFiles uploadedFiles={uploadedFiles} />
				) : undefined}

				<h3 className='mb-6 text-lg font-semibold text-gray2-dark'>
					{isInReview ? 'Filled Data' : 'Fill Details'}
				</h3>
				<div className='bg-white py-3 md:p-0'>
					<Tab.Group selectedIndex={activeFieldTab}>
						<Tab.List className='-mt-[10px] mb-3 flex space-x-3 rounded-sm !border-b border-gray-200'>
							{tabsList.map((tab, idx) => (
								<Tab
									onClick={() => {
										if (isInReview) {
											setActiveFieldTab(idx)
										}
									}}
									as='span'
									key={idx}
									className={({ selected }) =>
										classNames(
											'max-w-fit border-b-2 py-2.5 text-sm font-bold focus:outline-none',
											selected
												? 'border-main text-main'
												: 'border-transparent  text-[#B5B5C3]',
											{
												'cursor-pointer border-l-0 transition-all hover:border-main hover:text-main':
													isInReview,
											},
										)
									}>
									{tab.label}
								</Tab>
							))}
						</Tab.List>

						<Tab.Panels>
							<Tab.Panel>
								<ProfileFieldsTab
									handleNext={handleNext}
									currentStatus={currentStatus}
									docs={docs}
									isFieldSaved={isFieldSaved}
									error={error}
									activeField={activeField}
									fields={fields}
									setIsFieldSaved={setIsFieldSaved}
									toggleField={toggleField}
									total_fields={total_fields}
									disableNext={disableNext}
									screen_type={screen_type}
									isReadOnly={isReadOnly}
								/>
							</Tab.Panel>
							<Tab.Panel>
								<DocumentBoundFieldsTab
									documentBoundFields={documentBoundFields}
									isReadOnly={isReadOnly}
									currentStatus={currentStatus}
								/>
							</Tab.Panel>
						</Tab.Panels>
					</Tab.Group>
				</div>

				{![
					WorkflowStatuses.IN_REVIEW,
					WorkflowStatuses.IN_FINAL_REVIEW,
					WorkflowStatuses.APPROVED,
					WorkflowStatuses.FINAL_REVIEWER_APPROVED,
				].includes(currentStatus) ? (
					<Fragment>
						<div className='!my-6 !border' />
						<div className='m-0 flex justify-between'>
							<Button
								type='button'
								variant='primary-outline'
								disabled={activeFieldTab === 0}
								onClick={() => setActiveFieldTab(0)}
								isLoadingText='Saving...'>
								<i className='uil-angle-left' />Previous
							</Button>

							<div className='flex items-center space-x-2'>
								{activeFieldTab === 1 ? (
									<>
										<Button
											type='button'
											onClick={() => onSubmit('draft')}
											variant='primary-outline'
											isLoadingText='Saving...'
											isLoading={isDraftWorkflowSaving}>
											Save as Draft
										</Button>
										<span>or</span>
									</>
								) : undefined}

								<Button
									type='submit'
									isLoadingText='Saving...'
									isLoading={isWorkflowSaving}>
									{activeFieldTab === 0 ? (
										<span>
											Next <i className='uil-angle-right' />
										</span>
									) : (
										<span>
											Submit <i className='uil-angle-right' />
										</span>
									)}
								</Button>
							</div>
						</div>
					</Fragment>
				) : undefined}
			</form>
		</div>
	)
}

const FillDetailsTab = reduxForm({
	form: 'workflow_step_2',
	enableReinitialize: true,
})(FillDetailsTabForm)

export default FillDetailsTab
