import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

import AddToFolder from './add-to-folder'
import CreateFolder from './create-folder'

const AddOptions = ({ data, refetchRecord }) => {
	const [isFolderOpen, setIsFolderOpen] = useState(false)
	const [isDocumentOpen, setIsDocumentOpen] = useState(false)
	const [menu, setMenu] = useState(false)
	const onOpen = () => setIsFolderOpen(true)
	const onDocOpen = () => setIsDocumentOpen(true)
	const onClose = () => setIsFolderOpen(false)
	const onDocClose = () => setIsDocumentOpen(false)

	return (
		<Fragment>
			<>
				<AddToFolder
					refetchRecord={refetchRecord}
					onClose={onDocClose}
					isOpen={isDocumentOpen}
					type={'document'}
					file={data}
				/>
				<CreateFolder
					refetchRecord={refetchRecord}
					onClose={onClose}
					isOpen={isFolderOpen}
					type={'folder'}
					parentId={data?.id}
				/>

				<Dropdown
					isOpen={menu}
					toggle={() => setMenu(!menu)}
					className='dropdown  inline-block'
					tag='li'>
					<DropdownToggle
						tag='button'
						type='button'
						className='m-0 !border !border-none bg-transparent p-0'>
						<Link className='text-sm font-medium'>
							<i className='uil-plus text-sm'></i>
							<span>Add</span>
						</Link>
					</DropdownToggle>
					<DropdownMenu className='dropdown-menu-sm dropdown-menu-end  p-0 '>
						<div className='w-full overflow-hidden rounded-md bg-white text-left shadow-lg ring-1 ring-black/5'>
							<div className='relative divide-y '>
								<div className='flex flex-col gap-1 py-2'>
									<span
										onClick={onDocOpen}
										className='flex cursor-pointer gap-3 px-3 py-2 text-[15px] hover:bg-blue-50 hover:text-blue-600'>
										<i className='uil-file-blank text-[18px]'></i>
										Document
									</span>
									<span
										className='flex cursor-pointer gap-3 px-3 py-2 text-[15px] hover:bg-blue-50 hover:text-blue-600'
										onClick={onOpen}>
										<i className='uil-folder text-[18px]'></i>
										Folder
									</span>
								</div>
							</div>
						</div>
					</DropdownMenu>
				</Dropdown>
			</>
		</Fragment>
	)
}

export default AddOptions
