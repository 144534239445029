import { Field, FormikProvider, useFormik } from 'formik'
import { useEffect, useMemo } from 'react'
import SimpleBar from 'simplebar-react'

import { ReactComponent as SearchIcon } from 'assets/svg/search-icon.svg'
import { useLazyGetDocumentPackagesQuery } from 'modules/documents/document-package-api'
import { Button } from 'theme/ui/forms'
import Spinner from 'theme/ui/spinner'
import { useDebounce } from 'use-debounce'
import notification from 'utilities/notification'
import { useAddShortcutMutation } from '../readymade-templates-api'
import DocumentList from './document-list'

const AddShortcutForm = ({ document, documents, onClose }) => {
	const [addShortcut] = useAddShortcutMutation()

	const formik = useFormik({
		initialValues: {
			search: null,
			selectedFolders: [],
			folderTitle: null,
		},
		onSubmit: async (values) => {
			try {
				const payload = {
					documentIds: document ? [document?.id] : documents,
					documentPackageIds: values?.selectedFolders,
				}
				const response = await addShortcut(payload).unwrap()
				if (response?.status === 200) {
					notification('success', 'Shortcut added successfully')
					onClose()
				}
			} catch (error) {
				notification('error', error?.message)
			}
		},
	})
	const [search] = useDebounce(formik?.values?.search, 300)
	const [fetchFolder, { data, isUninitialized, isLoading }] = useLazyGetDocumentPackagesQuery()

	useEffect(() => {
		const query = { page: 1, limit: 20 }
		if (search && search.length >= 3) {
			Object.assign(query, { search: search })
		}
		fetchFolder(query)
	}, [search])

	const folders = useMemo(
		() =>
			Array.isArray(data?.data)
				? data?.data.map((folder) => ({
						id: folder?.id,
						title: folder?.title,
						documents: Array.isArray(folder?.documents)
							? folder?.documents.map((d) => d?.id)
							: [],
				  }))
				: [],
		[data?.data],
	)

	return (
		<FormikProvider value={formik}>
			<form className='mb-3 flex-1' onSubmit={formik.handleSubmit}>
				<div className='flex h-[56px] w-full items-center space-x-1 rounded px-2.5 shadow'>
					<div className='px-2'>
						<SearchIcon height={20} width={20} />
					</div>
					<Field name='search'>
						{({ field }) => (
							<input
								type='text'
								{...field}
								className='flex-1 px-1 py-2 text-base font-normal text-gray2-dark'
								placeholder='Search folders'
							/>
						)}
					</Field>
				</div>
				<div className='mt-3 flex flex-col'>
					<div className='mb-3 flex flex-col justify-start gap-1'>
						<SimpleBar style={{ maxHeight: '300px' }}>
							{isUninitialized || isLoading ? (
								<Spinner />
							) : (
								<DocumentList
									folders={folders}
									document={document}
									formik={formik}
								/>
							)}
						</SimpleBar>
					</div>

					<div className='mt-3 flex w-full flex-col gap-2'>
						<Button
							type='submit'
							block={true}
							disabled={folders.length === 0}
							className={`rounded-md !border p-2 font-medium text-white ${
								folders.length > 0
									? 'border-main bg-main'
									: 'border-gray-400 bg-gray-500'
							}`}>
							Add Now
						</Button>
					</div>
				</div>
			</form>
		</FormikProvider>
	)
}

export default AddShortcutForm
