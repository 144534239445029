import { ChevronDown } from 'lucide-react'

const DropdownIndicator = () => {
	return (
		<div className='px-2'>
			<ChevronDown size={16} className='text-[#B5B5C3]' />
		</div>
	)
}

export default DropdownIndicator
