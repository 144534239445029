// import { SpinLoader } from 'components/Common'
import { SpinLoader } from 'components/Common'
import { useLazyGetEmailTemplateQuery } from 'modules/email-templates/email-templates-api'
import { EmailTemplatesForm } from 'modules/email-templates/email-templates-form'
import Pages404 from 'pages/StaticPages/Page404'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const EmailTemplatesEdit = () => {
	const params = useParams()
	const [fetchEmailTemplate, { data, isFetching }] = useLazyGetEmailTemplateQuery(params?.id)
	useEffect(() => {
		if (params?.id) {
			fetchEmailTemplate(params?.id)
		}
	}, [params?.id])

	const emailTemplate = useMemo(() => data?.emailTemplate, [data?.emailTemplate])

	return (
		<AppLayout
			meta={{
				title: 'Email Template Edit',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<PageHeader title={emailTemplate?.name} hasBackButton={true} />
					<div className='page-body'>
						{isFetching ? (
							<SpinLoader />
						) : (
							<>
								{data?.emailTemplate ? (
									<EmailTemplatesForm emailTemplate={emailTemplate} />
								) : (
									<Pages404 />
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default EmailTemplatesEdit
