import base64 from 'base-64'
import * as yup from 'yup'

import apiEndpoints from 'helpers/apiEndpoints'
import { dispatch } from 'store'
import { getPermissions, login } from 'store/jwtauth/actions'
import { JwtAuthSlices } from 'store/jwtauth/slices'
import { resetPermissions, setDefaultGroup } from 'store/permissions/actions'
import { showErrorToast } from 'utils/commonFunctions'
import { add, get } from 'utils/storageService'
import LoginForm from './login-form'

const { actions } = JwtAuthSlices

export const removePermissions = () => {
	return new Promise((resolve, reject) => {
		try {
			dispatch(resetPermissions())
			resolve({ message: 'permissions removed' })
		} catch (error) {
			reject(new Error('Something went wrong while removing permissions'))
		}
	})
}

export const rememberMe = (email) => {
	return new Promise((resolve, reject) => {
		try {
			add('remember_me', JSON.stringify({ email: email }))
			resolve({ message: 'Email saved now' })
		} catch (error) {
			reject(new Error('Something went wrong while saving email'))
		}
	})
}

export const addAuthUser = (email) => {
	return new Promise((resolve, reject) => {
		try {
			add('authUser', JSON.stringify({ email: email }))
			resolve({ message: 'Email saved now' })
		} catch (error) {
			reject(new Error('Something went wrong while saving email'))
		}
	})
}

export const saveToken = (token) => {
	return new Promise((resolve, reject) => {
		try {
			add('token', token)
			resolve({ message: 'token saved' })
		} catch (error) {
			reject(new Error('Something went wrong while saving token'))
		}
	})
}

export const authenticate = (payload) => {
	return new Promise((resolve, reject) => {
		dispatch(login(apiEndpoints.auth.login, payload)).then((response) => {
			if (response?.status === 200) {
				resolve(response?.user)
			} else {
				reject(new Error('Authentication failed'))
			}
		})
	})
}

export const fetchPermissions = () => {
	return new Promise((resolve) => {
		dispatch(getPermissions()).then((response) => {
			if (response?.status === 200) {
				resolve(response?.user)
			} else {
				// reject()
				showErrorToast('Permissions fetching failed')
			}
		})
	})
}

export const setUserDetails = (user) => {
	return new Promise((resolve, reject) => {
		try {
			dispatch(actions.login(user))
			resolve({ message: 'user details saved' })
		} catch (error) {
			reject(new Error('Something went wrong while saving user'))
		}
	})
}
export const setDefaultPermissionsGroup = (user) => {
	return new Promise((resolve, reject) => {
		try {
			const groups = Array.isArray(user?.groups) ? user?.groups : []
			const $groups = groups.filter((g) => g?.status === 'Approved')

			const hasTeam = user?.hasTeam
			const currentGroup = hasTeam
				? $groups.find((group) => group?.team?.id === hasTeam?.id)
				: $groups[0]

			if (currentGroup) {
				dispatch(setDefaultGroup(currentGroup))
			}
			resolve()
		} catch (error) {
			reject(new Error('Something went wrong while setting default group'))
		}
	})
}

export const getRememberedEmail = () => {
	try {
		const _email = localStorage.getItem('remember_me') || '{}'
		const obj = JSON.parse(_email)
		const email = obj?.email
		return email
	} catch (error) {
		return ''
	}
}

export const loginValidation = yup.object().shape({
	email: yup.string().email().required('Please enter your email address'),
	password: yup.string().required('Please enter your password'),
})

export const getInitialValues = (token, query) => {
	try {
		const data = token ? base64.decode(token) : ''
		const parsedData = data ? JSON.parse(data) : null
		const email = parsedData?.email

		return {
			email: email || query?.email || JSON.parse(get('remember_me'))?.email || '',
			password: '',
			remember_me: false,
		}
	} catch (error) {
		showErrorToast(error.message)
	}
}

export default LoginForm
