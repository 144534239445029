import { createColumnHelper } from '@tanstack/react-table'
import { useState } from 'react'

import { ReactComponent as DocumentIcon } from 'assets/svg/document-icon.svg'
import { ReactComponent as FolderIcon } from 'assets/svg/folder-icon.svg'
import DataTable from 'theme/ui/data-table'
import { formatDate } from 'utilities/helpers'
import Actions from '../actions'
import FoldersColumn from '../folders-column'

const TableRowBody = ({ data }) => {
	const [sorting, setSorting] = useState([])
	const [actionActiveId, setActionActiveId] = useState(null)
	const onMouseEnter = (id) => setActionActiveId(id)
	const onMouseLeave = () => setActionActiveId(null)
	const columnHelper = createColumnHelper()

	const columns = [
		columnHelper.accessor((row) => row, {
			id: 'info',
			size: 270,
			header: () => <span>Title</span>,
			cell: (info) => (
				<div className='flex items-center space-x-2'>
					{/* <input type='checkbox' name='' className='form-check-input' id='' /> */}
					<div className='flex items-center'>
						{info.getValue()?.type === 'folder' ? (
							<FolderIcon height={15} />
						) : (
							<DocumentIcon height={20} />
						)}
						<span className='my-1 ml-2 line-clamp-1 text-sm font-medium'>
							{info.getValue()?.title}
						</span>
					</div>
				</div>
			),
		}),

		columnHelper.accessor((row) => row, {
			id: 'folders',
			size: 170,
			header: () => <span style={{minWidth:"60px"}}>Folder</span>,
			cell: (info) => (
				<FoldersColumn
					key={info?.row?.id}
					data={info.getValue()}
					type={info.getValue()?.type}
				/>
			),
		}),

		columnHelper.accessor((row) => row.updated_at, {
			id: 'updated_at',
			size: 160,
			header: () => <span>Last Edited</span>,
			cell: (info) => <span className='text-[14px]'>{formatDate(info.getValue())}</span>,
		}),

		columnHelper.accessor((row) => row, {
			id: 'actions',
			size: 200,
			header: () => <span>Actions</span>,
			cell: (info) => (
				<Actions
					type={info.getValue()?.type}
					data={info?.row}
					isActionActive={actionActiveId === info?.row?.id}
				/>
			),
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={data}
			isLoading={false}
			setSorting={setSorting}
			sorting={sorting}
			variant='secondary'
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		/>
	)
}

export default TableRowBody
