import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

import errorImg from '../../assets/images/404-error.png'

const ErrorCard = ({ title, description }) => {
	return (
		<div className='text-center'>
			<div>
				<Row className='row justify-content-center'>
					<Col sm={4}>
						<div className='error-img'>
							<img src={errorImg} alt='' className='img-fluid d-block mx-auto' />
						</div>
					</Col>
				</Row>
			</div>
			<h4 className='text-uppercase mt-4'>{title || 'Something went wrong'}</h4>
			<p className='text-muted'>
				{description ||
					'The requested resource is not found in our system. Please ensure that the resource exists in the system.'}
			</p>
			<div className='mt-5'>
				<Link className='btn btn-primary waves-effect waves-light' to='/'>
					Go back
				</Link>
			</div>
		</div>
	)
}

export default ErrorCard
