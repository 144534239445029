import classNames from 'classnames'
import { useMemo } from 'react'

import { Search } from 'lucide-react'

const SearchField = ({
	id,
	form,
	label,
	field,
	placeholder,
	isRequiredField,
	labelHelperText,
	type,
	disabled,
}) => {
	const { onChange, onBlur, name, value } = field

	const touched = useMemo(() => form?.touched[name], [form?.touched])
	const error = useMemo(() => form?.errors[name], [form?.errors])

	return (
		<div className='form-group'>
			{label ? (
				<label
					htmlFor={id}
					className='flex items-baseline justify-between text-[14px] font-bold text-[#495057]'>
					<span>
						{label}{' '}
						{isRequiredField ? <span className='text-red-500'>*</span> : undefined}
					</span>
					{labelHelperText ? (
						<span className='text-[11px] font-normal'>
							<span className='text-red-500'>*</span> This asterisk indicates not
							optional
						</span>
					) : undefined}
				</label>
			) : undefined}

			<div className='flex w-full appearance-none items-center space-x-3 rounded !border border-solid border-[#ced4da] bg-white bg-clip-padding px-[10px] py-[0.47rem] text-[0.9rem] font-normal leading-normal text-[#495057]'>
				<input
					id={id}
					onChange={onChange}
					onBlur={onBlur}
					name={name}
					type={type}
					value={value}
					disabled={disabled}
					placeholder={placeholder}
					autoComplete='off'
					className={classNames('w-full placeholder:!text-[#B5B5C3]', {
						'is-invalid': touched && error,
					})}
				/>
				<span>
					<Search size={16} className='text-[#B5B5C3]' />
				</span>
			</div>

			{touched && error ? <div className='invalid-feedback text-xs'>{error}</div> : undefined}
		</div>
	)
}

export default SearchField
