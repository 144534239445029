import classNames from 'classnames'
import { getIn } from 'formik'
import CoApplicantDropdown from './co-applicant-dropdown'

export const createCoApplicantOption = (client, formValues, client_applicants, name) => {
	return {
		fullName: `${client?.first_name} ${client?.last_name}`,
		email: client?.email,
		first_name: client?.first_name,
		last_name: client?.last_name,
		value: client?.id,
		isDisabled:
			client_applicants.includes(client?.id) && getIn(formValues, name)?.value !== client?.id,
	}
}

export const createCoApplicantOptionLabel = (client) => {
	return (
		<div
			className={classNames(
				'flex items-center space-x-1',
				client?.isDisabled ? 'cursor-not-allowed opacity-50 hover:!bg-transparent' : '',
			)}>
			<span className='font-bold text-gray-700'>{client?.fullName}</span>
			<span className='text-sm text-gray-500'>({client?.email})</span>
		</div>
	)
}

export default CoApplicantDropdown
