import { DURATIONS } from '../payments-form'
import PaymentsListingContainer from './payments-listing-container'

export const getDuration = (druation) => {
	if (druation === DURATIONS.MONTHLY) {
		return 'month'
	}

	if (druation === DURATIONS.ANNUAL) {
		return 'year'
	}
}

export default PaymentsListingContainer
