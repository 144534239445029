import { MDBDataTableV5 } from 'mdbreact'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import UsersAction from './users-action'

const UsersTab = ({ users = [], team = {} }) => {
	const columns = useMemo(
		() => [
			{ label: '#', field: 'id', attributes: { style: { width: '50px' } } },
			{ label: 'Full Name', field: 'name', attributes: { style: { minWidth: '100px' } } },
			{ label: 'Email', field: 'email', attributes: { style: { minWidth: '100px' } } },
			{ label: 'Phone Number', field: 'phone', attributes: { style: { minWidth: '150px' } } },
			{ label: 'Role', field: 'role', attributes: { style: { minWidth: '80px' } } },
			{ label: 'Status', field: 'status', attributes: { style: { minWidth: '80px' } } },
			{ label: 'Action', field: 'actions', attributes: { style: { minWidth: '50px' } } },
		],
		[],
	)

	const rows = useMemo(
		() =>
			users
				.filter(
					(user) =>
						Array.isArray(user.groups) &&
						user.groups.length > 0 &&
						user.groups[0]?.group?.name !== 'Owner',
				)
				.map((user, idx) => ({
					id: idx + 1,
					name: user?.fullName || 'N/A',
					email: user?.email || 'N/A',
					phone: user?.phone_number || 'N/A',
					role:
						Array.isArray(user.groups) && user.groups.length > 0
							? user.groups[0]?.group?.name
							: '-',
					status: user?.status ? 'Active' : 'Inactive',
					actions: !user?.hasTeam ? <UsersAction team={team} user={user} /> : '—',
				})),
		[users, team],
	)

	return (
		<div className='rounded-md !border bg-white p-4 shadow-sm'>
			<h5 className='mb-6 font-semibold text-gray-700'>Other Users</h5>
			<MDBDataTableV5
				responsive
				bordered
				searchTop
				sortable={false}
				searchBottom={false}
				data={{ columns, rows }}
			/>
		</div>
	)
}

UsersTab.propTypes = {
	users: PropTypes.arrayOf(PropTypes.object),
	team: PropTypes.object,
}

export default UsersTab
