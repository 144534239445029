import { createApi } from '@reduxjs/toolkit/query/react'
import apiEndpoints from 'helpers/apiEndpoints'

import axiosBaseQuery from 'store/axios-base-query'

const API_URL = apiEndpoints.userSubscriptions

export const userSubscriptionApi = createApi({
	reducerPath: 'UserSubscriptions',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['UserSubscription', 'ActiveSubscription'],
	endpoints: (builder) => ({
		getMyAllSubscription: builder.query({
			query: () => ({
				url: `${API_URL}/subscriptions-history`,
				method: 'GET',
			}),
			transformResponse: (response, _, arg) => {
				const userHasSubscription = Array.isArray(response?.data?.userHasSubscription)
					? response?.data?.userHasSubscription.map((subscription, idx) => ({
							...subscription,
							index: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + idx + 1,
					  }))
					: []

				return {
					subscriptions: userHasSubscription,
					meta: response?.data?.meta,
				}
			},
			providesTags: ['UserSubscription'],
		}),
		getMySubscription: builder.query({
			query: () => ({
				url: `${API_URL}/my-subscription`,
				method: 'GET',
			}),
			providesTags: ['ActiveSubscription'],
		}),
		assignSubscription: builder.mutation({
			query: (payload) => ({
				url: API_URL,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['UserSubscription'],
		}),
		getUserSubscription: builder.query({
			query: (id) => ({
				url: `${apiEndpoints.getSubscriptions}/${id}`,
				method: 'GET',
			}),
			providesTags: ['UserSubscription'],
		}),
		cancelSubscription: builder.mutation({
			query: (payload) => ({
				url: apiEndpoints.cancelSubscriptions,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['ActiveSubscription'],
		}),
		getPricing: builder.query({
			query: () => ({
				url: '/stripe/pricing-list',
				method: 'GET',
			}),
		}),
		updateSubscription: builder.mutation({
			query: (payload) => ({
				url: '/stripe/update-subscription',
				method: 'POST',
				data: payload,
			}),
		}),
	}),
})

export const {
	useAssignSubscriptionMutation,
	useLazyGetUserSubscriptionQuery,
	useLazyGetMySubscriptionQuery,
	useLazyGetMyAllSubscriptionQuery,
	useCancelSubscriptionMutation,
	useGetPricingQuery,
	useUpdateSubscriptionMutation,
} = userSubscriptionApi
