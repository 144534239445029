import classNames from 'classnames'
import { useLazyGetPaymentQuery } from 'modules/payments/payments-api'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import PaymentsDetailsCard from './payment-details-card'

const PaymentsDetailsContainer = () => {
	const params = useParams()
	const [fetchPayment, { data, isFetching: isLoading }] = useLazyGetPaymentQuery(params?.id)
	useEffect(() => {
		if (params?.id) {
			fetchPayment(params?.id)
		}
	}, [params?.id])

	const paymentDetails = useMemo(() => data?.payment, [data])
	return (
		<div>
			<div className='grid grid-cols-2 gap-3 edit_client_alignment'>
				{paymentDetails?.map((elem, ind) => {
					return (
						<div
							key={ind}
							className={classNames(
								ind === paymentDetails.length - 1 ? 'col-span-2' : '',
							)}>
							<PaymentsDetailsCard
								title={elem?.component}
								data={elem?.details}
								isLoading={isLoading}
							/>
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default PaymentsDetailsContainer
