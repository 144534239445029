import { Dialog, Transition } from '@headlessui/react'
import { ReactComponent as SearchIcon } from 'assets/svg/search-icon.svg'
import { Field, FormikProvider, useFormik } from 'formik'
import { useAppRouter } from 'hooks'
import { useLazyGetDocumentTemplatesQuery } from 'modules/documents/document-api'
import {
	useLazyGetDocumentPackagesQuery,
	useUpdateDocumentPackageMutation,
} from 'modules/documents/document-package-api'
import { DOCUMENT_TEMPLATE_APP_ROUTES } from 'modules/documents/routes'
import { Fragment, useEffect, useMemo, useState } from 'react'
import SimpleBar from 'simplebar-react'
import notification from 'utilities/notification'
import DataListDocuments from './data-list-documents'
import DataListFolder from './data-list-folder'

const AddToFolder = ({ isOpen, onClose, type = 'folder', file, addType }) => {
	const formik = useFormik({
		initialValues: {
			search: '',
			search_type: {
				icon: 'uil-file-blank',
				label: 'All',
				value: 'all',
			},
		},
	})
	const [selectedIds, setSelectedIds] = useState([])
	const [isSubmitting, setIsSubmitting] = useState(false)
	const { navigate } = useAppRouter()

	const handleSelectionChange = (ids) => {
		setSelectedIds(ids)
	}
	const [fetchFolder, { data: folders, isFetching: loading }] = useLazyGetDocumentPackagesQuery()

	const [fetchDocumentTemplates, { data, isFetching: isLoading }] =
		useLazyGetDocumentTemplatesQuery()
	const [updateDocumentPackage] = useUpdateDocumentPackageMutation()

	useEffect(() => {
		if (isOpen) {
			if (type === 'folder') {
				const delayDebounce = setTimeout(() => {
					const query = { page: 1, limit: 20 }
					if (formik.values.search && formik.values.search.length >= 3) {
						Object.assign(query, { search: formik.values.search })
					}
					fetchFolder(query)
				}, 500)

				return () => clearTimeout(delayDebounce)
			}
			if (type === 'document') {
				const delayDebounce = setTimeout(() => {
					fetchDocumentTemplates({
						params: {
							page: 1,
							limit: 20,
							search: formik.values.search || '',
						},
					})
				}, 500)

				return () => clearTimeout(delayDebounce)
			}
		}
	}, [type, formik.values.search, isOpen])

	const documents = useMemo(() => {
		if (!data?.documents || !file?.documents) return null

		const currentDocIDs = new Set(file.documents.map((doc) => doc.id))

		return data.documents.filter((doc) => !currentDocIDs.has(doc.id))
	}, [data?.documents, file?.documents])

	const $folders = useMemo(() => {
		const filterData = folders?.data?.filter((doc) => doc?.id !== file?.id)

		return filterData?.length > 0 ? filterData : null
	}, [folders])

	const listData = type === 'folder' ? $folders : documents

	const addToFunction = async (type) => {
		if (type == 'document') {
			setIsSubmitting(true)
			const currentDocIDs = new Set(file.documents.map((doc) => doc.id))
			const documentsIds = [...new Set([...selectedIds, ...Array.from(currentDocIDs)])]
			const payload = {
				documents: documentsIds.map((item) => ({ id: item })),
			}
			const response = await updateDocumentPackage({ payload, id: file.id })
			if (response?.data?.status === 200) {
				notification('success', response?.data?.message)
				onClose()
			}
			setIsSubmitting(false)
		}

		if (type == 'folder') {
			if (addType === 'move') {
				setIsSubmitting(true)
				const payload = { title: file.title }
				if (selectedIds) {
					payload.parentDocumentPackageId = selectedIds
				}
				const response = await updateDocumentPackage({ payload, id: file.id })
				if (response?.data?.status === 200) {
					notification('success', response?.data?.message)
					onClose()
				}
				setIsSubmitting(false)
			}
			if (addType === 'copy') {
				setIsSubmitting(true)
				const payload = {
					documents: [{ id: file.id }],
				}
				const response = await updateDocumentPackage({ payload, id: selectedIds })
				if (response?.data?.status === 200) {
					notification('success', response?.data?.message)
					onClose()
				}
				setIsSubmitting(false)
			}
		}
	}

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-[1008]' onClose={onClose}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/30' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'>
							<Dialog.Panel className='w-full max-w-lg transform overflow-hidden rounded-3xl bg-white p-2 text-left align-middle shadow-xl transition-all'>
								<div className='relative flex justify-end rounded-3xl bg-blue-50 p-3'>
									<button type='button' onClick={onClose}>
										<i className='fas fa-times rounded-full bg-white px-2 py-1 text-[20px]' />
									</button>
								</div>
								<div className='mt-3 flex flex-col '>
									<div className='ms-4 flex flex-col justify-start ps-2'>
										<Dialog.Title
											as='h2'
											className='mb-3 text-left text-xl font-bold leading-6 text-gray-900'>
											{type === 'folder'
												? 'Move to folder'
												: 'Select document to add'}
										</Dialog.Title>
										<div className='flex h-[56px] w-full max-w-[512px] items-center space-x-1 rounded'>
											<FormikProvider value={formik}>
												<form className='flex-1 !pe-6'>
													<div className='flex h-[56px] w-full items-center space-x-1 rounded px-2.5 shadow'>
														<div className='px-2'>
															<SearchIcon height={20} width={20} />
														</div>
														<Field name='search'>
															{({ field }) => (
																<input
																	type='text'
																	{...field}
																	onKeyDown={(e) => {
																		if (e.key === ' ') {
																			e.stopPropagation()
																		}
																	}}
																	autoComplete='off'
																	className='flex-1 px-1 py-2 text-base font-normal text-gray2-dark'
																	placeholder='Search documents & folder'
																/>
															)}
														</Field>
													</div>
												</form>
											</FormikProvider>
										</div>
									</div>
									<div className='ms-4 mt-2 flex flex-col justify-start gap-1 pe-4 ps-2'>
										<SimpleBar style={{ maxHeight: '300px' }}>
											{type === 'folder' ? (
												<DataListDocuments
													type={type}
													listData={listData}
													isLoading={isLoading || loading}
													onSelectionChange={handleSelectionChange}
												/>
											) : (
												<>
													<DataListFolder
														type={type}
														listData={listData}
														isLoading={isLoading || loading}
														onSelectionChange={handleSelectionChange}
													/>
												</>
											)}
										</SimpleBar>
									</div>

									<hr className='mx-4 my-3 bg-gray-300' />
									<div className='mb-3 ms-4 flex w-full flex-col gap-2 pe-5 ps-2'>
										<button
											type='button'
											disabled={listData?.length === 0 || isSubmitting}
											onClick={() => addToFunction(type)}
											className={`rounded-md !border p-2 font-medium text-white ${
												listData?.length > 0
													? 'border-main bg-main'
													: 'border-gray-400 bg-gray-500'
											}`}>
											Add Now
										</button>

										{type === 'document' && (
											<button
												type='button'
												className='rounded-md !border !border-main bg-white p-2 font-medium text-main'
												onClick={() =>
													navigate(DOCUMENT_TEMPLATE_APP_ROUTES.create())
												}>
												+ Create new
											</button>
										)}
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export default AddToFolder
