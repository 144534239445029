import { Fragment, useState } from 'react'

import classNames from 'classnames'
import { ChevronDown } from 'lucide-react'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import { POPUP_STATES } from '.'
import OnboardPopup from './onboard-popup/onboard-popup'
import SendInvitationPopup from './send-invitation-popup/send-invitation-popup'

const BulkActions = ({
	selectedRows,
	assignWorkflow,
	checkedTags,
	selectedRecords,
	isAssignDisabled,
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const toggle = () => setIsOpen(!isOpen)

	const [popupStates, setPopupStates] = useState({
		[POPUP_STATES.ONBOARD_NOW]: false,
		[POPUP_STATES.SEND_INVITATION]: false,
	})

	const onPopupOpen = (key) => {
		setPopupStates((prev) => ({
			...prev,
			[key]: true,
		}))
	}

	const onPopupClose = (key) => {
		setPopupStates((prev) => ({
			...prev,
			[key]: false,
		}))
	}

	return (
		<Fragment>
			<OnboardPopup
				onClose={() => onPopupClose(POPUP_STATES.ONBOARD_NOW)}
				isOpen={popupStates[POPUP_STATES.ONBOARD_NOW]}
				selectedRows={selectedRows}
			/>
			<SendInvitationPopup
				onClose={() => onPopupClose(POPUP_STATES.SEND_INVITATION)}
				isOpen={popupStates[POPUP_STATES.SEND_INVITATION]}
				selectedRows={selectedRows}
			/>
			<Dropdown isOpen={isOpen} toggle={() => toggle()}>
				<DropdownToggle
					tag='button'
					type='button'
					className={classNames('btn btn-secondary max-w-fit', {
						'': isOpen,
					})}>
					<div className='flex items-center justify-between space-x-3 text-[#B5B5C3]'>
						<span>Actions</span>
						<span className=''>
							<ChevronDown size={16} />
						</span>
					</div>
				</DropdownToggle>
				<DropdownMenu className='dropdown-menu-end mt-2 p-0 py-2 shadow-md'>
					<ul className='m-0 flex flex-col p-0'>
						<li
							className='exp-dropdown-item'
							onClick={() => onPopupOpen(POPUP_STATES.SEND_INVITATION)}>
							<span>Send Invitation</span>
						</li>
						<li
							className='exp-dropdown-item'
							onClick={() => onPopupOpen(POPUP_STATES.ONBOARD_NOW)}>
							<span>Onboard Now</span>
						</li>
						{checkedTags?.length > 0 &&
						isAssignDisabled &&
						selectedRecords?.length > 0 ? (
							<li className='exp-dropdown-item' onClick={assignWorkflow}>
								<span>Assign Workflow</span>
							</li>
						) : undefined}
					</ul>
				</DropdownMenu>
			</Dropdown>
		</Fragment>
	)
}

export default BulkActions
