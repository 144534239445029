const Header = () => {
	return (
		<div className='flex w-full flex-col'>
			<div className='mb-3 flex w-full justify-between'>
				<div className='max-w-[733px]'>
					<h4 className='page-title'>User Management</h4>
				</div>
			</div>
			<div className='-mb-[12px] space-x-2'></div>
		</div>
	)
}

export default Header
