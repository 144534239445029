import { uniqWith } from 'lodash'
import Filters from './filters'

export const getFormikFilterValues = (values) => {
	const filters = {}
	if (values?.view_by) {
		Object.assign(filters, { view_by: values?.view_by?.value })
	}
	return filters
}

export const getInitialValues = (queryObj) => {
	const initialValues = {
		view_by: '',
	}
	if (queryObj?.view_by) {
		Object.assign(initialValues, { view_by: queryObj?.view_by })
	}

	return initialValues
}

const getFitlerKey = (key) => {
	if (['from', 'to'].includes(key)) {
		return 'date_range'
	}
	return key
}

export const getActiveFilters = (filterObj) => {
	const filtersArray = Object.entries(filterObj)
		.filter((filter) => !['page', 'limit'].includes(filter[0]))
		.filter((filter) => {
			// eslint-disable-next-line no-unused-vars
			const [_, values] = filter
			return values.length > 0
		})
		.map((filter) => {
			const [key, values] = filter
			return [
				{
					label: filterKeys[key],
					name: getFitlerKey(key),
				},
				values,
			]
		})
	return uniqWith(filtersArray, (arrayVal, otherVal) => {
		return arrayVal[0]?.name === otherVal[0]?.name
	})
}

export const filterKeys = {
	view_by: 'View By',
	search: 'Search',
}

export default Filters
