import * as yup from 'yup'

import { capitalize } from 'lodash'
import notification from 'utilities/notification'
import SubscriptionForm from './subscription-form'

const PLAN_TYPES = {
	INDIVIDUAL: 'individual',
	TEAM: 'team',
	ENTERPRISE: 'enterprise',
}

const createOption = (value, isDisabled = false) => ({
	label: capitalize(value),
	value: value,
	isDisabled,
})

export const planTypeOptions = [
	createOption(PLAN_TYPES.INDIVIDUAL),
	createOption(PLAN_TYPES.TEAM),
	// createOption(PLAN_TYPES.ENTERPRISE),
]

export const DURATIONS = {
	DAILY: 'day',
	WEEKLY: 'week',
	MONTHLY: 'month',
	YEARLY: 'year',
}

export const META_OPTIONS = [
	{ value: 'max_users', label: 'Maximum Number of Users' },
	{ value: 'max_pdf_views', label: 'Maximum PDF Views' },
	{ value: 'max_workflows', label: 'Maximum Number of Workflows' },
	{ value: 'max_document_templates', label: 'Maximum Number of Document Templates' },
]

export const durationOptions = [
	createOption(DURATIONS.DAILY),
	createOption(DURATIONS.WEEKLY),
	createOption(DURATIONS.MONTHLY),
	createOption(DURATIONS.YEARLY),
]

export const validationSchema = yup.object().shape({
	name: yup.string().required('Please enter a subscription name'),
	prices_data: yup
		.array()
		.of(
			yup.object().shape({
				unitAmount: yup
					.number()
					.typeError('Amount must be a valid number')
					.min(0, 'Amount cannot be negative')
					.required('Amount is required'),
				recurring: yup.object().shape({
					interval: yup.mixed().required('Recurring interval is required'),
					interval_count: yup
						.number()
						.typeError('Interval count must be a number')
						.integer('Interval count must not be an dasimal value')
						.required('Interval count is required'),
				}),
			}),
		)
		.required('At least one price data entry is required'),
})

export const getInitialValues = (subscription) => {
	const mapPricesData = (prices = []) =>
		prices.length > 0
			? prices.map((price) => ({
					unitAmount: price?.unit_amount || 0,
					currency: price?.currency || 'usd',
					recurring: {
						interval: price?.recurring?.interval
							? {
									value: price.recurring.interval,
									label: capitalize(price.recurring.interval),
							  }
							: { value: 'month', label: 'Monthly' },
						interval_count: price?.recurring?.interval_count || 1,
					},
			  }))
			: [
					{
						unitAmount: 0,
						currency: 'usd',
						recurring: {
							interval: { value: 'month', label: 'Monthly' },
							interval_count: 1,
						},
					},
			  ]

	const mapMetadata = (metadata = {}) =>
		Object.entries(metadata).map(([key, value]) => ({
			key: key || '',
			value: value || '',
		})) || [{ key: '', value: '' }]

	return {
		name: subscription?.name || '',
		description: subscription?.description || '',
		prices_data: mapPricesData(subscription?.price),
		statement_descriptor: subscription?.statement_descriptor || '',
		metadata: subscription?.metadata
			? mapMetadata(subscription.metadata)
			: [{ key: '', value: '' }],
		marketing_features: subscription?.marketing_features?.length
			? subscription.marketing_features
			: [''],
	}
}
const convertMetadata = (metadataArray) => {
	const seenKeys = new Set()

	for (const item of metadataArray) {
		if (seenKeys.has(item.key.value)) {
			notification('error', `Duplicate key detected: ${item.key.label}`)
			return null
		}

		seenKeys.add(item.key.value)
	}

	return metadataArray.reduce((acc, item) => {
		acc[item.key.value] = item.value
		return acc
	}, {})
}

export const getPayload = (values, type) => {
	const metadata = values?.metadata?.length > 0 ? convertMetadata(values.metadata) : {}
	if (!metadata) return null // Exit if metadata validation fails

	const basePayload = {
		name: values?.name,
		description: values?.description,
		statement_descriptor: values?.statement_descriptor,
		marketing_features: values?.marketing_features,
		metadata,
	}

	// Helper function for default and recurring price data
	const formatPriceData = (price) => ({
		currency: price?.currency,
		unitAmount: price?.unitAmount,
		recurring: price?.recurring
			? {
					interval: price.recurring.interval?.value,
					interval_count: price.recurring.interval_count,
			  }
			: undefined,
	})

	if (type === 'Recurring') {
		basePayload.prices_data = values?.prices_data?.map(formatPriceData) || []
		basePayload.default_price_data = formatPriceData(values?.prices_data?.[0])
	} else {
		const nonRecurringPrice = formatPriceData(values?.prices_data?.[0])
		basePayload.prices_data = [nonRecurringPrice]
		basePayload.default_price_data = nonRecurringPrice
	}
	return basePayload
}

export default SubscriptionForm
