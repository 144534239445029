import { useUser } from 'hooks'
import { useEffect, useMemo, useState } from 'react'
import { Field, reduxForm } from 'redux-form'
import { permissions } from 'utilities/permissions'

import placeholderAvatar from 'assets/images/users/avatar1.jpg'
import ButtonWithLoader from 'components/Common/ButtonWithLoader'
import DocumentPreview from 'components/Common/DocumentPreview'
import InputCountrySelect from 'components/Forms/InputCountrySelect'
import { TextField } from 'components/Forms/TextFieldComponent'
import UserQuery from 'hooks/UserQuery'
import { snakeCase } from 'lodash'
import { useDisconnectNylasMutation, useLazyGetNylasQuery } from 'modules/authentication/auth-api'
import { useSelector } from 'react-redux'
import AsyncImage from 'theme/ui/async-image'
import { Button, FileUploadField } from 'theme/ui/forms'
import { isAllowTo } from 'utilities/helpers'
import notification from 'utilities/notification'
import { phoneRequired, requiredName } from 'utils/formValidation'
import SwitchField from './switch-field'

const validate = {
	first_name: requiredName('First Name'),
	last_name: requiredName('Last Name'),
	phone_number: phoneRequired('Phone Number'),
}

const ProfileForm = ({
	user,
	loading,
	upLoader,
	isUpload,
	avatarRef,
	isUploaded,
	setIsUpload,
	handleUpload,
	handleSubmit,
	onAvatarSave,
}) => {
	const { group, team } = useUser()
	const [isOpen, setIsOpen] = useState(false)
	const [url, setUrl] = useState(null)
	const { user: profile } = UserQuery()
	const avatar = useMemo(() => profile?.avatar?.path, [user])
	const [picture, setPicture] = useState(null)

	useEffect(() => {
		if (avatar) {
			setPicture(avatar)
		}
	}, [avatar])

	const toggle = async (url) => {
		setIsOpen(!isOpen)
		setUrl(url)
	}

	const [fetchNylas, { isLoading }] = useLazyGetNylasQuery()
	const [disconnectNylas, { isLoading: isFetching }] = useDisconnectNylasMutation()
	const { defaultGroup } = useSelector((state) => state.Permissions)
	const currentGroup = useMemo(() => snakeCase(defaultGroup?.group?.name), [defaultGroup])

	const fetchApi = async () => {
		try {
			const response = await fetchNylas().unwrap()

			if (response.status === 200 && response.data) {
				window.location.href = response.data
			}
		} catch (error) {
			notification('error', error?.message || 'Failed to link email.')
		}
	}

	const disconnectEmail = async () => {
		try {
			const response = await disconnectNylas(team?.nylasGrantId).unwrap()

			if (response?.status === 200) {
				notification('success', response?.message || 'Email successfully unlinked.')
				window.location.reload()
			}
		} catch (error) {
			notification('error', error?.message || 'Failed to unlink email.')
		}
	}

	return (
		<form onSubmit={handleSubmit} className='flex flex-col divide-y divide-dashed'>
			<div className='justify-between gap-10 py-6 sm:flex'>
				<div className='flex-1'>
					<div className='flex flex-col items-center'>
						<div className='relative flex flex-shrink-0 flex-col items-center '>
							<div className='absolute bottom-8 right-[-5px] h-[20px] w-[20px] rounded-full border-[4px] border-white bg-[#34C38F]' />
							<AsyncImage
								src={picture || placeholderAvatar}
								alt='profile'
								className='h-[275px] w-[275px] self-center rounded-xl !border  object-cover'
							/>
							<button
								type='button'
								className='btn btn-link btn-sm'
								onClick={() => setIsUpload(!isUpload)}>
								<i className='uil uil-edit' /> Change
							</button>
						</div>
						{isUpload ? (
							<div className='flex flex-col items-center'>
								<FileUploadField
									fileRef={avatarRef}
									isUploading={upLoader}
									onUpload={handleUpload}
									allowedExtentions='.apng,.avif,.gif,.jpg,.jpeg,.pjpeg,.png,.webp'
								/>
								{isUploaded ? (
									<Button
										isLoading={loading}
										isLoadingText='Saving...'
										type='button'
										onClick={onAvatarSave}>
										Save
									</Button>
								) : undefined}
							</div>
						) : undefined}
					</div>
				</div>
				<div className='not-prose grid grid-cols-1 gap-x-6 sm:min-w-[400px]'>
					<div className='grid grid-cols-2 gap-x-6'>
						<Field
							type='text'
							name='first_name'
							component={TextField}
							label='First Name *'
							validate={validate.first_name}
						/>

						<Field
							type='text'
							name='last_name'
							component={TextField}
							label='Last Name *'
							validate={validate.last_name}
						/>
					</div>

					<Field
						type='text'
						name='phone_number'
						component={InputCountrySelect}
						label='Phone Number *'
						validate={validate.phone_number}
					/>

					<Field
						type='text'
						name='email'
						component={TextField}
						label='Email *'
						disabled
					/>

					{currentGroup === 'owner' && (
						<div className='border-info bg-light my-2 rounded border px-2 py-2'>
							<div className='font-medium'>
								<span className='mb-2'>
									{!team?.nylasGrantId
										? 'Connect your email account here to send all workflow-related emails directly from your own address.'
										: 'Disconnect your email inbox to stop sending.'}
								</span>
								<p className='m-0 p-0'>
									messages directly to your custodians?{' '}
									<button
										onClick={!team?.nylasGrantId ? fetchApi : disconnectEmail}
										disabled={isLoading || isFetching}
										className='text-info font-bold'>
										{isLoading || isFetching
											? 'Loading...'
											: !team?.nylasGrantId
											? 'Link My Email Inbox'
											: 'Unlink My Email Inbox'}
									</button>
								</p>
							</div>
						</div>
					)}

					<div className='form-group'>
						<label>Role</label>
						<input
							type='text'
							disabled
							defaultValue={group?.name}
							className='form-control'
						/>
					</div>
				</div>
			</div>

			<div className='justify-between gap-10 py-6 sm:flex'>
				<div className='prose my-3 max-w-none'>
					<h3>Address Information</h3>
					<p className='text-sm text-gray-500'>
						Location at Your Fingertips: Seamlessly Update and Organize Your Address
						Information for Convenient Access.
					</p>
				</div>
				<div className='grid grid-cols-1 gap-x-6 sm:min-w-[400px]'>
					<Field
						type='text'
						name='userProfile.address_1'
						component={TextField}
						label='Address 1'
					/>

					<Field
						type='text'
						name='userProfile.address_2'
						component={TextField}
						label='Address 2'
					/>

					<Field type='text' name='userProfile.unit' component={TextField} label='Unit' />

					<div className='grid grid-cols-2 gap-x-6'>
						<Field
							type='text'
							name='userProfile.city'
							component={TextField}
							label='City'
						/>

						<Field
							type='text'
							name='userProfile.province'
							component={TextField}
							label='Province'
						/>
					</div>

					<div className='grid grid-cols-2 gap-x-6'>
						<Field
							type='text'
							name='userProfile.zip_code'
							component={TextField}
							label='Zip Code'
						/>

						<Field
							type='text'
							name='userProfile.country'
							component={TextField}
							label='Country'
						/>
					</div>
				</div>
			</div>

			<div className='items-center justify-between gap-10 py-6 sm:flex'>
				<div className='prose my-3 max-w-none'>
					<h3>Multi-Factor Authentication</h3>
					<p className='text-sm text-gray-500'>
						Multi-Factor Authentication (MFA) settings enable individuals to configure
						additional layers of security.
					</p>
				</div>
				<div className='grid grid-cols-1 gap-x-6 sm:min-w-[400px] sm:max-w-[500px]'>
					<div className='flex h-fit w-full items-center justify-between space-x-3 rounded-md !border border-gray-300 p-3'>
						<span className='text-sm text-gray-500'>
							Toggle the switch to enable or disabled the Multi-Factor Authentication
						</span>
						<Field type='checkbox' name='isMfaEnabled' component={SwitchField} />
					</div>
				</div>
			</div>

			<DocumentPreview url={url} toggle={toggle} isOpen={isOpen} />
			<div className='flex justify-end py-6'>
				<ButtonWithLoader
					isDisabled={!isAllowTo(permissions?.profileDetails?.edit)}
					loading={loading}
					color='primary'
					type='submit'>
					Update
				</ButtonWithLoader>
			</div>
		</form>
	)
}

export default reduxForm({
	form: 'user_profile',
	enableReinitialize: true,
})(ProfileForm)
