import { useHistory, useParams } from 'react-router-dom'

import { SpinLoader } from 'components/Common'
import { SystemFieldForm, SystemFieldQuery } from 'modules/system-fields'
import ResourceNotFound from 'pages/StaticPages/ResourceNotFound'
import { PageHeader } from 'theme/layouts'
import AppLayout from 'theme/layouts/app-layout'

const SystemFieldsEdit = () => {
	const history = useHistory()
	const params = useParams()
	const ID = params?.id
	const { field, isLoading } = SystemFieldQuery(ID)

	return (
		<AppLayout
			meta={{
				title: 'Edit System Field',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<PageHeader title='Edit System Field' hasBackButton={true} />

					<div className='page-body'>
						{isLoading ? (
							<SpinLoader />
						) : (
							<>
								{field ? (
									<SystemFieldForm field={field} />
								) : (
									<ResourceNotFound
										title='System field not found'
										description='Request system field is not found'
									/>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default SystemFieldsEdit
