import { Fragment, useEffect } from 'react'

import errorImg from 'assets/images/404-error.png'
import { useAppRouter } from 'hooks'
import PricingHeader from 'modules/subscriptions/subscription-pricing-table/pricing-header'
import { useLazyGetStripeCheckoutSessionQuery } from 'modules/subscriptions/subscriptions-api'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { Col, Row } from 'reactstrap'
import Spinner from 'theme/ui/spinner'
import { formatPrice } from 'utilities/helpers'
import IsAuthenticated from 'utils/isAuthenticated'

const PaymentConfirmation = () => {
	const { parseQuery } = useAppRouter()
	const checkout_session_id = parseQuery?.checkout_session
	const [fetchCheckoutSession, { data, isUninitialized, isLoading }] =
		useLazyGetStripeCheckoutSessionQuery()
	const customer_email = data?.customer_details?.email
	const isLoggedIn = IsAuthenticated()

	useEffect(() => {
		if (checkout_session_id) {
			fetchCheckoutSession(checkout_session_id)
		}
	}, [checkout_session_id])

	useEffect(() => {
		document.body.className = 'bg-white'
		return function cleanup() {
			document.body.className = ''
		}
	})

	return (
		<div className='my-auto flex min-h-screen w-full flex-col space-y-6'>
			<PricingHeader />
			<div className='flex justify-center p-6'>
				{isUninitialized || isLoading ? (
					<div className='w-full max-w-md '>
						<Spinner />
					</div>
				) : (
					<Fragment>
						{data ? (
							<div className='w-full max-w-md rounded-lg !border bg-white p-6 text-center '>
								{data?.payment_status === 'paid' ? (
									<div className='mx-auto mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-green-100'>
										<svg
											viewBox='0 0 24 24'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'>
											<g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
											<g
												id='SVGRepo_tracerCarrier'
												strokeLinecap='round'
												strokeLinejoin='round'></g>
											<g id='SVGRepo_iconCarrier'>
												<path
													d='M10.5 15.25C10.307 15.2353 10.1276 15.1455 9.99998 15L6.99998 12C6.93314 11.8601 6.91133 11.7029 6.93756 11.55C6.96379 11.3971 7.03676 11.2562 7.14643 11.1465C7.2561 11.0368 7.39707 10.9638 7.54993 10.9376C7.70279 10.9114 7.86003 10.9332 7.99998 11L10.47 13.47L19 5.00004C19.1399 4.9332 19.2972 4.91139 19.45 4.93762C19.6029 4.96385 19.7439 5.03682 19.8535 5.14649C19.9632 5.25616 20.0362 5.39713 20.0624 5.54999C20.0886 5.70286 20.0668 5.86009 20 6.00004L11 15C10.8724 15.1455 10.6929 15.2353 10.5 15.25Z'
													fill='#199400'></path>{' '}
												<path
													d='M12 21C10.3915 20.9974 8.813 20.5638 7.42891 19.7443C6.04481 18.9247 4.90566 17.7492 4.12999 16.34C3.54037 15.29 3.17596 14.1287 3.05999 12.93C2.87697 11.1721 3.2156 9.39921 4.03363 7.83249C4.85167 6.26578 6.1129 4.9746 7.65999 4.12003C8.71001 3.53041 9.87134 3.166 11.07 3.05003C12.2641 2.92157 13.4719 3.03725 14.62 3.39003C14.7224 3.4105 14.8195 3.45215 14.9049 3.51232C14.9903 3.57248 15.0622 3.64983 15.116 3.73941C15.1698 3.82898 15.2043 3.92881 15.2173 4.03249C15.2302 4.13616 15.2214 4.2414 15.1913 4.34146C15.1612 4.44152 15.1105 4.53419 15.0425 4.61352C14.9745 4.69286 14.8907 4.75712 14.7965 4.80217C14.7022 4.84723 14.5995 4.87209 14.4951 4.87516C14.3907 4.87824 14.2867 4.85946 14.19 4.82003C13.2186 4.52795 12.1987 4.43275 11.19 4.54003C10.193 4.64212 9.22694 4.94485 8.34999 5.43003C7.50512 5.89613 6.75813 6.52088 6.14999 7.27003C5.52385 8.03319 5.05628 8.91361 4.77467 9.85974C4.49307 10.8059 4.40308 11.7987 4.50999 12.78C4.61208 13.777 4.91482 14.7431 5.39999 15.62C5.86609 16.4649 6.49084 17.2119 7.23999 17.82C8.00315 18.4462 8.88357 18.9137 9.8297 19.1953C10.7758 19.4769 11.7686 19.5669 12.75 19.46C13.747 19.3579 14.713 19.0552 15.59 18.57C16.4349 18.1039 17.1818 17.4792 17.79 16.73C18.4161 15.9669 18.8837 15.0864 19.1653 14.1403C19.4469 13.1942 19.5369 12.2014 19.43 11.22C19.4201 11.1169 19.4307 11.0129 19.461 10.9139C19.4914 10.8149 19.5409 10.7228 19.6069 10.643C19.6728 10.5631 19.7538 10.497 19.8453 10.4485C19.9368 10.3999 20.0369 10.3699 20.14 10.36C20.2431 10.3502 20.3471 10.3607 20.4461 10.3911C20.5451 10.4214 20.6372 10.471 20.717 10.5369C20.7969 10.6028 20.863 10.6839 20.9115 10.7753C20.9601 10.8668 20.9901 10.9669 21 11.07C21.1821 12.829 20.842 14.6026 20.0221 16.1695C19.2022 17.7363 17.9389 19.0269 16.39 19.88C15.3288 20.4938 14.1495 20.8755 12.93 21C12.62 21 12.3 21 12 21Z'
													fill='#199400'></path>{' '}
											</g>
										</svg>
									</div>
								) : (
									<div className='mx-auto mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-red-100'>
										<svg
											viewBox='0 0 24 24'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'>
											<g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
											<g
												id='SVGRepo_tracerCarrier'
												strokeLinecap='round'
												strokeLinejoin='round'></g>
											<g id='SVGRepo_iconCarrier'>
												{' '}
												<path
													d='M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5'
													stroke='#c70000'
													strokeWidth='1.5'
													strokeLinecap='round'></path>{' '}
												<path
													d='M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7'
													stroke='#c70000'
													strokeWidth='1.5'
													strokeLinecap='round'></path>{' '}
											</g>
										</svg>
									</div>
								)}

								<h1 className='mb-2 text-2xl font-semibold text-gray-800'>
									{data?.payment_status === 'paid'
										? 'Payment Successful'
										: 'Payment Failed'}
								</h1>
								<p className='mb-6 text-gray-600'>
									Thank you for your payment. Your transaction was completed
									successfully.
								</p>

								<div className='mb-6 rounded-lg border bg-gray-50 p-4 text-left'>
									<p className='text-sm text-gray-500'>Payment Details:</p>
									{/* <div className='mt-2 flex justify-between'>
										<span className='font-medium text-gray-700'>
											Transaction ID:
										</span>
										<span className='text-gray-900'>#123456789</span>
									</div> */}
									<div className='mt-2 flex justify-between'>
										<span className='font-medium text-gray-700'>
											Amount Paid:
										</span>
										<span className='text-gray-900'>
											{formatPrice(
												data?.amount_total
													? parseInt(data?.amount_total) / 100
													: 0,
											)}
										</span>
									</div>
									<div className='mt-2 flex justify-between'>
										<span className='font-medium text-gray-700'>
											Payment Date:
										</span>
										<span className='text-gray-900'>
											{new Date(data?.created * 1000).toLocaleString()}
										</span>
									</div>
								</div>

								<div className='flex items-center justify-center space-x-6'>
									{isLoggedIn ? (
										<Link
											to={`/profile?tab=3`}
											className='inline-block text-main  hover:!underline'>
											<i className='uil-arrow-left'></i>
											<span>Back to My Subscription</span>
										</Link>
									) : (
										<Link
											to={`/login?email=${customer_email}`}
											className='inline-block text-main  hover:!underline'>
											<i className='uil-arrow-left'></i>
											<span>Login to Experivise</span>
										</Link>
									)}
								</div>
							</div>
						) : (
							<div className='w-full max-w-md '>
								<div className='text-center'>
									<div>
										<Row className='row justify-content-center'>
											<Col sm={4}>
												<div className='error-img'>
													<img
														src={errorImg}
														alt=''
														className='img-fluid d-block mx-auto'
													/>
												</div>
											</Col>
										</Row>
									</div>
									<h4 className='text-uppercase mt-4'>
										Sorry, payment details not found
									</h4>
									<p className='text-muted'>
										The requested resource is not found in our system. Please
										ensure that the resource exists in the system.
									</p>
									<div className='mt-5'>
										<Link
											className='btn btn-primary waves-effect waves-light'
											to='/'>
											Go back
										</Link>
									</div>
								</div>
							</div>
						)}
					</Fragment>
				)}
			</div>
		</div>
	)
}

export default PaymentConfirmation
