import { Link } from 'react-router-dom'
import { isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'
import { ASSIGN_WORKFLOW_APP_ROUTES } from '../../assigned-workflows/routes'

const Actions = ({ id }) => {
	const canFill = isAllowTo(permissions?.assignedWorkflow?.fill)
	return (
		<div className='w-full'>
			<div className='flex w-full space-x-3'>
				{canFill ? (
					<Link
						to={ASSIGN_WORKFLOW_APP_ROUTES.findOne(id)}
						className='action-btn btn-primary-outline'>
						<i className='uil uil-eye' style={{ fontSize: 16 }} />
						<span>View</span>
					</Link>
				) : undefined}
			</div>
		</div>
	)
}

export default Actions
