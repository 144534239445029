import ViewByDropdown from './view-by-dropdown'

export const viewByOptions = [
	{
		label: 'Last edited',
		value: 'last_edited',
	},
	{
		label: 'A to Z',
		value: 'a_to_z',
	},
	{
		label: 'Folder wise',
		value: 'folder_wise',
	},
]

export default ViewByDropdown
