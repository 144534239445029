import { useMemo } from 'react'
import { useDebounce } from 'use-debounce'

import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import { toQueryString } from 'utils/commonFunctions'
import { createOption } from '.'

const DocumentPackagesQuery = (search = '') => {
	const [debounceSearch] = useDebounce(search, 600)

	const queryParams = useMemo(() => {
		const params = { page: 1, limit: 20 }
		if (debounceSearch) {
			Object.assign(params, { search: debounceSearch })
		}
		return params
	}, [debounceSearch])

	const query = toQueryString(queryParams)
	const URL = useMemo(
		() => (query ? `${apiEndpoints.documentPackage}?${query}` : apiEndpoints.documentPackage),
		[query],
	)
	const { data, isLoading } = useQuery(URL)

	const documentPackages = useMemo(
		() =>
			Array.isArray(data?.data?.documentPackages)
				? data?.data?.documentPackages.map((documentPackage) =>
						createOption(documentPackage),
				  )
				: [],
		[data?.data?.documentPackages],
	)
	return {
		documentPackages: documentPackages,
		isLoading: isLoading,
	}
}

export default DocumentPackagesQuery
