import { SystemFieldForm } from 'modules/system-fields'
import { useHistory } from 'react-router-dom'
import { PageHeader } from 'theme/layouts'
import AppLayout from 'theme/layouts/app-layout'

const SystemFieldsCreate = () => {
	const history = useHistory()

	return (
		<AppLayout
			meta={{
				title: 'Create System Field',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<PageHeader title='Create System Field' hasBackButton={true} />
					<div className='page-body'>
						<SystemFieldForm />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default SystemFieldsCreate
