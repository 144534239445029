import { ReactComponent as FolderIcon } from 'assets/svg/folder-icon.svg'
import clsx from 'clsx'

const DocumentList = ({ folders, formik }) => {
	if (folders?.length === 0) {
		return <p className='text-center'>No Folder found</p>
	}

	const selectFolder = (folderId) => {
		formik.setFieldValue('selectedFolder', folderId)
	}

	return (
		<div>
			{folders.map((item, idx) => {
				const folderId = Number(item.id)
				const isSelected = formik.values.selectedFolder === folderId
				const isDisabled = false // Update this condition if required
				return (
					<label
						key={idx}
						htmlFor={`folder-${folderId}`}
						className={clsx(
							'flex cursor-pointer items-center justify-between space-x-2 rounded-sm px-4 py-2 shadow-sm',
							isDisabled
								? 'cursor-not-allowed bg-gray-100 text-gray-400'
								: isSelected
								? 'border border-blue-500 bg-blue-100'
								: 'border border-gray-300 bg-white hover:bg-gray-100',
						)}>
						<div className='flex items-center gap-1'>
							<span>
								<FolderIcon height={15} />
							</span>
							<span className='my-1 ml-2 text-sm font-medium'>{item?.title}</span>
						</div>
						<input
							type='radio'
							id={`folder-${folderId}`}
							name='selectedFolder'
							value={folderId}
							checked={isSelected}
							onChange={() => selectFolder(folderId)}
							disabled={isDisabled}
						/>
					</label>
				)
			})}
		</div>
	)
}

export default DocumentList
