import classNames from 'classnames'
import { useMemo } from 'react'

const CheckboxField = ({ field, form, label, id, type, helperText, isRequiredField }) => {
	const { onChange, onBlur, name, value } = field

	const touched = useMemo(() => form?.touched[name], [form?.touched])
	const error = useMemo(() => form?.errors[name], [form?.errors])

	return (
		<div className='flex flex-col space-y-3'>
			<div className='flex items-start space-x-3'>
				<input
					id={id}
					type={type || 'checkbox'}
					onChange={onChange}
					onBlur={onBlur}
					name={name}
					value={value}
					checked={value}
					className={classNames('form-check-input m-0 mt-1', {
						'is-invalid': touched && error ? true : false,
					})}
				/>
				&nbsp;&nbsp;
				{label ? (
					<label htmlFor={id} className='m-0 flex cursor-pointer flex-col'>
						<span className='text-sm font-bold text-[#495057]'>
							{label}{' '}
							{isRequiredField ? <span className='text-red-500'>*</span> : undefined}
						</span>
						{helperText ? (
							<span className='text-xs font-normal text-gray-500'>
								({helperText})
							</span>
						) : undefined}
					</label>
				) : undefined}
			</div>
			{touched && error ? <div className='text-xs text-red-500'>{error}</div> : undefined}
		</div>
	)
}

export default CheckboxField
