import { createApi } from '@reduxjs/toolkit/query/react'
import apiEndpoints from 'helpers/apiEndpoints'

import axiosBaseQuery from 'store/axios-base-query'
import { transformReadymadeTemplateResponse } from '.'

const API_STAR_URL = '/starred-document'

export const readymadeTemplatesApi = createApi({
	reducerPath: 'ReadymadeTemplates',
	keepUnusedDataFor: 0,
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['ReadymadeTemplates'],
	endpoints: (builder) => ({
		getReadymadeTemplates: builder.query({
			query: (params) => ({
				url: `${apiEndpoints.documentPackage}/ready-made`,
				method: 'GET',
				params: params,
			}),
			providesTags: ['ReadymadeTemplates'],
			transformResponse: transformReadymadeTemplateResponse,
		}),
		updateFolder: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${apiEndpoints.documentPackage}/${id}`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['ReadymadeTemplates'],
		}),
		updateDocument: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${apiEndpoints.document}/${id}`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['ReadymadeTemplates'],
		}),
		deleteFolder: builder.mutation({
			query: (id) => ({
				url: `${apiEndpoints.documentPackage}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['ReadymadeTemplates'],
		}),
		deleteDocument: builder.mutation({
			query: (id) => ({
				url: `${apiEndpoints.document}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['ReadymadeTemplates'],
		}),
		markAsStar: builder.mutation({
			query: (payload) => ({
				url: API_STAR_URL,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['ReadymadeTemplates'],
		}),
		addShortcut: builder.mutation({
			query: (payload) => ({
				url: `${apiEndpoints.documentPackage}/batch-update`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['ReadymadeTemplates'],
		}),
	}),
})

export const {
	useLazyGetReadymadeTemplatesQuery,
	useUpdateFolderMutation,
	useUpdateDocumentMutation,
	useDeleteFolderMutation,
	useDeleteDocumentMutation,
	useMarkAsStarMutation,
	useAddShortcutMutation,
} = readymadeTemplatesApi
