import { Link } from 'react-router-dom'

import ToolTip from 'theme/ui/tool-tip'
import { TEAM_APP_ROUTES } from '../routes'

const TeamActions = ({ team }) => {
	return (
		<div className=''>
			<div className='float-right flex justify-end space-x-2'>
				<ToolTip targetId={`edit-role-${team?.id}`} tooltipText='View' placement='top' />
				<Link
					to={TEAM_APP_ROUTES.findOne(team?.id)}
					id={`edit-role-${team?.id}`}
					className='action-btn btn-primary-outline'>
					<i className='uil-eye' style={{ fontSize: 16 }} />
				</Link>
				{/* {!team?.subscription ? (
						<div id={`assing_sub_${team?.id}`}>
							<Link
								title='Assign Subscription'
								to={TEAM_APP_ROUTES.subscribe(team?.id)}
								className='action-btn btn-info-outline !py-1.5'>
								<i className='far fa-money-bill-alt' style={{ fontSize: 16 }} />
							</Link>
							<UncontrolledTooltip placement='top' target={`assing_sub_${team?.id}`}>
								Assign Subscription
							</UncontrolledTooltip>
						</div>
					) : undefined} */}
			</div>
		</div>
	)
}

export default TeamActions
