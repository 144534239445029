import Badge from 'theme/ui/badge'

const FoldersColumn = ({ data, type }) => {
	return (
		<div>
			{type === 'folder' ? (
				<span className='text-[14px]'>
					{data?.childPackages?.length > 0 ? data?.childPackages?.length : 0} Folders,{' '}
					{data?.documents?.length > 0 ? data?.documents?.length : 0} Files
				</span>
			) : (
				<>
					{data?.documentPackages?.length > 0 ? (
						<div className='flex flex-wrap items-center gap-1'>
							<Badge variant='primary-outline' rounded={false}>
								{data?.documentPackages[0]?.title}
							</Badge>
							{data?.documentPackages?.length > 1 ? (
								<Badge variant='primary-outline' rounded={false}>
									+{data?.documentPackages?.length}
								</Badge>
							) : undefined}
						</div>
					) : undefined}
				</>
			)}
		</div>
	)
}

export default FoldersColumn
