import { createColumnHelper } from '@tanstack/react-table'
import { Fragment, useState } from 'react'
import Badge from 'theme/ui/badge'

import DataTable from 'theme/ui/data-table'
import Actions from './actions'

const TeamMemberInvitationTable = ({ data, meta, isLoading, pagination, setPagination }) => {
	const [sorting, setSorting] = useState([])
	const [actionActiveId, setActionActiveId] = useState(null)
	const onMouseEnter = (id) => setActionActiveId(id)
	const onMouseLeave = () => setActionActiveId(null)

	const columnHelper = createColumnHelper()

	const columns = [
		columnHelper.accessor('index', {
			size: 20,
			header: () => <span>#</span>,
			cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		}),

		columnHelper.accessor((row) => row, {
			id: 'name',
			header: () => <span>Name</span>,
			cell: (info) => (
				<span className='font-bold'>
					{info.getValue()?.first_name + ' ' + info.getValue()?.last_name}
				</span>
			),
		}),

		columnHelper.accessor((row) => row.email, {
			id: 'email',
			size: 200,
			header: () => <span>Email</span>,
			cell: (info) => <span>{info.getValue()}</span>,
		}),

		columnHelper.accessor((row) => row?.group?.name, {
			id: 'role',
			size: 100,
			header: () => (
				<div className='!w-full text-center'>
					<span>Role</span>
				</div>
			),
			cell: (info) => (
				<div className='mx-auto flex !w-full justify-center'>
					<Badge>{info.getValue()}</Badge>
				</div>
			),
		}),

		columnHelper.accessor((row) => row, {
			id: 'created_at',
			header: () => (
				<div className='!w-full'>
					<span className='float-right'>Invited At</span>
				</div>
			),
			cell: (info) => (
				<Actions
					id={info.getValue()?.id}
					user={info.getValue()}
					created_at={info.getValue()?.created_at}
					isActionActive={actionActiveId === info?.row?.id}
				/>
			),
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={data}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			setSorting={setSorting}
			sorting={sorting}
			meta={meta}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		/>
	)
}

export default TeamMemberInvitationTable
