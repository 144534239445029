import apiEndpoints from 'helpers/apiEndpoints'
import { useGetQueryDataQuery } from 'modules/clients/client-apis'
import { useMemo } from 'react'

const useRelationViewQuery = (client, page = 1, limit = 10) => {
	if (!client?.id) {
		return { data: [], meta: null, isLoading: false }
	}

	const URL = `${apiEndpoints.clientSetting}/user/${client.id}?page=${page}&limit=${limit}`
	const { data, isLoading, refetch } = useGetQueryDataQuery(URL)
	const relationData = data?.data?.userSettings

	const relations = useMemo(
		() =>
			Array.isArray(relationData)
				? relationData.map((item) => ({
						id: item?.id,
						key: item?.user_key,
						value: item?.user_value,
				  }))
				: [],
		[relationData],
	)

	return {
		data: relations || [],
		meta: data?.data?.meta,
		isLoading,
		refetch,
	}
}

export default useRelationViewQuery
