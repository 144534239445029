import classNames from 'classnames'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, FormFeedback, Input, Row } from 'reactstrap'
import { Field, FieldArray, reduxForm } from 'redux-form'
import Swal from 'sweetalert2'

import { ButtonWithLoader, Pagination } from 'components/Common'
import { useDeleteClientSettingMutation } from 'modules/clients/client-apis'
import { getPageNumbers } from 'theme/ui/data-table'
import { showSuccessToast } from 'utils/commonFunctions'
import { required } from 'utils/formValidation'
import AddFieldPopup from './add-field-popup'
import AddressFormPopup from './address-form-popup'

const CustomFields = ({
	handleSubmit,
	loading,
	fields,
	client,
	meta,
	refetch,
	setPagination,
	setFieldFormLoading,
	saveFields,
}) => {
	return (
		<form onSubmit={(values) => handleSubmit(values)}>
			<FieldArray
				name='fields'
				client={client}
				component={ArrayField}
				initialValues={fields}
				meta={meta}
				refetch={refetch}
				setPagination={setPagination}
				setFieldFormLoading={setFieldFormLoading}
				saveFields={saveFields}
			/>
			<div className='mb-3 hidden p-4 py-3 pt-0 text-center'>
				<ButtonWithLoader type='submit' loading={loading}>
					Submit
				</ButtonWithLoader>
			</div>
		</form>
	)
}

const CustomFieldsForm = reduxForm({
	form: 'custom_fields',
	enableReinitialize: true,
})(CustomFields)

export default CustomFieldsForm

const validate = {
	key: required('Key'),
	value: required('Value'),
}

const ArrayField = ({
	fields,
	client,
	refetch,
	meta,
	setPagination,
	setFieldFormLoading,
	saveFields,
}) => {
	const clientProfile = useMemo(
		() => ({
			address1: client?.userProfile?.address_1,
			address2: client?.userProfile?.address_2,
			city: client?.userProfile?.city,
			unit: client?.userProfile?.unit,
			province: client?.userProfile?.province,
			zip_code: client?.userProfile?.zip_code,
			country: client?.userProfile?.country,
		}),
		[client],
	)
	const { custom_fields } = useSelector((state) => state.form)
	const initialValues = custom_fields?.values?.fields || []
	const [selected, setSelected] = useState([])

	useEffect(() => {
		if (fields.length === 0) {
			fields.push({})
		}
	}, [fields])
	const [deleteClientSetting] = useDeleteClientSettingMutation()

	const handleDelete = async (e, id, idx) => {
		e.preventDefault()
		if (id) {
			Swal.fire({
				title: 'Are you sure you want to Delete ?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes',
			}).then(async (result) => {
				if (result.isConfirmed) {
					const button = e.target
					button.disabled = true
					const payload = {
						ids: [id],
					}
					const response = await deleteClientSetting(payload)
					if (response?.data?.status === 200) {
						showSuccessToast(response?.data?.message)
						refetch()
					}
				}
			})
		} else {
			fields.remove(idx)
		}
	}
	const handleBulkDelete = async (ids) => {
		if (ids) {
			Swal.fire({
				title: 'Are you sure you want to Delete ?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes',
			}).then(async (result) => {
				if (result.isConfirmed) {
					const payload = {
						ids: ids,
					}
					const response = await deleteClientSetting(payload)
					if (response?.data?.status === 200) {
						setSelected([])
						showSuccessToast(response?.data?.message)
						refetch()
					} else {
						setSelected([])
					}
				}
			})
		}
	}

	const [isOpen, setIsOpen] = useState(false)

	const addField = (e) => {
		e.preventDefault()
		setIsOpen(true)
	}

	const onPageChange = (e, page) => {
		setFieldFormLoading(true)
		setPagination((prev) => ({ ...prev, pageIndex: page }))
		setFieldFormLoading(false)
	}

	const toggleSelectAll = () => {
		if (selected.length === fields.length) {
			setSelected([])
		} else {
			const allIds = initialValues?.map((field) => {
				return field?.id
			})
			setSelected(allIds)
		}
	}

	const toggleSelect = (id) => {
		if (selected.includes(id)) {
			setSelected(selected.filter((selectedId) => selectedId !== id))
		} else {
			setSelected([...selected, id])
		}
	}
	const { startIndex, endIndex } = useMemo(() => getPageNumbers(meta), [meta])
	return (
		<Fragment>
			<AddFieldPopup
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				saveFields={saveFields}
			/>
			<div className='mb-3 rounded-lg bg-white'>
				<div className='flex items-center justify-between bg-[#F2F5FF] p-4 py-3'>
					<h5 className='m-0 font-bold '>Client Information</h5>
					<div
						className='flex items-center space-x-3
					'>
						<AddressFormPopup client={client} />
					</div>
				</div>
				<Row className='m-0 border-b p-3'>
					<Col xs={6}>
						<span className='text-gray-500'>Address 1</span>
					</Col>
					<Col xs={6}>
						<strong>{clientProfile?.address1}</strong>
					</Col>
				</Row>
				<Row className='m-0 border-b p-3'>
					<Col xs={6}>
						<span className='text-gray-500'>Address 2</span>
					</Col>
					<Col xs={6}>
						<strong>{clientProfile?.address2}</strong>
					</Col>
				</Row>
				<Row className='m-0 border-b p-3'>
					<Col xs={6}>
						<span className='text-gray-500'>Unit</span>
					</Col>
					<Col xs={6}>
						<strong>{clientProfile?.unit || '-'}</strong>
					</Col>
				</Row>
				<Row className='m-0 border-b p-3'>
					<Col xs={6}>
						<span className='text-gray-500'>City</span>
					</Col>
					<Col xs={6}>
						<strong>{clientProfile?.city}</strong>
					</Col>
				</Row>
				<Row className='m-0 border-b p-3'>
					<Col xs={6}>
						<span className='text-gray-500'>Province</span>
					</Col>
					<Col xs={6}>
						<strong>{clientProfile?.province}</strong>
					</Col>
				</Row>
				<Row className='m-0 border-b p-3'>
					<Col xs={6}>
						<span className='text-gray-500'>Zip Code</span>
					</Col>
					<Col xs={6}>
						<strong>{clientProfile?.zip_code}</strong>
					</Col>
				</Row>
				<Row className='m-0 border-b p-3'>
					<Col xs={6}>
						<span className='text-gray-500'>Country</span>
					</Col>
					<Col xs={6}>
						<strong>{clientProfile?.country}</strong>
					</Col>
				</Row>
				<Row className='m-0 border-b p-3'>
					<Col xs={6}>
						<span className='text-gray-500'>SIN</span>
					</Col>
					<Col xs={6}>
						<strong>{client?.sin_number}</strong>
					</Col>
				</Row>
			</div>

			<div className='p-[0.75rem]'>
				<div className='flex justify-end gap-4 px-4'>
					{selected?.length > 0 && (
						<button
							type='button'
							className='btn btn-danger text-white'
							onClick={() => handleBulkDelete(selected)}>
							Delete
						</button>
					)}
					<button
						className='cursor-pointer text-main hover:underline'
						onClick={addField}
						type='button'>
						<i className='uil uil-plus' /> Add Field
					</button>
				</div>
				<table className='table-1 mb-0'>
					<thead>
						<tr>
							<th>
								<input
									type='checkbox'
									checked={selected.length === fields.length && fields.length > 0}
									onChange={toggleSelectAll}
								/>
							</th>
							<th>Key</th>
							<th>Value</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{fields.map((field, idx) => {
							const item = initialValues[idx] || {}
							const id = item?.id
							// let isFocused = idx === activeField
							return (
								<tr
									className='client_details_styling identification_document hover:!bg-white'
									key={id || idx}>
									<td>
										<input
											className='mt-2.5'
											type='checkbox'
											checked={selected.includes(id)}
											onChange={() => toggleSelect(id)}
										/>
									</td>
									<td className='!border-none' style={{ verticalAlign: 'top' }}>
										<Field
											type='hidden'
											name={`${field}.id`}
											component='input'
										/>
										<Field
											name={`${field}.key`}
											component={TextField}
											placeholder='Field Key'
											validate={validate.key}
											className={{ fontBold: false }}
										/>
									</td>
									<td className='!border-none' style={{ verticalAlign: 'top' }}>
										<Field
											name={`${field}.value`}
											placeholder='Field Value'
											component={TextField}
											validate={validate.value}
											className={{ fontBold: true }}
											// onFocus={(e) => onFocus(e, idx)}
											// onBlur={onBlur}
										/>
									</td>
									<td className='!border-none' style={{ verticalAlign: 'top' }}>
										<button
											type='button'
											className='btn btn-danger w-full text-white'
											onClick={(e) => handleDelete(e, id, idx)}>
											<i className='uil uil-trash' />
										</button>
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
				<div className='mt-4 flex items-center justify-between'>
					<div className='flex flex-row'>
						<span className='mx-3 '>
							Showing {startIndex} - {endIndex} of {meta?.totalRecords}
						</span>
						<div className='custom-select'>
							<select
								className='!w-14 rounded px-2 py-1 text-xs'
								value={meta?.limit}
								onChange={(e) => {
									setPagination((prev) => ({
										...prev,
										pageSize: Number(e.target.value),
										pageIndex: 1,
									}))
								}}>
								{[5, 10, 20, 40, 50, 100].map((pageSize) => (
									<option key={pageSize} value={pageSize}>
										{pageSize}
									</option>
								))}
							</select>
						</div>
					</div>
					<Pagination
						total={meta?.totalRecords}
						limit={meta?.limit}
						currentPage={meta?.currentPage}
						onPageChange={onPageChange}
					/>
				</div>
			</div>
		</Fragment>
	)
}

const TextField = ({ meta, className, input, ...rest }) => {
	const [val] = useState('')
	const { touched, invalid, error } = meta

	return (
		<Fragment>
			<div className='form-group'>
				<Input
					{...rest}
					{...input}
					value={val || input.value}
					invalid={touched && Boolean(invalid)}
					className={classNames('form-control', { ...className })}
				/>
				{touched && error && (
					<FormFeedback className='text-danger'>{touched && error}</FormFeedback>
				)}
			</div>
		</Fragment>
	)
}
