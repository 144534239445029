import { useEffect } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import PdfViewer from './pdf-viewer'

const DocumentPreview = ({ title, url, isOpen, toggle }) => {
	useEffect(() => {
		const body = document.getElementsByTagName('body')[0].style
		if (isOpen) {
			body.overflow = 'hidden'
		} else {
			body.overflow = 'auto'
		}

		return () => {
			body.overflow = 'auto'
			body.paddingRight = 0
		}
	}, [isOpen])

	return (
		<Modal isOpen={isOpen} toggle={toggle} size='lg' fullscreen='lg' backdrop='static'>
			<ModalHeader toggle={toggle}>{title || 'Document Preview'}</ModalHeader>
			<ModalBody className='p-0'>
				<PdfViewer src={url} />
			</ModalBody>
		</Modal>
	)
}

export default DocumentPreview
