import classNames from 'classnames'
import { Field } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import ReactDragListView from 'react-drag-listview'
import Select from 'react-select'

import { useLazyGetDocumentPackageDetailsQuery } from 'modules/documents/document-package-api'
import { createOption, createOptionLabel } from '.'
import DocumentPackagesQuery from '../document-package-dropdown/document-packages-query'
import DocumentBadge from './document-badge'
import DocumentsQuery from './documents-query'

const MergedDropdown = ({ formik }) => {
	const [search, setSearch] = useState('')

	const { documentPackages, isLoading: isLoadingPackages } = DocumentPackagesQuery(search)

	const selectedDocs = useMemo(
		() => (Array.isArray(formik.values.documents) ? formik.values.documents : []),
		[formik.values.documents],
	)
	const { documents, isLoading: isLoadingDocs } = DocumentsQuery(search, selectedDocs)

	const groupedOptions = [
		{
			label: 'Document Packages',
			options: documentPackages,
		},
		{
			label: 'Documents',
			options: documents,
		},
	]
	const [getDocumentPackageDetails] = useLazyGetDocumentPackageDetailsQuery()

	const fetchDocumentsByPackageId = async (packageId) => {
		if (!packageId) return []

		try {
			const response = await getDocumentPackageDetails(packageId).unwrap()
			const formattedFolders = response?.data?.documents.map((elem) => {
				return {
					...elem,
					minClients: elem?.min_clients,
				}
			})

			return formattedFolders
		} catch (error) {
			console.error('Error fetching document package details:', error)
			return []
		}
	}

	const onChange = async (fieldName, option) => {
		if (option.type === 'folder') {
			const packageDocuments = await fetchDocumentsByPackageId(option.id)
			const createdOption = packageDocuments.map((doc) => createOption(doc))
			const updatedDocs = [...selectedDocs, ...createdOption]
			formik.setFieldValue(fieldName, updatedDocs)
		} else {
			const updatedDocs = [...selectedDocs, option]
			formik.setFieldValue(fieldName, updatedDocs)
		}
	}
	const onRemove = (fieldName, index) => {
		if (index < 0 || index >= selectedDocs.length) {
			return false
		}
		const updatedDocs = selectedDocs.slice(0, index).concat(selectedDocs.slice(index + 1))
		formik.setFieldValue(fieldName, updatedDocs)
	}

	const onRemoveAll = (fieldName) => {
		formik.setFieldValue(fieldName, [])
	}

	useEffect(async () => {
		if (formik?.values?.document_packages?.length > 0) {
			const packageDocuments = await fetchDocumentsByPackageId(
				formik?.values?.document_packages[0]?.id,
			)
			const createdOption = packageDocuments.map((doc) => createOption(doc))
			const updatedDocs = [...selectedDocs, ...createdOption]
			formik.setFieldValue('documents', updatedDocs)
		}
	}, [formik?.values?.document_packages])

	const onDragEnd = (fromIndex, toIndex) => {
		const $documents = [...selectedDocs]
		const document = $documents.splice(fromIndex, 1)[0]
		$documents.splice(toIndex, 0, document)
		formik.setFieldValue('documents', $documents)
		// console.log({ fromIndex, toIndex })
	}

	const dragProps = {
		onDragEnd: onDragEnd,
		nodeSelector: '.document-item',
		handleSelector: '.drag-selector',
	}

	return (
		<Field name='documents'>
			{(fieldProps) => {
				const { field, meta } = fieldProps
				const isInvalid = meta?.touched && meta?.error
				return (
					<div className='flex-col space-y-[8px]'>
						<div className='flex items-center justify-between'>
							<label
								className={classNames(
									'mb-0 flex items-baseline justify-between space-x-2 text-[14px] font-bold text-[#495057]',
								)}>
								<div className='flex space-x-2'>
									<div>Documents</div>
									{selectedDocs.length > 0 ? (
										<span>({selectedDocs.length})</span>
									) : undefined}
								</div>
							</label>

							{selectedDocs.length > 0 ? (
								<span
									className='cursor-pointer text-sm text-red-500 hover:underline'
									onClick={() => onRemoveAll(field?.name)}>
									Clear All
								</span>
							) : undefined}
						</div>
						<Select
							value={null}
							options={groupedOptions}
							onInputChange={(value) => setSearch(value)}
							onChange={(option) => onChange(field?.name, option)}
							isSearchable={true}
							isLoading={isLoadingPackages || isLoadingDocs}
							classNamePrefix={
								isInvalid ? 'invalid-select-dropdown' : 'select-dropdown'
							}
							className='select-dropdown-container'
							placeholder='Search documents'
							getOptionLabel={(option) => createOptionLabel(option)}
							filterOption={({ data }, search) =>
								data?.title?.toLowerCase().includes(search.toLowerCase())
							}
							styles={{
								menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
								menu: (provided) => ({ ...provided, zIndex: 9999 }),
							}}
							menuPortalTarget={document.body}
							menuPosition='fixed'
						/>
						{isInvalid ? (
							<div className='error mt-[3px] text-[13px]'>{meta?.error}</div>
						) : undefined}
						<ReactDragListView {...dragProps}>
							<div className='mb-2 grid grid-cols-1 gap-1'>
								{selectedDocs.map((doc, idx) => (
									<DocumentBadge
										key={idx}
										title={doc?.title}
										onRemove={() => onRemove(field.name, idx)}
									/>
								))}
							</div>
						</ReactDragListView>
					</div>
				)
			}}
		</Field>
	)
}

export default MergedDropdown
