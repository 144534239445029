import AllFoldersListingContainer from './all-folders-listing-container'

export { default as SearchFoldersListingContainer } from './search-folders-listing-container/search-folders-listing-container'

export default AllFoldersListingContainer

export const getFormValues = (values) => {
	return {
		...values,
		search_type: values?.search_type?.value,
	}
}
