import { Field, FormikProvider, useFormik } from 'formik'
import { useAppRouter } from 'hooks'
import { useState } from 'react'
import { Button, InputField, TextareaField } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { getInitialValues, getPayload, validationSchema } from '.'
import { useCreateSubscriptionMutation, useUpdateSubscriptionMutation } from '../subscriptions-api'
import MarketingFeatureList from './marketing-feature-list'
import MetaDataList from './metaData-list'
import PriceData from './price-data'

const SubscriptionForm = ({ subscription }) => {
	const [createSubscription] = useCreateSubscriptionMutation()
	const [updateSubscription] = useUpdateSubscriptionMutation()
	const { goBack } = useAppRouter()
	const [selectedOption, setSelectedOption] = useState('Recurring')

	const formik = useFormik({
		initialValues: getInitialValues(subscription),
		enableReinitialize: true,
		validationSchema: validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			const payload = getPayload(values, selectedOption)
			if (!payload) return

			try {
				setSubmitting(true)
				const response = subscription
					? await updateSubscription({ payload, id: subscription?.id })
					: await createSubscription(payload)

				if (response?.data?.status === 200) {
					notification('success', response?.data?.message)
					goBack()
				}
			} catch (error) {
				notification('warn', error?.message)
			} finally {
				setSubmitting(false)
			}
		},
	})

	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				<div className='w-full px-4'>
					<div className='flex justify-center'>
						<div className='grid w-full max-w-lg grid-cols-1 gap-y-6 rounded bg-white px-6 py-8 shadow-md md:min-w-[380px] lg:max-w-[600px] lg:px-8 lg:py-10'>
							<div className='space-y-6'>
								<Field
									label='Name'
									placeholder='Enter name'
									name='name'
									type='text'
									component={InputField}
									isRequiredField
								/>

								<Field
									label='Subscription Description'
									placeholder='Enter subscription description'
									name='description'
									component={TextareaField}
								/>

								<Field
									label='Statement Descriptor'
									placeholder='Enter statement descriptor'
									name='statement_descriptor'
									type='text'
									component={InputField}
									helperText='Overrides default descriptors. Only used for subscription payments. Pick something your customers will recognize on their bank statement.'
								/>

								<MarketingFeatureList formik={formik} />
								<MetaDataList formik={formik} />
								<PriceData
									selectedOption={selectedOption}
									formik={formik}
									setSelectedOption={setSelectedOption}
								/>
							</div>

							<div className='mt-6 flex justify-end'>
								<Button
									type='submit'
									isLoading={formik.isSubmitting}
									isLoadingText='Saving...'>
									Save
								</Button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</FormikProvider>
	)
}

export default SubscriptionForm
