import { Link } from 'react-router-dom'

import { PAYMENTS_APP_ROUTES } from 'modules/payments/routes'
import ToolTip from 'theme/ui/tool-tip'

const PendingSubActions = ({ payment }) => {
	const $payment = payment?.payments[0]

	return (
		<div className=''>
			<div className='float-right flex justify-end space-x-2'>
				<ToolTip
					targetId={`edit-payment-${payment?.id}`}
					tooltipText='Edit'
					placement='top'
				/>
				<Link
					to={PAYMENTS_APP_ROUTES.edit($payment?.id)}
					id={`edit-payment-${payment?.id}`}
					className='action-btn btn-info-outline'>
					<i className='uil uil-edit' style={{ fontSize: 16 }} />
					<span>Edit</span>
				</Link>
			</div>
		</div>
	)
}

export default PendingSubActions
