import SubscriptionListingContainer from './subscription-listing-container'

export const getDuration = (druation) => {
	if (druation === 'month') {
		return '/ month'
	}

	if (druation === 'year') {
		return '/ year'
	}

	if (druation === 'day') {
		return '/ day'
	}

	if (druation === 'week') {
		return '/ week'
	}
}

export default SubscriptionListingContainer
