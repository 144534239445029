import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Field, reduxForm } from 'redux-form'
import Spinner from 'theme/ui/spinner'
import ButtonWithLoader from '../../../components/Common/ButtonWithLoader'
import { CheckBox } from '../../../components/Forms/CheckBoxComponent'
import { TextField } from '../../../components/Forms/TextFieldComponent'
import { normalizeMaxLength, required } from '../../../utils/formValidation'
import MethodGroup from './MethodGroup'
import { capitalize } from './customHooks'

const validate = {
	name: required('Name'),
	description: required('Short description'),
}

const GroupsForm = ({
	loading,
	isFetching,
	handleSubmit,
	initialize,
	initialValues,
	groupId,
	newPermissionsArray,
}) => {
	useEffect(() => {
		if (initialValues) {
			initialize({
				name: initialValues.name,
				description: initialValues.description,
				is_active: initialValues.is_active,
				accesses: initialValues.accesses,
			})
		}
	}, [initialValues, initialize])

	return (
		<div className='rounded-lg bg-gray-50 p-3 shadow-md'>
			{isFetching ? (
				<div className='flex items-center justify-center'>
					<Spinner />
				</div>
			) : (
				<form onSubmit={handleSubmit} className='space-y-6'>
					<div className='space-y-6 rounded-lg bg-white p-6 shadow-md'>
						<div className='border-b pb-2 text-lg font-semibold text-gray-700'>
							Role Information
						</div>

						<div className='grid grid-cols-1 gap-6 sm:grid-cols-2'>
							<Field
								component={TextField}
								name='name'
								label='Role Name *'
								validate={validate.name}
								disabled={!!groupId}
								className='form-input border-gray-300 focus:border-blue-500 focus:ring-blue-500'
							/>

							<Field
								component={TextField}
								name='description'
								label='Short Description *'
								validate={validate.description}
								normalize={normalizeMaxLength(30)}
								className='form-input border-gray-300 focus:border-blue-500 focus:ring-blue-500'
							/>
						</div>

						<div className='flex items-center space-x-4 border-t pt-4'>
							<Field
								type='checkbox'
								component={CheckBox}
								name='is_active'
								label='Is Active'
								id='is_active'
								className='form-checkbox rounded border-gray-300 text-blue-500 focus:ring-blue-500'
							/>
						</div>
					</div>
					<div className='space-y-4 rounded-lg bg-white p-6 shadow-md'>
						<h3 className='border-b pb-2 text-lg font-semibold text-gray-800'>
							Permissions
						</h3>

						<div className='overflow-x-auto rounded-lg border border-gray-200'>
							<table className='min-w-full bg-white'>
								<thead className='bg-gray-100 text-gray-700'>
									<tr>
										<th className='px-2 py-3 text-left text-sm font-medium'>
											Module
										</th>
										<th
											className='px-4 py-3 text-center text-sm font-medium'
											style={{ minWidth: '130px' }}>
											Select All
										</th>
										<th
											className='px-4 py-3 text-center text-sm font-medium'
											style={{ minWidth: '120px' }}>
											Actions
										</th>
									</tr>
								</thead>
								<tbody className='divide-y divide-gray-200 bg-white'>
									{newPermissionsArray?.map((n) => (
										<tr key={n.name}>
											<td
												className='!border !border-gray-200 px-2 py-2'
												style={{ minWidth: '250px' }}>
												<span className='font-semibold text-gray-800'>
													{capitalize(n.label)}
												</span>
											</td>
											<Field
												name='accesses'
												label={n.name}
												component={MethodGroup}
												options={n.method}
											/>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>

					<div className='flex justify-end'>
						<ButtonWithLoader type='submit' loading={loading} className='btn-primary'>
							Save
						</ButtonWithLoader>
					</div>
				</form>
			)}
		</div>
	)
}

GroupsForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
}

const ReduxGroupsForm = reduxForm({ form: 'groups', enableReinitialize: true })(GroupsForm)

export default ReduxGroupsForm
