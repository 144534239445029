/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types'
import React, { Component, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import './assets/scss/theme.scss'

import Gleap from 'gleap'
import ClientOnBoardingLayout from './components/ClientOnBoardingLayout'
import NonAuthLayout from './components/NonAuthLayout'
import { authRoutes, clientOnBoardingRoutes, publicRoutes, userRoutes } from './routes/allRoutes'
import Authmiddleware from './routes/middleware/Authmiddleware'

const App = () => {

	const defaultGroup = useSelector((state) => state.Permissions)

	useEffect(() => {
		if (
			(defaultGroup?.defaultGroup?.group?.slug == 'owner' ||
				defaultGroup?.defaultGroup?.group?.slug == 'advisor') &&
			process.env.REACT_APP_GLEAP_KEY
		) {
			Gleap.initialize(process.env.REACT_APP_GLEAP_KEY)
		}
	}, [defaultGroup?.defaultGroup?.group?.slug])
	
	return (
		<React.Fragment>
			<Router>
				<Switch>
					{publicRoutes.map((route, idx) => (
						<Route key={idx} {...route} render={(props) => <Component {...props} />} />
					))}

					{authRoutes.map((route, idx) => (
						<Authmiddleware
							path={route.path}
							layout={NonAuthLayout}
							component={route.component}
							key={idx}
							isAuthProtected={false}
						/>
					))}

					{clientOnBoardingRoutes.map((route, idx) => (
						<Authmiddleware
							path={route.path}
							layout={ClientOnBoardingLayout}
							component={route.component}
							key={idx}
							isAuthProtected={true}
							exact
						/>
					))}

					{userRoutes.map((route, idx) => (
						<Authmiddleware
							path={route.path}
							component={route.component}
							key={idx}
							isAuthProtected={true}
							exact
						/>
					))}
				</Switch>
			</Router>
		</React.Fragment>
	)
}

App.propTypes = {
	layout: PropTypes.any,
}

const mapStateToProps = (state) => {
	return {
		layout: state.Layout,
	}
}

export default connect(mapStateToProps, null)(App)
