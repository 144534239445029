import { useReactTable } from '@tanstack/react-table'
import { Fragment, useEffect } from 'react'

import { DataTablePagination, useSetDataTable } from 'theme/ui/data-table'
import { WorkflowOutstandingsSkeleton } from 'theme/ui/skeletons'
import TableRowBody from './table-row-body'

const TrashListingTable = ({ meta, isLoading, pagination, data, setPagination }) => {
	const table = useReactTable({
		data: data,
		manualPagination: true,
		pageCount: meta?.totalPages ?? -1,
		state: { pagination },
		onPaginationChange: setPagination,
	})
	const setTable = useSetDataTable()

	useEffect(() => {
		setTable(table)
		return () => {
			setTable(null)
		}
	}, [table])

	return (
		<div>
			<div className='flex w-full flex-col'>
				{isLoading ? (
					<WorkflowOutstandingsSkeleton pageSize={table.getState().pagination.pageSize} />
				) : (
					<Fragment>
						{data?.length > 0 ? (
							<TableRowBody data={data} />
						) : (
							<p className='text-center'>Trash is empty</p>
						)}
					</Fragment>
				)}
			</div>
			{pagination && meta ? <DataTablePagination table={table} meta={meta} /> : undefined}
		</div>
	)
}

export default TrashListingTable
