import { Fragment, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useLazyGetOneDocumentTemplateQuery } from 'modules/documents/document-api'
import DocumentVersionDetailsContainer from 'modules/documents/document-versions/document-version-details'
import Pages404 from 'pages/StaticPages/Page404'
import { AppLayout, PageHeader } from 'theme/layouts'
import Spinner from 'theme/ui/spinner'

const ViewDocumentTemplate = () => {
	const { id: ID } = useParams()
	const [fetchDocumentTemplate, { data, isUninitialized, isLoading }] =
		useLazyGetOneDocumentTemplateQuery()
	useEffect(() => {
		if (ID) {
			fetchDocumentTemplate(ID)
		}
	}, [ID])

	const document = data?.document

	return (
		<AppLayout
			meta={{
				title: 'Edit Document Template',
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<PageHeader
						title='Document Details'
						description='When assigning documents/packages here, do not ignore to fill in all the required fields completely and follow the document/package adding rules.'
						hasBackButton
					/>
					<div className='page-body'>
						{isUninitialized || isLoading ? (
							<Spinner />
						) : (
							<Fragment>
								{document ? (
									<DocumentVersionDetailsContainer document={document} />
								) : (
									<Pages404 />
								)}
							</Fragment>
						)}
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default ViewDocumentTemplate
