import { createColumnHelper } from '@tanstack/react-table'
import { useState } from 'react'

import { ReactComponent as DocumentIcon } from 'assets/svg/document-icon.svg'
import DataTable from 'theme/ui/data-table'
import ToolTip from 'theme/ui/tool-tip'
import { formatDate } from 'utilities/helpers'
import Actions from './actions'
import FoldersColumn from './folders-column'

const DocumentsTable = ({ pagination, setPagination, data, meta, isLoading }) => {
	const [sorting, setSorting] = useState([])
	const [actionActiveId, setActionActiveId] = useState(null)
	const onMouseEnter = (id) => setActionActiveId(id)
	const onMouseLeave = () => setActionActiveId(null)
	const columnHelper = createColumnHelper()

	const columns = [
		columnHelper.accessor((row) => row, {
			id: 'title',
			size: 220,
			header: () => <span>Title</span>,
			cell: (info) => {
				const elementId = `title-${info.getValue().id}`
				return (
					<div className='flex items-center'>
						<span>
							<DocumentIcon height={20} />
						</span>
						<span id={elementId} className='my-1 ml-2 line-clamp-1 text-sm font-medium'>
							{info.getValue()?.title}
						</span>
						<ToolTip targetId={elementId} tooltipText={info.getValue().title} />
					</div>
				)
			},
		}),

		// columnHelper.accessor((row) => row.type, {
		// 	id: 'type',
		// 	size: 100,
		// 	header: () => <span>Type</span>,
		// 	cell: (info) => (
		// 		<Badge variant='primary' rounded={false}>
		// 			{info.getValue()}
		// 		</Badge>
		// 	),
		// }),

		columnHelper.accessor((row) => row.folders, {
			id: 'folders',
			size: 170,
			header: () => <span style={{minWidth: "60px"}}>Folder</span>,
			cell: (info) => <FoldersColumn key={info?.row?.id} folders={info.getValue()} />,
		}),

		columnHelper.accessor((row) => row.last_edit, {
			id: 'last_edit',
			size: 160,
			header: () => <span>Last Edited</span>,
			cell: (info) => <span className='text-[13px]'>{formatDate(info.getValue())}</span>,
		}),

		columnHelper.accessor((row) => row, {
			id: 'actions',
			header: () => <span className='float-end'>Actions</span>,
			cell: (info) => (
				<Actions
					isActionActive={actionActiveId === info?.row?.original?.uuid}
					document={info.getValue()}
				/>
			),
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={data}
			isLoading={isLoading}
			setSorting={setSorting}
			pagination={pagination}
			setPagination={setPagination}
			sorting={sorting}
			meta={meta}
			variant='secondary'
			isOverflow={false}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		/>
	)
}

export default DocumentsTable
