import { createApi } from '@reduxjs/toolkit/query/react'
import apiEndpoints from 'helpers/apiEndpoints'

import axiosBaseQuery from 'store/axios-base-query'

export const clientOnboardingApi = createApi({
	reducerPath: 'ClientOnboardings',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['ClientOnboarding'],
	endpoints: (builder) => ({
		sendEmailOtp: builder.mutation({
			query: (payload) => ({
				url: apiEndpoints.userOnBoard.email.sendOTP,
				method: 'POST',
				data: payload,
			}),
		}),
		enrollClient: builder.mutation({
			query: (payload) => ({
				url: '/users/enroll-client',
				method: 'POST',
				data: payload,
			}),
		}),
		getUserInfo: builder.query({
			keepUnusedDataFor: 0,
			query: (email) => ({
				url: `/users/email/${email}`,
				method: 'GET',
			}),
		}),
	}),
})

export const { useSendEmailOtpMutation, useEnrollClientMutation, useLazyGetUserInfoQuery } =
	clientOnboardingApi
