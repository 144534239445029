import { Field } from 'formik'
import { useMemo } from 'react'

import { SelectField } from 'theme/ui/forms'
import { viewByOptions } from '.'

const ViewByDropdown = () => {
	const options = useMemo(() => viewByOptions, [])

	return (
		<Field
			type='select'
			name='view_by'
			component={SelectField}
			placeholder='View By'
			options={options}
		/>
	)
}

export default ViewByDropdown
