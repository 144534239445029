import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import store from '../store'

export const showToast = (message) => {
	toast(message, { className: 'custom-toast-class' })
}

export const showSuccessToast = (message, options) => {
	toast(message, { className: 'custom-success-toast-class', type: 'success', ...options })
}

export const showErrorToast = (message, options) => {
	toast(message, { className: 'custom-error-toast-class', type: 'error', ...options })
}

export const isConfirmed = async (title, icon) => {
	const result = await Swal.fire({
		title: title,
		icon: icon,
		showCancelButton: true,
		confirmButtonText: 'Yes',
	})
	if (result.isConfirmed) {
		return true
	} else {
		return false
	}
}

export const useQuery = (search) => {
	const urlSearchParams = new URLSearchParams(search)
	return Object.fromEntries(urlSearchParams.entries())
}

export const getQuery = (search) => {
	const urlSearchParams = new URLSearchParams(search)
	return Object.fromEntries(urlSearchParams.entries())
}

export const fromQueryToObject = (search) => {
	const urlSearchParams = new URLSearchParams(search)
	return Object.fromEntries(urlSearchParams.entries())
}

export const toQueryString = (obj) => {
	return new URLSearchParams(obj).toString()
}

// check if the object is present in array by id or not
export const isPresentInArray = (array, id) => {
	return array.some((item) => item.id === id)
}

// add object to array if not present
export const addToArray = (array, object) => {
	if (!isPresentInArray(array, object.id)) {
		array.push(object)
	}
}

// remove object from array if present
export const removeFromArray = (array, object) => {
	if (isPresentInArray(array, object.id)) {
		array = array.filter((item) => item.id !== object.id)
	}
}

// create a function to check the object is present in array or not
export const isPresentInArrayByField = (array, field, id) => {
	return array.some((item) => item[field] === id)
}

// create a function to convert a string to snake case
export const toSnakeCase = (str) => {
	return (
		str &&
		str
			.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
			.map((s) => s.toLowerCase())
			.join('_')
	)
}

// create a function to convert multidimensional array to single array
export const flattenArray = (array) => {
	return [].concat(...array)
}

// create a function to convert single array to multidimensional array
export const unflattenArray = (array) => {
	const newArray = []
	for (let i = 0; i < array.length; i++) {
		newArray.push([array[i]])
	}
	return newArray
}

// create a function to split array into chunks
export const arrayChunks = (array, chunkSize) => {
	const chunks = []
	let i = 0
	const n = array.length
	while (i < n) {
		chunks.push(array.slice(i, (i += chunkSize)))
	}
	return chunks
}

// create a function to find the object by a field in array
export const findObjectByField = (array, field, value) => {
	return array.find((item) => item[field] === value)
}

// create function to convert string into camel case
export const toCamelCase = (str) => {
	return str
		.replace(/\s(.)/g, function ($1) {
			return $1.toUpperCase()
		})
		.replace(/\s/g, '')
}

export function getFormValues(formName) {
	const { form } = store.getState()
	const step1Form = form && form[formName] ? form[formName] : {}
	return step1Form && step1Form.values ? step1Form.values : {}
}

export const findValueByKey = (array, key) => {
	const newArray = array.filter((item) => item.key !== null)
	return newArray.find((item) => {
		return item[key]
	})
}

export const formatBytes = (bytes, decimals = 2) => {
	if (bytes === 0) return '0 Bytes'
	const k = 1024
	const dm = decimals < 0 ? 0 : decimals
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
	const i = Math.floor(Math.log(bytes) / Math.log(k))
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

// create a funtion to check at least one value should be present in array object
export const atLeastOneValuePresent = (array) => {
	for (let i = 0; i < array.length; i++) {
		let value = array[i]
		if (value && Object.entries(value).length > 0) {
			return true
		}
	}
	return false
}

export const getCookie = (cname) => {
	let name = cname + '='
	let decodedCookie = decodeURIComponent(document.cookie)
	let ca = decodedCookie.split(';')
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i]
		while (c.charAt(0) === ' ') {
			c = c.substring(1)
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length)
		}
	}
	return ''
}

export function randomString(length = 9) {
	var result = ''
	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
	var charactersLength = characters.length
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength))
	}
	return result
}

export const toJson = (string) => {
	try {
		return JSON.parse(string)
	} catch (_) {
		return []
	}
}

export const toArray = (obj) => {
	if (typeof obj === 'object') {
		const array = Object.entries(obj)
		console.log('array', array)
		return array
	} else {
		return obj
	}
}
