import React from "react";
import ReactApexChart from 'react-apexcharts'
import CountUp from 'react-countup';
import {
	Card,
	CardBody,
	CardTitle,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
} from 'reactstrap'

const SalesAnalyticsChart = () => {

    const series = [
		{
			name: 'Tablets',
			type: 'line',
			data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
		},
	]

	const options = {
		chart: {
			stacked: false,
			toolbar: { show: false },
		},
		stroke: {
			width: 3,
			curve: 'smooth',
		},
		colors: ['#f1b44c'],
		fill: {
			opacity: 1,
			gradient: {
				inverseColors: false,
				shade: 'light',
				type: 'vertical',
				opacityFrom: 0.85,
				opacityTo: 0.55,
				stops: [0, 100, 100, 100],
			},
		},
		labels: [
			'2023-11-01',
			'2023-12-01',
			'2024-01-01',
			'2024-02-01',
			'2024-03-01',
			'2024-04-01',
			'2024-05-01',
			'2024-06-01',
			'2024-07-01',
			'2024-08-01',
			'2024-09-01',
			'2024-10-01',
		],
		markers: { size: 0 },
		xaxis: { type: 'datetime' },
		yaxis: { title: { text: 'Values' } },
		tooltip: {
			shared: true,
			intersect: false,
			y: {
				formatter: function (y) {
					return typeof y !== 'undefined' ? `${y.toFixed(0)} values` : y
				},
			},
		},
		grid: { borderColor: '#f1f1f1' },
	}

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<div className='float-end'>
						<UncontrolledDropdown>
							<DropdownToggle
								tag='a'
								className='text-reset !border !border-gray-300 px-3'
								id='dropdownMenuButton5'
								caret
								href='#'>
								<span className='text-muted'>
									1 Year<i className='mdi mdi-chevron-down ms-1'></i>
								</span>
							</DropdownToggle>
							<DropdownMenu className='dropdown-menu-end'>
								<DropdownItem href='#'>1 Month</DropdownItem>
								<DropdownItem href='#'>1 Year</DropdownItem>
								<DropdownItem href='#'>1 Day</DropdownItem>
							</DropdownMenu>
						</UncontrolledDropdown>
					</div>
					<CardTitle className='h4 mb-4'>Performance</CardTitle>
					<div className='mt-1'>
						<ul className='list-inline main-chart mb-0'>
							<li className='list-inline-item chart-border-left me-0 border-0'>
								<h3 className=''>
									<span className='text-muted d-inline-block font-size-15 ms-3'>
										Return :
									</span>
									<span className='font-size-15 mx-2'>
										<CountUp end={45032} separator=',' prefix='$' />
									</span>
									|
									<span className='font-size-15 ms-2'>
										<CountUp end={5.59} decimals={2} suffix='%' />
									</span>
								</h3>
							</li>{' '}
						</ul>
					</div>

					<div className='mt-3'>
						<ReactApexChart
							options={options}
							series={series}
							height={339}
							type='line'
							className='apex-charts'
						/>
					</div>
				</CardBody>
			</Card>
		</React.Fragment>
	)
};

export default SalesAnalyticsChart;