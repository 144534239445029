const PdfViewer = ({ src }) => {
	return (
		<embed
			src={src}
			type='application/pdf'
			frameBorder={0}
			style={{
				width: '100%',
				height: '80vh',
			}}
		/>
	)
}

export default PdfViewer
