import { MDBDataTableV5 } from 'mdbreact'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import ClientAction from './clients-action'

const ClientsTab = ({ clients = [], team = {} }) => {
	const columns = useMemo(
		() => [
			{ label: '#', field: 'id', attributes: { style: { width: '50px' } } },
			{ label: 'Name', field: 'name', attributes: { style: { minWidth: '80px' } } },
			{ label: 'Email', field: 'email', attributes: { style: { minWidth: '100px' } } },
			{ label: 'Phone Number', field: 'phone', attributes: { style: { minWidth: '150px' } } },
			{ label: 'Status', field: 'status', attributes: { style: { minWidth: '70px' } } },
			{ label: 'Action', field: 'actions', attributes: { style: { minWidth: '50px' } } },
		],
		[],
	)

	const rows = useMemo(
		() =>
			clients
				.filter(
					(client) =>
						Array.isArray(client.groups) &&
						client.groups.length > 0 &&
						client.groups[0]?.group?.name !== 'Owner',
				)
				.map((user, idx) => ({
					id: idx + 1,
					name: user?.fullName || 'N/A',
					email: user?.email || 'N/A',
					phone: user?.phone_number || 'N/A',
					status: user?.status ? 'Active' : 'Inactive',
					actions: !user?.hasTeam ? <ClientAction team={team} user={user} /> : '—',
				})),
		[clients, team],
	)

	return (
		<div className='rounded-md !border bg-white p-4 shadow-sm'>
			<h5 className='mb-6 font-semibold text-gray-700'>Clients</h5>
			<MDBDataTableV5
				responsive
				bordered
				searchTop
				sortable={false}
				searchBottom={false}
				data={{ columns, rows }}
			/>
		</div>
	)
}

ClientsTab.propTypes = {
	clients: PropTypes.arrayOf(PropTypes.object),
	team: PropTypes.object,
}

export default ClientsTab
