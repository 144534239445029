import { useMemo } from 'react'
import OTPInput from 'react-otp-input'

const OtpField = ({ form, label, field, otpLength = 4 }) => {
	const { name, value } = field
	const touched = useMemo(() => form?.touched[name], [form?.touched])
	const error = useMemo(() => form?.errors[name], [form?.errors])

	return (
		<div className='form-group w-fit otp_full_width'>
			{label ? (
				<label className='flex items-baseline justify-between text-[14px] font-bold text-[#495057]'>
					{label}
				</label>
			) : undefined}
			<OTPInput
				inputType='tel'
				name={name}
				value={value}
				onChange={(value) => form.setFieldValue(field.name, value)}
				numInputs={otpLength}
				renderSeparator={<span className='w-3'></span>}
				renderInput={(props) => (
					<input
						{...props}
						className='border-1 rounded-md border-gray-200 px-2 text-center otp-field-adjustment'
						style={{
							width: 38,
							height: 38,
						}}
						required
					/>
				)}
			/>
			{touched && error ? <div className='invalid-feedback text-xs'>{error}</div> : undefined}
		</div>
	)
}

export default OtpField
