const CustomCheckBox = (props) => {
	const { label, input, id } = props

	return (
		<div className="alignment_full">
			<input
				{...input}
				type='checkbox'
				className='btn-check'
				id={id}
				value={input.value}
				onChange={(value) => input.onChange(value)}
				autoComplete='off'
			/>
			<label className='flex btn btn-secondary btn-sm m-0 min-w-max alignment_full' htmlFor={id}>
				<div className="flex flex-row justify-start">
					{input.checked ? (
						<>
							<i className='uil-times' />
							&nbsp;
						</>
					) : undefined}

					<span className="flex justify-left">{label}</span>
				</div>
			</label>
		</div>
	)
}

export default CustomCheckBox
