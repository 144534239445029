import classNames from 'classnames'
import { useMemo } from 'react'

import { useTabs } from 'contexts/tabs-context'
import { Button } from 'theme/ui/forms'
import { getTabs } from '../tabs-header'

const TabsFooter = ({ formik }) => {
	const { activeTab, setActiveTab } = useTabs()
	const isSignatureRequired = useMemo(() => formik?.values?.signature_required, [formik?.values])
	const totalTabs = useMemo(() => getTabs(isSignatureRequired).length, [isSignatureRequired])

	const onPrevious = () => {
		setActiveTab(activeTab - 1)
	}

	return (
		<div className='px-[75px] tabs_spacing'>
			<div
				className={classNames(
					'flex !border-t py-6',
					activeTab === 1 ? 'justify-end' : 'justify-between',
				)}>
				{activeTab > 1 ? (
					<Button type='button' onClick={onPrevious} variant='white'>
						Previous
					</Button>
				) : undefined}

				<Button
					type='submit'
					isLoading={formik.isSubmitting}
					disabled={!formik.isValid}
					isLoadingText='Saving...'>
					{activeTab === totalTabs ? 'Publish Now' : 'Next'}
				</Button>
			</div>
		</div>
	)
}

export default TabsFooter
