import { Field } from 'formik'
import { useState } from 'react'

import { CreateUserOptionLabel } from 'modules/shared/users-dropdown'
import { SelectField } from 'theme/ui/forms'
import ReviewersQuery from './reviewers-query'

const ReviewerDropdown = ({ formValues, advisor_reviewer }) => {
	const [search, setSearch] = useState('')
	const { advisors, isLoading } = ReviewersQuery(
		search,
		formValues,
		advisor_reviewer,
		'reviewers',
	)

	const filterOption = ({ data }, search) => {
		if (
			data.fullName.toLowerCase().includes(search.toLowerCase()) ||
			data.email.toLowerCase().includes(search.toLowerCase())
		) {
			return true
		} else {
			return false
		}
	}

	return (
		<Field
			label='Select Reviewers'
			type='select'
			name='reviewers'
			isMulti
			options={advisors}
			isLoading={isLoading}
			onInputChange={(value) => setSearch(value)}
			component={SelectField}
			getOptionLabel={(option) => <CreateUserOptionLabel user={option} />}
			filterOption={filterOption}
		/>
	)
}

export default ReviewerDropdown
