import { Link } from 'react-router-dom'
import { ANNOUNCEMENTS_APP_ROUTES } from './routes'

const Header = () => {
	return (
		<div className='flex w-full flex-col'>
			<div className='alignment mb-3 flex w-full justify-between gap-2'>
				<div className='max-w-[733px]'>
					<h4 className='page-title'>Announcements</h4>
				</div>
				<Link
					to={ANNOUNCEMENTS_APP_ROUTES.create()}
					className='h-fit min-w-[110px] max-w-fit rounded bg-main px-3 py-2 text-white'>
					Create new
				</Link>
			</div>
		</div>
	)
}

export default Header
