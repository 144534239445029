// import { SpinLoader } from 'components/Common'
import { SpinLoader } from 'components/Common'
import { useLazyGetNotificationTemplateQuery } from 'modules/notification-templates/notifications-templates-api'
import { NotificationsTemplatesForm } from 'modules/notification-templates/notifications-templates-form'
import Pages404 from 'pages/StaticPages/Page404'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const NotificationsTemplatesEdit = () => {
	const params = useParams()
	const [fetchNotificatonTemplate, { data, isFetching }] = useLazyGetNotificationTemplateQuery(
		params?.id,
	)
	useEffect(() => {
		if (params?.id) {
			fetchNotificatonTemplate(params?.id)
		}
	}, [params?.id])

	const notificationTemplate = useMemo(
		() => data?.notificationsTemplate,
		[data?.notificationsTemplate],
	)

	return (
		<AppLayout
			meta={{
				title: 'Notification Template Edit',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<PageHeader title={notificationTemplate?.name} hasBackButton={true} />
					<div className='page-body'>
						{isFetching ? (
							<SpinLoader />
						) : (
							<>
								{data?.notificationsTemplate ? (
									<NotificationsTemplatesForm
										notificationTemplate={notificationTemplate}
									/>
								) : (
									<Pages404 />
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default NotificationsTemplatesEdit
