import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive'; 
import { useAppRouter, useUser } from 'hooks';
import * as actions from 'store/file-manager/actions';

const Actions = ({ isActionActive, document }) => {
  const dispatch = useDispatch();
  const { navigate } = useAppRouter();
  const { isSuperAdmin } = useUser();


  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const attachInWorkflow = (data) => {
    const item = {
      title: data?.title,
      value: JSON.stringify({
        id: data?.id,
        minClients: data?.minClients,
      }),
    };

    dispatch(actions.setSelectedDocuments([item]));
    navigate(`/workflows/create?document=true`);
  };

  return (
    <div className="w-[120px]">
      {isMobile ? (
        <span
          className="text-sm font-medium text-main cursor-pointer"
          onClick={() => attachInWorkflow(document)}
        >
          <span>Use in workflow</span>
          <i className="uil-angle-right text-base"></i>
        </span>
      ) : (
        isActionActive &&
        !isSuperAdmin && (
          <span
            className="cursor-pointer text-sm font-medium text-main hover:text-secondary"
            onClick={() => attachInWorkflow(document)}
          >
            <span>Use in workflow</span>
            <i className="uil-angle-right text-base"></i>
          </span>
        )
      )}
    </div>
  );
};

export default Actions;
