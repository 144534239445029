import { Field, FormikProvider, useFormik } from 'formik'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useDebounce } from 'use-debounce'

import { useAppRouter } from 'hooks'
import { useBatchDeleteDocumentsMutation } from 'modules/documents/document-api'
import { getParsedRows } from 'modules/file-manager/my-documents-listing/filters'
import * as actions from 'store/file-manager/actions'
import { useDataTable } from 'theme/ui/data-table'
import DeleteConfirmAlert from 'theme/ui/delete-confirm-alert'
import { Button, SearchField } from 'theme/ui/forms'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import notification from 'utilities/notification'
import { toQueryString } from 'utils/commonFunctions'

import classNames from 'classnames'
import { DateRange } from 'theme/ui/filters'
import { getActiveFilters, getFormikFilterValues, getInitialValues } from './index'
import StatusDropdown from './status-dropdown'

const Filters = ({ setPagination, selectedRows, setRowSelection }) => {
	const history = useHistory()
	const location = useLocation()
	const parseQueryString = getParseQueryString(location?.search)
	const initialValues = getInitialValues(parseQueryString)
	const formik = useFormik({ initialValues: initialValues })
	const tableContext = useDataTable()
	const parsedRows = useMemo(() => getParsedRows(selectedRows), [selectedRows])
	const formValues = useMemo(() => formik?.values, [formik?.values])
	const [debounceValues] = useDebounce(formValues, 800)
	const dispatch = useDispatch()
	const { navigate } = useAppRouter()
	const [deleteDocuments, { isLoading: isDeleting }] = useBatchDeleteDocumentsMutation()
	const [isDeleteOpen, setIsDeleteOpen] = useState(false)

	const resetFilters = () => {
		return new Promise((resolve) => {
			const filterObj = { page: 1, limit: 10 }
			const queryString = toQueryString(filterObj)
			history.push(`${window.location.pathname}?${queryString}`)
			resolve()
		})
	}

	const onDeleteDocuments = async (documentIds) => {
		try {
			const payload = {
				documentIds: documentIds,
			}
			const response = await deleteDocuments(payload).unwrap()
			if (response?.status === 200) {
				setRowSelection({})
				setIsDeleteOpen(false)
				notification('success', 'Selected documents successfully deleted')
			}
		} catch (error) {
			setRowSelection({})
			notification('error', error?.message)
		}
	}

	const onResetFilters = async () => {
		resetFilters().then(() => {
			formik.handleReset()
		})
	}

	useEffect(() => {
		if (debounceValues) {
			const paginationParams = getPaginationParams(location?.search)
			const filterObj = {
				...getFormikFilterValues(debounceValues),
				page: 1,
				limit: paginationParams.limit,
			}
			const queryString = toQueryString(filterObj)
			history.push(`${window.location.pathname}?${queryString}`)
			if (tableContext) {
				tableContext.resetPageIndex()
			}
			setPagination({
				pageIndex: 0,
				pageSize: 10,
			})
		}
	}, [debounceValues])

	useEffect(() => {
		if (location?.search) {
			const paginationParams = getPaginationParams(location?.search)
			const currentPage = paginationParams?.page ? parseInt(paginationParams.page, 10) : 1
			const currentLimit = paginationParams?.limit ? parseInt(paginationParams.limit, 10) : 10
			if (currentPage > 1) {
				setPagination({
					pageIndex: currentPage - 1,
					pageSize: currentLimit,
				})
			}
		}
	}, [location?.search])

	const activeFilters = getActiveFilters(parseQueryString)

	const attachInWorkflow = (documents) => {
		const isAllPublished = documents?.every((elem) => {
			return elem?.status === 1
		})
		if (isAllPublished) {
			const items = documents.map((doc) => ({
				title: doc?.title,
				value: JSON.stringify({
					id: doc?.id,
					minClients: doc?.minClients,
				}),
			}))
			dispatch(actions.setSelectedDocuments(items))
			navigate(`/workflows/create?document=true`)
		} else {
			notification(
				'warn',
				'Some selected documents are drafts. Only published documents can be attached to a workflow. Please review and try again.',
			)
		}
	}

	return (
		<Fragment>
			{' '}
			<DeleteConfirmAlert
				isDeleting={isDeleting}
				isOpen={isDeleteOpen}
				onClose={() => setIsDeleteOpen(false)}
				onConfirm={() => onDeleteDocuments(parsedRows.map((doc) => doc?.id))}
				title={`Delete (${parsedRows?.length}) ${
					parsedRows?.length > 1 ? 'Documents' : 'Document'
				}?`}
				subtitle={`Are you sure you want to delete the selected ${
					parsedRows?.length > 1 ? 'Documents' : 'Document'
				} record?`}
			/>
			<FormikProvider value={formik}>
				<form
					className='mb-3'
					onSubmit={(e) => {
						e.preventDefault()
					}}>
					<div className='flex flex-col space-y-3'>
						<div
							className={classNames(
								'grid items-center justify-between ',
								parsedRows.length > 0
									? 'grid-cols-1 gap-y-2 md:grid-cols-3 md:gap-x-3 md:gap-y-0'
									: 'grid-cols-1',
							)}>
							<div className='col-span-2 flex w-full flex-wrap items-center gap-1'>
								<Field
									className='form-control'
									type='search'
									name='search'
									component={SearchField}
									placeholder='Search documents...'
								/>
								<StatusDropdown values={formik?.values?.status} name='status' />

								<DateRange name='date_range' />

								{activeFilters.length > 0 ? (
									<Button size='sm' variant='ghost' onClick={onResetFilters}>
										Clear filters
									</Button>
								) : undefined}
							</div>

							<div>
								{parsedRows?.length > 0 ? (
									<div className='flex w-full flex-wrap items-center gap-2 lg:float-right'>
										<div className='flex items-center divide-x divide-solid divide-gray-200'>
											<span className='min-w-fit pr-3 text-sm text-gray-500'>
												{parsedRows.length} selected
											</span>
											<div className='pl-3'>
												<span
													className='h-full cursor-pointer !border-b border-dashed border-gray-400 !px-1 font-semibold hover:border-solid'
													onClick={() => setRowSelection({})}>
													Deselect
												</span>
											</div>
										</div>
										<div className='flex items-center space-x-3'>
											<div className='bg-white'>
												<Button
													type='button'
													size='md'
													variant='primary-outline'
													onClick={() => attachInWorkflow(parsedRows)}>
													<span>Attach in Workflow</span>
												</Button>
											</div>
											<div className='bg-white'>
												<Button
													type='button'
													size='md'
													onClick={() => setIsDeleteOpen(true)}
													variant='danger-outline'>
													<span>
														Delete {parsedRows?.length > 1 && 'All'}
													</span>
												</Button>
											</div>
										</div>
									</div>
								) : undefined}
							</div>
						</div>
					</div>
				</form>
			</FormikProvider>
		</Fragment>
	)
}

export default Filters
