import classNames from 'classnames'
import { useState } from 'react'
import Badge from 'theme/ui/badge'
import { Button } from 'theme/ui/forms'
import { formatPrice } from 'utilities/helpers'

const ProductCard = ({ activePlan, isActive, onSubscribe }) => {
	const [submitting, setSubmitting] = useState(false)
	return (
		<div
			className={classNames(
				'flex flex-col rounded-lg !border  bg-white p-6',
				isActive ? 'border-green-400' : 'border-gray-200',
			)}>
			<div className='flex items-center justify-between'>
				<h3 className='mb-1 text-xl font-semibold text-gray-800'>
					{activePlan?.productName}
				</h3>
				{isActive ? <Badge variant='success'>Active</Badge> : undefined}
			</div>
			<div className='md:min-h-[96px]'>
				<p className='text-sm text-gray-500'>{activePlan?.productDescription}</p>
			</div>
			<div className='my-3'>
				<span className='text-xl font-extrabold text-gray-900'>
					{formatPrice(activePlan?.price, activePlan?.currency)}
				</span>
				<span className='text-base text-gray-600'>/{activePlan?.recurring}</span>
			</div>
			<Button
				type='button'
				isLoading={submitting}
				onClick={async () => {
					onSubscribe(activePlan?.priceId, { setSubmitting })
				}}
				block={true}
				className='font-medium'
				disabled={isActive}>
				Subscribe
			</Button>
			{/* <ul className='ml-0 mt-6 space-y-4 pl-0'>
									<li className='flex items-center'>
										<svg
											className='mr-3 h-5 w-5 text-green-500'
											xmlns='http://www.w3.org/2000/svg'
											fill='none'
											viewBox='0 0 24 24'
											stroke='currentColor'>
											<path
												strokeLinecap='round'
												strokeLinejoin='round'
												strokeWidth='2'
												d='M5 13l4 4L19 7'
											/>
										</svg>
										5 Projects
									</li>
									<li className='flex items-center'>
										<svg
											className='mr-3 h-5 w-5 text-green-500'
											xmlns='http://www.w3.org/2000/svg'
											fill='none'
											viewBox='0 0 24 24'
											stroke='currentColor'>
											<path
												strokeLinecap='round'
												strokeLinejoin='round'
												strokeWidth='2'
												d='M5 13l4 4L19 7'
											/>
										</svg>
										10 GB Storage
									</li>
								</ul> */}
		</div>
	)
}

export default ProductCard
