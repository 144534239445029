import { Fragment, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import WorkflowsLinksContainer from '../workflow-template/workflows-links-list/workflows-links-container'

const Heading = ({ open, title, updatesCount, links, id }) => {
	const [isOpen, setIsOpen] = useState(false)
	const toggle = (event) => {
		event.stopPropagation()
		setIsOpen(!isOpen)
	}

	return (
		<Fragment>
			<Modal isOpen={isOpen} toggle={toggle} size='lg' fullscreen='lg' backdrop='static'>
				<ModalHeader toggle={toggle}>Workflow Links</ModalHeader>
				<ModalBody className='p-3'>
					<WorkflowsLinksContainer workflowId={id} />
				</ModalBody>
			</Modal>
			<div className='flex workflow_columns w-full items-center justify-between'>
				<div className='flex items-center space-x-4 text-left workflow-space-between'>
					<div className='flex gap-2 items-center'>
						<i className='uil-bars' />
						<span className='line-clamp-1 font-ibmplex text-lg font-bold' title={title}>
							{title}
						</span>
					</div>
					<div
						className='workflow_header_show'
						style={{
							fontSize: 28,
							display: 'none', 
						}}>
						{open ? <i className='uil-angle-down' /> : <i className='uil-angle-up' />}
					</div>
				</div>

				<div className='flex items-center space-x-4 margin-below'>
					{links?.length ? (
						<button
							className='flex workflow-height h-6 max-w-fit items-center justify-center space-x-1 rounded !border border-yellow-500 px-1 text-xs text-yellow-500 transition-all hover:bg-yellow-100'
							onClick={toggle}>
							<span className='!h-[6px] !w-[6px] rounded-full bg-yellow-500' />
							<span>{links?.length} Link(s) Available</span>
						</button>
					) : undefined}
					{updatesCount ? (
						<div className='flex workflow-height h-6 max-w-fit items-center justify-center space-x-1 rounded !border border-main px-1 text-xs text-main'>
							<span className='!h-[6px] !w-[6px] rounded-full bg-main' />
							<span>{updatesCount} Update(s) Available</span>
						</div>
					) : undefined}

					<div
						className='workflow_header_hide'
						style={{
							fontSize: 28,
						}}>
						{open ? <i className='uil-angle-up' /> : <i className='uil-angle-down' />}
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default Heading
