import { useEffect, useState } from 'react'

import { useAppRouter } from 'hooks'

import Badge from 'theme/ui/badge'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import { useLazyGetReadymadeTemplatesQuery } from './readymade-templates-api'
import ReadymadeTemplateExpandableTable from './readymade-templates-expandable-table'

const ReadymadeTemplateListingContainer = () => {
	const { location, navigate } = useAppRouter()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination

	const [fetchDocumentPackages, { data, isUninitialized, isLoading }] =
		useLazyGetReadymadeTemplatesQuery()

	useEffect(() => {
		if (pagination) {
			const parseQueryString = getParseQueryString(location?.search)
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			navigate(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	useEffect(() => {
		if (location?.search) {
			fetchDocumentPackages(getParseQueryString(location?.search))
		}
	}, [location?.search])

	return (
		<div className='divide-y'>
			<div className='flex items-center justify-between pb-3'>
				<div className='flex items-center space-x-3'>
					<h3 className='m-0 text-base'>Readymade Template</h3>
					<Badge rounded={false}>By Experivise</Badge>
				</div>
			</div>

			<div className='pt-4'>
				<ReadymadeTemplateExpandableTable
					data={data?.data}
					pagination={pagination}
					meta={data?.meta}
					setPagination={setPagination}
					isLoading={isUninitialized || isLoading}
				/>
			</div>
		</div>
	)
}

export default ReadymadeTemplateListingContainer
