import Actions from './actions'

export const popupKeys = {
	DOCUMENT_RENAME: 'DOCUMENT_RENAME',
	FOLDER_RENAME: 'FOLDER_RENAME',
	DOCUMENT_DELETE: 'DOCUMENT_DELETE',
	FOLDER_DELETE: 'FOLDER_DELETE',
	ADD_DOCUMENT_SHORTCUT: 'ADD_DOCUMENT_SHORTCUT',
	COPY_TO_FOLDER: 'COPY_TO_FOLDER',
}

export default Actions
