import PaymentsDetailsContainer from 'modules/payments/payent-details/payment-details-container'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const PaymentDetails = () => {
	return (
		<AppLayout
			meta={{
				title: 'Payment details',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<PageHeader title='Payments' hasBackButton={true} />
					<div className='page-body'>
						<PaymentsDetailsContainer />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default PaymentDetails
