import { Tab } from '@headlessui/react'

import FilePreviewCard from 'theme/ui/file-preview-card'
import TabsContent from './tabs-content'
import TabsHeader from './tabs-header'

const DocumentVersionDetailsContainer = ({ document }) => {
	const pdfFile = document?.file

	return (
		<div>
			<div className='mb-3 flex flex-col space-y-3'>
				<div className='prose mb-8 flex max-w-none flex-wrap items-center gap-10'>
					<div className='flex flex-col text-sm'>
						<strong>Document Title</strong>
						<span className='text-gray-500'>{document?.title}</span>
					</div>

					<div className='flex flex-col text-sm'>
						<strong>Maximum Users</strong>
						<span className='text-gray-500'>{document?.min_clients}</span>
					</div>

					<div className='flex flex-col text-sm'>
						<strong>Is Signature Required</strong>
						<span className='text-gray-500'>
							{document?.signature_required ? 'Yes' : 'No'}
						</span>
					</div>

					<div className='flex flex-col text-sm'>
						<strong>Version</strong>
						<span className='text-gray-500'>(v. {document?.version})</span>
					</div>
				</div>
				<div className='flex max-w-sm flex-col text-sm'>
					<strong className='mb-1'>Uploaded PDF</strong>
					<FilePreviewCard
						mime_type={pdfFile?.mime_type}
						name={pdfFile?.name}
						path={document?.file_path}
						title={document?.title}
					/>
				</div>
			</div>

			<div>
				<Tab.Group>
					<TabsHeader />
					<TabsContent document={document} />
				</Tab.Group>
			</div>
		</div>
	)
}

export default DocumentVersionDetailsContainer
