import { createColumnHelper } from '@tanstack/react-table'

import { useState } from 'react'
import DataTable, { IndeterminateCheckbox } from 'theme/ui/data-table'
import Actions from './Actions'
import Status from './Status'

const DocumentTemplateTable = ({
	data,
	meta,
	isLoading,
	pagination,
	setPagination,
	rowSelection,
	setRowSelection,
}) => {
	const [sorting, setSorting] = useState([])

	const columnHelper = createColumnHelper()

	const columns = [
		// columnHelper.accessor('index', {
		// 	size: 20,
		// 	header: () => <span>#</span>,
		// 	cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		// }),
		{
			id: 'select',
			size: 30,
			header: ({ table }) => (
				<IndeterminateCheckbox
					{...{
						checked: table.getIsAllRowsSelected(),
						indeterminate: table.getIsSomeRowsSelected(),
						onChange: table.getToggleAllRowsSelectedHandler(),
					}}
				/>
			),
			cell: ({ row }) => (
				<div>
					<IndeterminateCheckbox
						{...{
							checked: row.getIsSelected(),
							disabled: !row.getCanSelect(),
							indeterminate: row.getIsSomeSelected(),
							onChange: row.getToggleSelectedHandler(),
						}}
					/>
				</div>
			),
		},
		columnHelper.accessor('title', {
			id: 'name',
			size: 300, // This size is likely for column width control
			header: () => (
				<div style={{ minWidth: '100px' }}>
					<span>Title</span>
				</div>
			),
			cell: (info) => {
				return (
					<div style={{ minWidth: '100px' }}>
						<span className='line-clamp-1 max-w-full text-sm font-bold !text-gray-700'>
							{info.getValue()}
						</span>
					</div>
				)
			},
		}),
		columnHelper.accessor('status', {
			header: () => <span>Status</span>,
			cell: (info) => (
				<div className='flex'>
					<Status status={info.getValue() || 0} />
				</div>
			),
		}),

		columnHelper.accessor('version', {
			header: () => <span>Version</span>,
			cell: (info) => (
				<span className='font-semibold text-gray-700'>v. {info.getValue()}</span>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'created_at',
			header: () => (
				<div className='!w-full text-right'>
					<span>Actions</span>
				</div>
			),
			cell: (info) => <Actions field={info.getValue()} />,
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={data}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			setSorting={setSorting}
			sorting={sorting}
			meta={meta}
			setRowSelection={setRowSelection}
			rowSelection={rowSelection}
			isFullRowSelection={true}
		/>
	)
}

export default DocumentTemplateTable
