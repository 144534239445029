import { useHistory } from 'react-router-dom'
import { Button } from 'theme/ui/forms'

const LogoutButton = () => {
	const history = useHistory()
	return (
		<>
			<Button
				className='flex items-center rounded !border-gray-500 bg-slate-50 px-3 py-1'
				size='sm'
				onClick={() => history.push('/logout')}>
				<i className='uil uil-sign-out-alt font-size-18 me-1 items-center text-black '></i>
				<span className='text-black'>Sign Out</span>
			</Button>
		</>
	)
}

export default LogoutButton
