import { Fragment, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { SpinLoader } from 'components/Common'
import { useUser } from 'hooks'
import { useLazyGetMySubscriptionQuery } from 'modules/subscriptions/user-subscriptions-api'
import CurrentSubscription from './current-subscription'
import DangerZone from './danger-zone'
import UsageSubscription from './usage-subscription'

const ActiveSubscription = ({ activeTab }) => {
	const { user } = useUser()
	const [fetchMySubscription, { isLoading, data }] = useLazyGetMySubscriptionQuery()

	useEffect(() => {
		if (activeTab === 3 && user?.id) {
			fetchMySubscription(user?.id)
		}
	}, [activeTab, user])

	const $subscription = useMemo(() => data?.data, [data?.data])
	const subscription = useMemo(() => $subscription?.subscription, [$subscription])

	return (
		<Fragment>
			{isLoading ? (
				<SpinLoader />
			) : (
				<Fragment>
					{subscription ? (
						<div className='grid grid-cols-1 gap-8'>
							<CurrentSubscription subscription={subscription} />

							<UsageSubscription $subscription={$subscription} />

							{/* <PaymentsDetails subscription={subscription} /> */}
							{subscription?.stripeSubscriptionetails?.status !== 'canceled' && (
								<DangerZone subscription={subscription} />
							)}
						</div>
					) : (
						<div>
							<p className='text-center text-gray-500'>
								No active subscription details were found. Would you like to{' '}
								<Link to='/pricing' className='font-medium hover:!underline'>
									subscribe
								</Link>{' '}
								now?
							</p>
						</div>
					)}
				</Fragment>
			)}
		</Fragment>
	)
}

export default ActiveSubscription
