import { createColumnHelper } from '@tanstack/react-table'
import { useState } from 'react'

import DataTable from 'theme/ui/data-table'
import Actions from './actions'
import StatusBadge from './status-badge'

const AssignedWorkflowsTable = ({ workflows, meta, isLoading, pagination, setPagination }) => {
	const [actionActiveId, setActionActiveId] = useState(null)
	const onMouseEnter = (id) => setActionActiveId(id)
	const onMouseLeave = () => setActionActiveId(null)

	const [sorting, setSorting] = useState([])
	const columnHelper = createColumnHelper()

	const columns = [
		// columnHelper.accessor('index', {
		// 	size: 30,
		// 	enableSorting: false,
		// 	header: () => <span>#</span>,
		// 	cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		// }),

		columnHelper.accessor((row) => row.title, {
			id: 'title',
			size: 200,
			header: () => <span>Workflow Title</span>,
			cell: (info) => (
				<span
					className='line-clamp-1 text-sm font-bold !text-gray-700'
					title={info.getValue()}>
					{info.getValue()}
				</span>
			),
		}),
		columnHelper.accessor((row) => row.client, {
			id: 'client',
			size: 160,
			header: () => <span>Client</span>,
			cell: (info) => (
				<span
					className='line-clamp-1 text-sm font-bold !text-gray-700'
					title={info.getValue()}>
					{info.getValue()}
				</span>
			),
		}),
		columnHelper.accessor((row) => row.status, {
			id: 'status',
			size: 160,
			header: () => <span>Status</span>,
			cell: (info) => <StatusBadge value={info.getValue()} />,
		}),
		columnHelper.accessor((row) => row, {
			id: 'last_updated',
			size: 160,
			header: () => <span>Last Updated</span>,
			cell: (info) => (
				<span className=' text-sm  !text-gray-700'>{info.getValue()?.last_updated}</span>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'action',
			size: 160,
			header: () => <span>Last Updated</span>,
			cell: (info) => (
				<Actions
					last_updated={info.getValue()?.last_updated}
					id={info?.getValue()?.id}
					isActionActive={actionActiveId === info?.row?.id}
				/>
			),
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={workflows}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			setSorting={setSorting}
			sorting={sorting}
			meta={meta}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		/>
	)
}

export default AssignedWorkflowsTable
