import StatusDropdown from './status-dropdown'

export const getStatusOptions = () => {
	return [
		{ label: 'Non-Invited', value: 'non_invited', variant: 'secondary' },
		{ label: 'Invited', value: 'invited', variant: 'warning' },
		{ label: 'Onboarding', value: 'onboarding', variant: 'info' },
		{ label: 'Submitted', value: 'submitted', variant: 'primary' },
		{ label: 'Rejected', value: 'rejected', variant: 'danger' },
		{ label: 'Onboarded', value: 'onboarded', variant: 'success' },
	]
}

export default StatusDropdown
