import store from '../../../store/index'
import AppLayout from './app-layout'

const getPermissions = async (permissions) => {
	return new Promise((resolve) => {
		if (permissions.length > 0) {
			return resolve(permissions)
		}
	})
}

export const hideLoaderRoutes = ['/documents/:id/edit']


export const authorizeRequest = async (permissions, route) => {
	const $permissions = await getPermissions(permissions)
	const isSuperAdmin = store.getState().jwtauth?.user?.is_root_user === 1

	return new Promise((resolve, reject) => {
		if (route?.isNoAuthorizationRequired) {
			resolve(true)
		} else if (isSuperAdmin) {
			resolve(true)
		} else if ($permissions.includes(route?.permission)) {
			resolve(true)
		} else {
			reject(new Error('You do not have permission to access this page', { cause: 403 }))
		}
	})
}

export default AppLayout
