import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'

import { ReactComponent as CloseIcon2 } from 'assets/svg/close-icon-2.svg'
import AddressForm from './address-form'

const AddressFormPopup = ({ client }) => {
	const [isOpen, setIsOpen] = useState(false)
	const onOpen = () => setIsOpen(true)
	const onClose = () => setIsOpen(false)

	return (
		<div>
			<button className='btn btn-outline-primary btn-sm' onClick={onOpen} type='button'>
				<i className='uil uil-pen' /> Edit
			</button>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as='div' className='popup-overlay relative' onClose={onClose}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'>
						<div className='fixed inset-0 bg-black/25' />
					</Transition.Child>

					<div className='fixed inset-0 flex w-screen items-end justify-center overflow-auto md:items-center md:p-4'>
						<Transition.Child as={Fragment}>
							<Dialog.Panel className='my-auto w-full max-w-[800px] overflow-auto rounded-[20px] bg-white edit_client_space'>
								<div>
									<div className='relative -left-[2%] top-[2%]'>
										<button
											type='button'
											className='absolute right-0 top-3'
											onClick={onClose}>
											<CloseIcon2 />
										</button>
									</div>

									<div className='mx-auto mt-0 flex w-full flex-col items-center p-3'>
										<h3 className='m-0 text-lg font-semibold'>
											Edit Client Information
										</h3>
									</div>
								</div>

								<div className='rounded-t-[20px]  p-3 md:rounded-b-[20px]'>
									<AddressForm client={client} onClose={onClose} />
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</div>
	)
}

export default AddressFormPopup
