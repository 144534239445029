import { useState } from 'react'
import StickyBox from 'react-sticky-box'
import { reduxForm } from 'redux-form'

import LoginHistory from './components/LoginHistory'
import ClientProfile from './components/client-profile'
import Tabs from './components/tabs'
import Navs from './components/tabs/Navs'
import CreateNewNote from './components/tabs/Notes/CreateNewNote'
import useNoteViewQuery from './components/tabs/Notes/useNoteViewQuery'

const ClientDetailsComp = ({
	client,
	role,
	reset,
	handleSubmit,
	isEditMode = false,
	isOnBoarded = true,
}) => {
	const [activeTab, setactiveTab] = useState('1')
	const [modal_standard, setmodal_standard] = useState(false)

	function removeBodyCss() {
		document.body.classList.add('no_padding')
	}
	const togStandard = () => {
		setmodal_standard(!modal_standard)
		removeBodyCss()
	}

	const pagination = { pageIndex: 0, pageSize: 10 }
	const { mutate } = useNoteViewQuery(pagination, client, role)

	return (
		<div className='row g-5 client_details'>
			<div className='col-lg-8 col-12 details_fill_width'>
				<StickyBox offsetBottom={20} offsetTop={85}>
					<div className='card !rounded-lg shadow-none'>
						<div className='card-body pb-0'>
							<ClientProfile
								client={client}
								isEditMode={isEditMode}
								togStandard={togStandard}
							/>
							<Navs tabState={[activeTab, setactiveTab]} isEditMode={isEditMode} />
						</div>
					</div>
					{client && (
						<Tabs
							isEditMode={isEditMode}
							client={client}
							role={role}
							activeTab={activeTab}
						/>
					)}
				</StickyBox>
			</div>
			{isOnBoarded ? (
				<div className='col-lg-4 col-12 details_fill_width'>
					<LoginHistory client={client} />
				</div>
			) : undefined}

			<CreateNewNote
				mutate={mutate}
				client={client}
				reset={reset}
				open={modal_standard}
				onClose={() => setmodal_standard(false)}
				onOpen={togStandard}
				handleSubmit={handleSubmit}
			/>
		</div>
	)
}

const ClientDetailsCard = reduxForm({
	form: 'notes_form',
	enableReinitialize: true,
})(ClientDetailsComp)

export default ClientDetailsCard
