import { ReactComponent as DocumentIcon } from 'assets/svg/document-icon.svg'
import { ReactComponent as FolderIcon } from 'assets/svg/folder-icon.svg'
import { Loader } from 'components/Common'
import { useState } from 'react'

const DataListFolder = ({ type, listData, isLoading, onSelectionChange }) => {
	const [selectedIds, setSelectedIds] = useState([])

	const handleCheckboxChange = (id, checked) => {
		const updatedSelectedIds = checked
			? [...selectedIds, id]
			: selectedIds.filter((selectedId) => selectedId !== id)

		setSelectedIds(updatedSelectedIds)
		onSelectionChange(updatedSelectedIds)
	}

	if (!listData && isLoading) {
		return <Loader />
	}

	if (listData && !listData?.length) {
		return <p className='text-center'>No {type}s found to review</p>
	}

	return (
		<div>
			{listData &&
				listData.map((item) => (
					<div
						className='flex items-center justify-between space-x-2 px-4 py-3 shadow-sm'
						key={item.id}>
						<div className='flex items-center gap-1'>
							{type === 'folder' ? (
								<FolderIcon height={15} />
							) : (
								<DocumentIcon height={20} />
							)}
							<span className='my-1 ml-2 text-sm font-medium'>{item?.title}</span>
						</div>
						<div>
							<input
								type='checkbox'
								className='form-check-input'
								onChange={(e) => handleCheckboxChange(item.id, e.target.checked)}
							/>
						</div>
					</div>
				))}
		</div>
	)
}

export default DataListFolder
