import base64 from 'base-64'
import * as yup from 'yup'

import apiEndpoints from 'helpers/apiEndpoints'
import request from 'utilities/request'
import RegisterForm from './register-form'

export const REGISTER_ACTIONS = {
	CREATE_TEAM: 'create-team',
	ENTER_AS_ADVISOR: 'enter-as-advisor',
}

export const CLIENT_ROLE = 'client'

export const registerValidation = yup.object().shape({
	first_name: yup.string().required('Please enter your first name'),
	last_name: yup.string().required('Please enter your last name'),
	email: yup.string().email().required('Please enter your email address'),
	phone_number: yup.string().required('Please enter your phone number'),
	password: yup
		.string()
		.required('Please create your password')
		.min(8, 'Your password must be at least 8 characters')
		.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]+$/),
	passwordConfirm: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
	team_name: yup.string().required('Please enter your unique team name'),
})

export const getDecodedValues = (encoded) => {
	try {
		const decoded = base64.decode(encoded)
		const { email, first_name, last_name, team, role_name, group_id } = JSON.parse(decoded)
		return {
			email: email || '',
			first_name: first_name || '',
			last_name: last_name || '',
			team_name: team || '',
			role_name: role_name || '',
			group_id: group_id || '',
		}
	} catch (error) {
		return {
			first_name: '',
			last_name: '',
			email: '',
			team_name: '',
			role_name: '',
		}
	}
}

export const getDisabledFields = (initialValues) => {
	return {
		first_name: !!initialValues?.first_name,
		last_name: !!initialValues?.last_name,
		email: !!initialValues?.email,
		team_name: !!initialValues?.team_name,
	}
}

export const registerRequest = (values) => {
	return request.post(apiEndpoints.auth.register, values)
}

export default RegisterForm
