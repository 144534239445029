import { Fragment, useState } from 'react'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

import { useAppRouter, useUser } from 'hooks'
import { dispatch } from 'store'
import DeleteConfirmAlert from 'theme/ui/delete-confirm-alert'
import notification from 'utilities/notification'
import * as actions from '../../../store/file-manager/actions'
import {
	useDeleteDocumentPackageMutation,
	useDeleteDocumentTemplateMutation,
	useMarkDocumentPackageAsStarMutation,
} from '../file-manager-api'
import RenamePopup from '../my-documents-listing/rename-popup'
import CreateFolder from '../search-bar/create-folder'
import AddToFolder from './add-to-folder'

const OptionsList = ({ data, type }) => {
	const { isSuperAdmin } = useUser()
	const [deleteDocumentPackage, { isLoading }] = useDeleteDocumentPackageMutation()
	const [markFolderStarred] = useMarkDocumentPackageAsStarMutation()
	const [isOpen, setIsOpen] = useState(false)
	const [isRenameOpen, setIsRenameOpen] = useState(false)
	const { navigate } = useAppRouter()
	const [isDeleteOpen, setIsDeleteOpen] = useState({
		open: false,
		id: undefined,
	})
	const [isFolderPopupOpen, setIsFolderPopupOpen] = useState(false)
	const [menu, setMenu] = useState(false)
	const [deleteDocumentTemplate, { isLoading: isDeleting }] = useDeleteDocumentTemplateMutation()

	const onOpen = () => setIsOpen(true)
	const onClose = () => setIsOpen(false)
	const onDeleteOpen = (id) => setIsDeleteOpen({ open: true, id })
	const onDeleteClose = () => setIsDeleteOpen({ open: false, id: undefined })

	const onDeleteFolder = async () => {
		if (type === 'folder') {
			const id = data?.id
			const response = await deleteDocumentPackage(id)
			if (response?.data?.status === 200) {
				notification('success', response?.data?.message)
			}
			onDeleteClose()
		} else {
			try {
				const id = data?.id
				const response = await deleteDocumentTemplate(id)
				if (response?.data?.status === 200) {
					notification('success', response?.data?.message)
				}
				onDeleteClose
			} catch (error) {
				notification('error', error?.message)
			}
		}
	}

	const attachInWorkflow = (data, type) => {
		const item = {
			id: data?.id,
			type: 'folder',
			title: data?.title,
			value: JSON.stringify({
				id: data?.id,
				minClients: data?.minClients,
			}),
		}
		if (type === 'folder') {
			dispatch(actions.setSelectedFolders([item]))
			navigate(`/workflows/create?folder=true`)
		} else {
			dispatch(actions.setSelectedDocuments([item]))
			navigate(`/workflows/create?document=true`)
		}
	}

	const onMarkStarred = async (id, type) => {
		try {
			let payload
			if (type === 'folder') {
				payload = { document_package_id: id }
			} else {
				payload = { document_id: id }
			}
			const response = await markFolderStarred(payload).unwrap()

			if (response?.message) {
				notification('success', response?.message)
			}
		} catch (error) {
			notification('error', error?.message)
		}
	}

	const onOptionClick = (action, data, type) => {
		switch (action) {
			case 'view':
				if (type === 'folder') {
					console.log('View folder', data.id)
				} else {
					console.log('View file', data.id)
				}
				break
			case 'attach':
				attachInWorkflow(data, type)
				break
			case 'addToFolder':
				onOpen()
				break
			case 'star':
				onMarkStarred(data?.id, type)
				break
			case 'delete':
				onDeleteOpen()
				break
			default:
				break
		}
	}

	return (
		<Fragment>
			<Dropdown
				isOpen={menu}
				toggle={() => setMenu(!menu)}
				className='dropdown inline-block'
				tag='li'>
				<DropdownToggle
					tag='button'
					type='button'
					className='m-0 !border !border-none bg-transparent p-0'>
					<i className='mdi mdi-dots-vertical text-[16px]'></i>
				</DropdownToggle>
				<DropdownMenu className='dropdown-menu-md dropdown-menu-end p-0'>
					<div className='w-full overflow-hidden rounded-md bg-white text-left shadow-lg ring-1 ring-black/5'>
						<div className='relative divide-y'>
							<div className='flex flex-col gap-1 py-2'>
								{/* <span
									onClick={() => onOptionClick('view', data, type)}
									className='flex cursor-pointer gap-3 px-3 py-2 text-[15px] hover:bg-blue-50 hover:text-blue-600'>
									<i className='uil-eye'></i>
									View
								</span> */}
								{!isSuperAdmin ? (
									<span
										onClick={() => onOptionClick('attach', data, type)}
										className='flex cursor-pointer gap-3 px-3 py-2 text-[15px] hover:bg-blue-50 hover:text-blue-600'>
										<i className='uil-paperclip'></i>
										Attach in Workflow
									</span>
								) : undefined}
								<span
									onClick={() => onOptionClick('star', data, type)}
									className='flex cursor-pointer gap-3 px-3 py-2 text-[15px] hover:bg-blue-50 hover:text-blue-600'>
									<i className='bx bxs-star text-main'></i>
									<span className='text-sm'>Remove from Starred</span>
								</span>
								<span
									onClick={() => onOptionClick('delete', data, type)}
									className='flex cursor-pointer gap-3 px-3 py-2 text-[15px] text-red-600 hover:bg-blue-50 hover:text-blue-600'>
									<i className='uil-trash text-red-600'></i>
									Move to trash
								</span>
							</div>
						</div>
					</div>
				</DropdownMenu>
			</Dropdown>
			<CreateFolder
				onClose={() => setIsFolderPopupOpen(!isFolderPopupOpen)}
				isOpen={isFolderPopupOpen}
				type={type}
				data={data}
			/>
			<AddToFolder
				onClose={onClose}
				isOpen={isOpen}
				type={'folder'}
				file={data}
				addType={type == 'document' ? 'copy' : 'move'}
			/>
			<DeleteConfirmAlert
				isOpen={isDeleteOpen?.open}
				onClose={onDeleteClose}
				isLoading={isLoading || isDeleting}
				onConfirm={onDeleteFolder}
				className='z-[9999]'
			/>
			<RenamePopup
				document={data}
				isOpen={isRenameOpen}
				onClose={() => setIsRenameOpen(false)}
			/>
		</Fragment>
	)
}

export default OptionsList
