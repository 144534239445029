import { Fragment, useState } from 'react'
import { FileIcon, defaultStyles } from 'react-file-icon'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { PdfViewer } from 'theme/ui/document-preview'

const PdfFileCard = ({ file }) => {
	let [isOpen, setIsOpen] = useState(false)

	function openModal() {
		setIsOpen(true)
	}
	const toggle = () => setIsOpen(!isOpen)

	return (
		<Fragment>
			<Modal isOpen={isOpen} toggle={toggle} size='lg' fullscreen='lg' backdrop='static'>
				<ModalHeader toggle={toggle}>Document Preview</ModalHeader>
				<ModalBody className='p-0'>
					<PdfViewer src={file?.fileUrl} />
				</ModalBody>
			</Modal>
			<div className='flex min-w-[200px] items-center justify-between space-x-3 rounded-md !border bg-white px-3 py-2'>
				<div className='flex items-center space-x-3 '>
					<div className='h-auto w-9'>
						<FileIcon extension={'pdf'} {...defaultStyles?.pdf} />
					</div>
					<div className='flex flex-col'>
						{file?.title ? (
							<span className='line-clamp-1 font-semibold' title={file?.title}>
								{file?.title}
							</span>
						) : undefined}
						{file?.files?.original_name ? (
							<span
								className='line-clamp-1 text-xs text-gray-500'
								title={file?.files?.original_name}>
								{file?.files?.original_name}
							</span>
						) : undefined}
					</div>
				</div>

				<a
					onClick={(e) => {
						e.preventDefault()
						openModal()
					}}
					href={file?.files?.path}
					className='link'>
					View
				</a>
			</div>
		</Fragment>
	)
}

export default PdfFileCard
