import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { PdfViewer } from '../document-preview'

const FilePreviewPopup = ({ isOpen, mime_type, path, toggle }) => {
	const [type, extension] = mime_type ? mime_type.split('/') : []

	return (
		<Modal isOpen={isOpen} toggle={toggle} size='lg' fullscreen='lg' backdrop='static'>
			<ModalHeader toggle={toggle}>File Preview</ModalHeader>
			<ModalBody className='p-0'>
				{type === 'image' ? (
					<div
						className='mx-auto flex items-center
                         justify-center p-3'>
						<img src={path} className='h-auto w-[600px]' />
					</div>
				) : undefined}

				{type === 'application' && extension === 'pdf' ? (
					<PdfViewer src={path} />
				) : undefined}
			</ModalBody>
		</Modal>
	)
}

export default FilePreviewPopup
