import { Field } from 'formik'
import { useMemo } from 'react'
import { InputField } from 'theme/ui/forms'

const MarketingFeatureList = ({ formik }) => {
	const formValues = useMemo(() => formik?.values, [formik?.values])

	return (
		<div>
			<label className='m-0 p-0 font-medium text-gray-700'>Marketing feature list</label>
			<p className='m-0 mb-1 p-0 text-[12px] italic text-gray-400'>
				A list of product features that will be visible to customers. Displayed in pricing
				tables.
			</p>
			<div>
				{formValues.marketing_features?.map((feature, index) => (
					<div key={index} className='mb-1 flex flex-col '>
						<div className='flex items-center gap-4'>
							<div className='form-control !m-0 !w-full !border !border-none !p-0'>
								<Field
									placeholder='Enter marketing feature'
									name={`marketing_features[${index}]`}
									type='text'
									component={InputField}
									value={formValues.marketing_features[index] || ''}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
								/>
							</div>

							<span
								onClick={() => {
									const updatedFeatures = [...formValues.marketing_features]
									updatedFeatures.splice(index, 1)
									formik.setFieldValue('marketing_features', updatedFeatures)
								}}
								className='mx-2 cursor-pointer'>
								<i className='uil-times'></i>
							</span>
						</div>
						{formik?.errors?.marketing_features?.[index] &&
						formik?.touched?.marketing_features?.[index] ? (
							<p className='m-0 mx-1 p-0 text-[12px] text-red-500'>
								{formik?.errors?.marketing_features[index]}
							</p>
						) : null}
					</div>
				))}

				<div className='mt-3'>
					<span
						onClick={() => {
							const updatedFeatures = [...formValues.marketing_features, '']
							formik.setFieldValue('marketing_features', updatedFeatures)
						}}
						className='float-end cursor-pointer text-blue-500'>
						+ Add
					</span>
				</div>
			</div>
		</div>
	)
}

export default MarketingFeatureList
