import { createApi } from '@reduxjs/toolkit/query/react'
import apiEndpoints from 'helpers/apiEndpoints'
import axiosBaseQuery from 'store/axios-base-query'
import { formatDate } from 'utilities/helpers'
import { getDocumentResponse } from './document-template-form/helpers'

const API_URL = apiEndpoints.document
const API_STAR_URL = '/starred-document'

export const documentApi = createApi({
	reducerPath: 'Documents',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['Documents', 'Document', 'EditEligibleDocument', 'DocumentWorkflows'],
	endpoints: (builder) => ({
		getDocumentTemplates: builder.query({
			keepUnusedDataFor: 0,
			query: ({ params }) => ({
				url: API_URL,
				method: 'GET',
				params: params,
			}),
			providesTags: ['Document'],
			transformResponse: (response, _, arg) => {
				const documents = Array.isArray(response?.data?.documents)
					? response?.data?.documents.map((document, idx) => ({
							index:
								parseInt(arg?.params?.limit) * (parseInt(arg?.params?.page) - 1) +
								idx +
								1,
							id: document?.id,
							title: document?.title || document?.workflow_template?.title,
							status: document?.status,
							version: document?.version,
							isStarred: document?.isStarred,
							last_edit: document?.lastEdited,
							folders: Array.isArray(document?.documentPackages)
								? document?.documentPackages
								: [],
					  }))
					: []
				return {
					documents,
					meta: response?.data?.meta,
				}
			},
		}),
		getOneDocumentTemplate: builder.query({
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'GET',
			}),
			transformResponse: (response) => ({
				document: getDocumentResponse(response?.data),
				status: response?.status,
			}),
			providesTags: ['Document'],
		}),
		uploadDocument: builder.mutation({
			query: ({ payload, onUploadProgress }) => ({
				url: `${API_URL}/upload`,
				method: 'POST',
				data: payload,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				onUploadProgress: onUploadProgress,
			}),
		}),
		checkEditEligibility: builder.query({
			query: (id) => ({
				url: `${API_URL}/${id}/edit`,
				method: 'GET',
			}),
			providesTags: ['EditEligibleDocument'],
		}),
		getDocumentWorkflows: builder.query({
			query: (id) => ({
				url: `${API_URL}/${id}/workflow-details`,
				method: 'GET',
			}),
			providesTags: ['DocumentWorkflows'],
		}),
		deactivateWorkflows: builder.mutation({
			query: (id) => ({
				url: `${API_URL}/${id}/deactivate-workflows`,
				method: 'POST',
			}),
		}),
		createDocument: builder.mutation({
			query: (payload) => ({
				url: API_URL,
				method: 'POST',
				data: payload,
			}),
		}),
		updateDocument: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${API_URL}/${id}`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['Document'],
		}),
		autoSaveDocument: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${API_URL}/form/${id}`,
				method: 'PATCH',
				data: payload,
			}),
		}),
		markDocumentAsStar: builder.mutation({
			query: (payload) => ({
				url: API_STAR_URL,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['Document'],
		}),
		documentDeleteEligible: builder.query({
			query: (id) => ({
				url: `${apiEndpoints.documentDeleteEligible}/${id}`,
				method: 'GET',
			}),
			providesTags: ['Documents'],
		}),
		deleteDocumentTemplate: builder.mutation({
			query: (id) => ({
				url: `${apiEndpoints.document}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Document'],
		}),
		getCompletedDocument: builder.query({
			keepUnusedDataFor: 0,
			query: ({ params, url }) => ({
				url: url,
				method: 'GET',
				params: params,
			}),
			providesTags: ['Documents'],
			transformResponse: (response, _, arg) => {
				const documents = Array.isArray(response?.data?.documents)
					? response?.data?.documents.map((document, idx) => ({
							index:
								parseInt(arg?.params?.limit) * (parseInt(arg?.params?.page) - 1) +
								idx +
								1,
							workflow_step_id: document?.id,
							id: document?.id,
							documentId: document?.document?.id,
							title: document?.title,
							file_url: document?.file?.path,
							advisor: document?.workflow_step?.workflow?.advisor || '-',
							client: document?.workflow_step?.workflow?.client || '-',
							workflow_title: document?.workflow_step?.workflow?.title || '-',
							final_reviewer:
								document?.workflow_step?.workflow?.workflowFinalReviewer
									?.finalReviewer || '-',
							updated_at: document?.updated_at
								? formatDate(document?.updated_at)
								: '-',
					  }))
					: []
				return {
					data: documents,
					meta: response?.data?.meta,
				}
			},
		}),

		AddShortcut: builder.mutation({
			query: (payload) => ({
				url: `${apiEndpoints.documentPackage}/batch-update`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['Document'],
		}),

		batchDeleteDocuments: builder.mutation({
			query: (payload) => ({
				url: apiEndpoints.document,
				method: 'DELETE',
				data: payload,
			}),
			invalidatesTags: ['Document'],
		}),

		globalSearchDocuments: builder.query({
			query: (params) => ({
				url: `${apiEndpoints.documentPackage}/global-search`,
				method: 'GET',
				params: params,
			}),
			providesTags: ['Document'],
			transformResponse: (response, _, arg) => {
				const documents = Array.isArray(response?.data?.documents)
					? response?.data?.documents.map((document, idx) => ({
							index:
								parseInt(arg?.params?.limit) * (parseInt(arg?.params?.page) - 1) +
								idx +
								1,
							id: document?.id,
							title: document?.title || document?.workflow_template?.title,
							status: document?.status,
							version: document?.version,
							isStarred: document?.isStarred,
							last_edit: document?.updated_at,
							folders: Array.isArray(document?.documentPackages)
								? document?.documentPackages
								: [],
					  }))
					: []
				return {
					documents,
					meta: response?.data?.meta,
				}
			},
		}),
	}),
})

export const {
	useLazyGetDocumentTemplatesQuery,
	useLazyGetOneDocumentTemplateQuery,
	useLazyCheckEditEligibilityQuery,
	useLazyGetDocumentWorkflowsQuery,
	useDeactivateWorkflowsMutation,
	useLazyDocumentDeleteEligibleQuery,
	useDeleteDocumentTemplateMutation,
	useLazyGetCompletedDocumentQuery,
	useUploadDocumentMutation,
	useCreateDocumentMutation,
	useUpdateDocumentMutation,
	useAutoSaveDocumentMutation,
	useMarkDocumentAsStarMutation,
	useAddShortcutMutation,
	useBatchDeleteDocumentsMutation,

	useLazyGlobalSearchDocumentsQuery,
} = documentApi
