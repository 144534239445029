/* eslint-disable no-unused-vars */
import axios from 'axios'

const axiosBaseQuery = async (config, _, __) => {
	try {
		const response = await axios(config)
		if (axios.isAxiosError(response)) {
			throw new Error(response?.response?.data?.message)
		}
		return { data: response.data }
	} catch (axiosError) {
		const e = axiosError
		return {
			error: {
				status: e.response?.status,
				data: e.response?.data || e.message,
			},
		}
	}
}

export default axiosBaseQuery
