import { formatToTwoDigits } from 'utilities/helpers'
import DocumentsDropdown from './documents-dropdown'

function countElements(arr) {
	const counts = new Map()

	for (let i = 0; i < arr.length; i++) {
		const num = arr[i]
		if (counts.has(num)) {
			counts.set(num, counts.get(num) + 1)
		} else {
			counts.set(num, 1)
		}
	}

	const result = Object.fromEntries(counts)

	return result
}

export const createOption = (document, selectedDocs) => {
	const $count = countElements(selectedDocs || [])
	return {
		title: document?.title,
		count: $count[document?.id],
		value: JSON.stringify({
			id: document?.id,
			minClients: document?.minClients,
		}),
	}
}

export const createOptionLabel = (document) => {
	return (
		<span className='line-clamp-1' title={document?.title}>
			<span>{document?.title}</span>
			{document?.count ? (
				<span className='ml-3 rounded-md bg-main p-1 text-xs text-white'>
					{formatToTwoDigits(document?.count || 0)}
				</span>
			) : undefined}
		</span>
	)
}
export { default as MultiDocumentsDropdown } from './multi-documents-dropdown'
export default DocumentsDropdown