import DocumentPackageDropdown from './document-package-dropdown'

export const createOption = (documentPackage) => ({
	title: documentPackage?.title,
	id: documentPackage?.id,
	type: 'folder',
	value: JSON.stringify({
		id: documentPackage?.id,
		minClients: documentPackage?.minClients,
	}),
})

export const createOptionLabel = (documentPackage) => {
	return (
		<span className='line-clamp-1' title={documentPackage?.title}>
			{documentPackage?.title}
		</span>
	)
}

export default DocumentPackageDropdown
