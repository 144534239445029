import { createColumnHelper } from '@tanstack/react-table'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import Badge from 'theme/ui/badge'
import DataTable from 'theme/ui/data-table'
import ToolTip from 'theme/ui/tool-tip'
import { formatDate } from 'utilities/helpers'
import { ROLES_APP_ROUTES } from '../routes'

const RolesTable = ({ data, meta, isLoading, pagination, setPagination }) => {
	const columnHelper = createColumnHelper()
	const [sorting, setSorting] = useState([])
	const columns = [
		columnHelper.accessor((row) => row, {
			id: 'id',
			size: 50,
			header: () => <span>#</span>,
			cell: (info) => <div>{info?.getValue()?.ind}</div>,
		}),
		columnHelper.accessor((row) => row, {
			id: 'name',
			size: 200,
			header: () => <span>Role</span>,
			cell: (info) => <div>{info?.getValue()?.name}</div>,
		}),
		columnHelper.accessor((row) => row, {
			id: 'description',
			size: 200,
			header: () => <span>Description</span>,
			cell: (info) => <div>{info?.getValue()?.description}</div>,
		}),
		columnHelper.accessor((row) => row, {
			id: 'status',
			size: 200,
			header: () => <span>Status</span>,
			cell: (info) => (
				<>
					<Badge variant={info?.getValue()?.is_active == 'Active' ? 'success' : 'danger'}>
						<span className='capitalize'>{info?.getValue()?.is_active}</span>
					</Badge>
				</>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'created_at',
			size: 200,
			header: () => (
				<div className='!w-full'>
					<span className='float-right'>Created At</span>
				</div>
			),
			cell: (info) => (
				<div className='!w-full'>
					<span className='float-right capitalize'>
						{formatDate(info?.getValue()?.created_at)}
					</span>
				</div>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'action',
			size: 200,
			enableSorting: false,
			header: () => (
				<div className='flex !w-full items-center justify-end'>
					<span className='float-right'>Actions</span>
				</div>
			),
			cell: (info) => (
				<>
					<div>
						<div className='float-right flex justify-end space-x-2'>
							<ToolTip
								targetId={`edit-role-${info?.getValue()?.id}`}
								tooltipText='Edit'
								placement='top'
							/>
							<Link
								to={ROLES_APP_ROUTES.edit(info?.getValue()?.id)}
								id={`edit-role-${info?.getValue()?.id}`}
								className='action-btn btn-primary-outline'>
								<i className='uil-edit' style={{ fontSize: 16 }} />
							</Link>
						</div>
					</div>
				</>
			),
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={data}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			meta={meta}
			setSorting={setSorting}
			sorting={sorting}
		/>
	)
}

export default RolesTable
