import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'

import { ReactComponent as WorkflowAttachIcon } from 'assets/svg/workflow-attach-icon.svg'
import { useAppRouter } from 'hooks'
import { useDispatch } from 'react-redux'
import * as actions from 'store/file-manager/actions'

const AttachInWorkflowPopup = ({ isOpen, onClose, selectedRows }) => {
	const dispatch = useDispatch()
	const { navigate } = useAppRouter()

	const attachInWorkflow = (documents) => {
		const $documents = documents.filter((doc) => doc?.status === 1)

		const items = $documents.map((doc) => ({
			title: doc?.title,
			value: JSON.stringify({
				id: doc?.id,
				minClients: doc?.minClients,
			}),
		}))

		dispatch(actions.setSelectedDocuments(items))
		navigate(`/workflows/create?document=true`)
	}

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-[1008]' onClose={() => {}}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/30' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'>
							<Dialog.Panel
								className={`w-full max-w-xl transform overflow-hidden rounded-3xl bg-white p-2 text-left align-middle shadow-xl transition-all`}>
								<div className='top-o relative flex justify-between rounded-3xl bg-blue-50 p-3'>
									<div></div>
									<div className='absolute  left-6 top-6 rounded-full !border !border-gray-300  bg-white !p-3 '>
										<span>
											<WorkflowAttachIcon height={24} width={24} />
										</span>
									</div>
									<button type='button' className='right-0' onClick={onClose}>
										<i className='fas fa-times rounded-full bg-white px-2 py-1 text-[20px]' />
									</button>
								</div>
								<div className='mt-10 flex flex-col '>
									<div className='ms-4 flex flex-col justify-start ps-2'>
										<Dialog.Title
											as='h2'
											className='mb-3 text-left text-2xl font-bold leading-6 text-gray-900'>
											Attach in workflow
										</Dialog.Title>
										<div>
											<p className='m-0 p-0 text-lg font-medium'>
												You have chosen to attach in workflow{' '}
												<span className='text-blue-400'>
													{selectedRows?.length} documents
												</span>
											</p>
											<p className='m-0 p-0 text-xs'>
												<span className='text-red-700'>*</span>This will
												apply only on documents with &quot;Published&quot;
												status
											</p>
										</div>
									</div>
									<hr className='mx-4 my-3 bg-gray-300' />

									<div className='my-2 ms-3 flex w-full flex-col gap-2 pe-5 ps-2'>
										<button
											type='button'
											className='rounded-md !border border-main bg-main p-2 font-medium text-white'
											size='sm'
											onClick={() => attachInWorkflow(selectedRows)}>
											Attach Now
										</button>
										<button
											type='button'
											size='sm'
											className='rounded-md bg-white p-2 font-medium text-black'
											onClick={onClose}>
											Cancel
										</button>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export default AttachInWorkflowPopup
