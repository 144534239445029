import { createColumnHelper } from '@tanstack/react-table'
import React, { Fragment, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import apiEndpoints from 'helpers/apiEndpoints'
import { CLIENT_APP_ROUTES } from 'modules/clients/routes'
import { useHistory } from 'react-router-dom'
import { update } from 'store/common/actions'
import Badge from 'theme/ui/badge'
import DataTable from 'theme/ui/data-table'
import { isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'
import StatusQuery from '../StatusQuery'
import FilterForm from './FilterForm'
import TagList from './tag-list'
import TaskActions from './task-actions'
import TaskPopover from './task-popover'
const ListView = ({ assignments, meta, isLoading, mutate, pagination, setPagination }) => {
	const dispatch = useDispatch()
	const { statuses } = StatusQuery()
	const [sorting, setSorting] = useState([])
	const history = useHistory()
	const handleClientClick = (clientId) => {
		history.push(CLIENT_APP_ROUTES.findOne(clientId))
	}

	const onStatusChange = (e, task_id) => {
		e.preventDefault()
		const status = e.target.value

		dispatch(
			update(`${apiEndpoints.task}/${task_id}`, 'update-status', {
				status,
			}),
		).then((response) => {
			if (response?.status === 200) {
				mutate()
			}
		})
	}

	const columnHelper = createColumnHelper()

	const columns = [
		columnHelper.accessor('serial', {
			header: () => <span>#</span>,
			size: 20,
			cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		}),
		columnHelper.accessor((row) => row, {
			id: 'summary',
			header: () => <span>Summary</span>,
			cell: (info) => (
				<Link
					to={isAllowTo(permissions?.task?.edit) ? `/tasks/${info.getValue()?.id}` : '#'}
					className='link line-clamp-1 max-w-[180px] text-sm font-bold !text-gray-700'
					title={info.getValue()?.task_title}>
					{info.getValue()?.task_title}
				</Link>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'progress_status',
			header: () => <span>Status</span>,
			cell: (info) => (
				<Fragment>
					<select
						className='rounded bg-[#F5F6F8] px-2 py-1 text-xs'
						onChange={(e) => onStatusChange(e, info.getValue()?.id)}
						defaultValue={info.getValue()?.progress_status}>
						{statuses.map((status, i) => (
							<option key={i}>{status?.title}</option>
						))}
					</select>
				</Fragment>
			),
		}),
		columnHelper.accessor('assignee', {
			header: () => <span>Assignee</span>,
			cell: (info) => (
				<div className='flex space-x-1'>
					{info.getValue()?.first_name && info.getValue()?.last_name ? (
						<div className='flex items-center justify-center rounded bg-indigo-100 px-2 py-1 text-xs'>
							<span
								className='line-clamp-1 cursor-pointer font-semibold uppercase text-indigo-600'
								title={`${info.getValue()?.first_name} ${
									info.getValue()?.last_name
								}`}>
								{info.getValue()?.first_name} &nbsp; {info.getValue()?.last_name}
							</span>
						</div>
					) : undefined}
				</div>
			),
		}),
		columnHelper.accessor('clients', {
			header: () => <span>clients</span>,
			size: 200,
			cell: (info) => {
				const _clients = useMemo(() => {
					const clients = info.getValue()
					if (Array.isArray(clients)) {
						return clients
							.map((client) => ({
								name: `${client?.first_name} ${client?.last_name}`,
								id: client?.id,
							}))
							.slice(0, 3)
					} else {
						return []
					}
				}, [info.getValue()])

				return (
					<div>
						{_clients.length > 0 && (
							<div className='flex flex-row items-center gap-2'>
								<Badge
									variant='primary'
									className='line-clamp-1 flex cursor-pointer'
									onClick={() => handleClientClick(_clients[0].id)}>
									{_clients[0].name}
								</Badge>
								{_clients.length > 1 && (
									<TaskPopover
										data={_clients}
										title='Groups'
										onClick={handleClientClick}
									/>
								)}
							</div>
						)}
					</div>
				)
			},
		}),
		columnHelper.accessor('workflow', {
			header: () => <span>Workflow</span>,
			cell: (info) => (
				<>
					{info.getValue()?.title ? (
						<span
							className='tag tag-info line-clamp-1 max-w-max'
							title={info.getValue()?.title}>
							{info.getValue()?.title}
						</span>
					) : undefined}
				</>
			),
		}),
		columnHelper.accessor('tags', {
			header: () => <span>Tags</span>,
			cell: (info) => <TagList tags={info.getValue()} />,
		}),
		
		columnHelper.accessor((row) => row, {
			id: 'actions',
			size: 100,
			enableSorting: false,
			header: () => <span>Actions</span>,
			cell: (info) => <TaskActions task={info.getValue()} mutate={mutate} />,
		}),
	]

	return (
		<React.Fragment>
			<FilterForm />
			<DataTable
				columns={columns}
				data={assignments}
				isLoading={isLoading}
				pagination={pagination}
				setPagination={setPagination}
				meta={meta}
				setSorting={setSorting}
				sorting={sorting}
			/>
		</React.Fragment>
	)
}

export default ListView
