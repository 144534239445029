import { Dialog, Transition } from '@headlessui/react'
import { useState } from 'react'
import { Button } from 'theme/ui/forms'
import notification from 'utilities/notification'

const AddFieldPopup = ({ isOpen, onClose, saveFields }) => {
	const [fieldData, setFieldData] = useState({ key: '', value: '' })

	const handleInputChange = (e) => {
		const { name, value } = e.target
		setFieldData((prev) => ({ ...prev, [name]: value }))
	}

	const handleFormSubmit = () => {
		if (!fieldData.key || !fieldData.value) {
			notification('warn', 'Both key and value fields are required.')
			return
		}
		saveFields({
			fields: [
				{
					key: fieldData.key,
					value: fieldData.value,
				},
			],
		})
		setFieldData({ key: '', value: '' })
		onClose()
	}
	return (
		<Transition
			show={isOpen}
			enter='transition duration-100 ease-out'
			enterFrom='transform scale-95 opacity-0'
			enterTo='transform scale-100 opacity-100'
			leave='transition duration-75 ease-out'
			leaveFrom='transform scale-100 opacity-100'
			leaveTo='transform scale-95 opacity-0'
			as='div'>
			<Dialog as='div' open={true} onClose={() => {}} className='popup-overlay relative'>
				<div className='fixed inset-0 bg-black/30' aria-hidden='true' />
				<div className='fixed inset-0 flex w-screen items-center justify-center p-4'>
					<Dialog.Panel className='w-96 rounded-md bg-white'>
						<div className='rounded-t px-8 py-4'>
							<div className='relative left-[8%] top-[8%]'>
								<button
									type='button'
									className='absolute -top-4 right-1'
									onClick={onClose}>
									<i className='fas fa-times text-lg' />
								</button>
							</div>

							<div className='mx-1 mt-0 flex items-center justify-start py-3 '>
								<h3 className='m-0 text-lg font-semibold'>Add custom field</h3>
							</div>
							<div className='flex flex-row gap-3 p-1'>
								<div>
									<label>Key</label>
									<input
										name='key'
										placeholder='Field key'
										className='form-control'
										value={fieldData.key}
										onChange={handleInputChange}
									/>
								</div>

								<div>
									<label>Value</label>
									<input
										name='value'
										placeholder='Field value'
										className='form-control'
										value={fieldData.value}
										onChange={handleInputChange}
									/>
								</div>
							</div>
							<div className='flex justify-end'>
								<Button
									className='mt-3 flex items-center rounded !border-gray-500 bg-main px-4 py-2'
									size='sm'
									onClick={handleFormSubmit}>
									<span className='text-white'>Add</span>
								</Button>
							</div>
						</div>
					</Dialog.Panel>
				</div>
			</Dialog>
		</Transition>
	)
}

export default AddFieldPopup
