import { Dialog, Transition } from '@headlessui/react'
import CreateFolderForm from 'modules/file-manager/search-bar/create-folder/create-folder-form'
import { Fragment, useState } from 'react'
import { Button } from 'theme/ui/forms'
import CopyToFolderForm from './copy-to-folder-form'

const CopyToFolderPopup = ({ isOpen, onClose, document, folder }) => {
	const [isFolderOpen, setIsFolderOpen] = useState(false)

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-[1008]' onClose={onClose}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/30' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'>
							<Dialog.Panel className='w-full max-w-lg transform overflow-hidden rounded-3xl bg-white p-2 text-left align-middle shadow-xl transition-all'>
								<div className='relative flex items-center justify-between rounded-3xl bg-blue-50 px-3 py-3'>
									{isFolderOpen ? (
										<h2 className='m-0 text-left text-xl font-bold leading-6 text-gray-900'>
											Create Folder
										</h2>
									) : (
										<h2 className='m-0 text-left text-xl font-bold leading-6 text-gray-900'>
											Copy folder &quot;
											{document?.title || folder?.title}
											&quot;
										</h2>
									)}

									<button type='button' onClick={onClose}>
										<i className='fas fa-times rounded-full bg-white px-2 py-1 text-[20px]' />
									</button>
								</div>
								<div className='p-3'>
									{isFolderOpen ? (
										<>
											<div className='mb-3'>
												<Button
													variant='ghost'
													type='button'
													onClick={() => setIsFolderOpen(false)}>
													<i className='uil-angle-left'></i> Back
												</Button>
											</div>
											<CreateFolderForm onClose={onClose} type='folder' />
										</>
									) : (
										<>
											<CopyToFolderForm
												folder={folder}
												document={document}
												onClose={onClose}
											/>
											<Button
												type='button'
												onClick={() => setIsFolderOpen(true)}
												variant='primary-outline'
												block={true}>
												+ Create new
											</Button>
										</>
									)}
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export default CopyToFolderPopup
