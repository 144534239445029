import { Field, reduxForm } from 'redux-form'

import { CustomCheckBox } from 'components/Forms2'
import { AssigneeDropdown, FilterDropdown, TagsDropdown } from '../common-filters'

const FilterForm = () => {
	return (
		<form>
			<div className='mb-3 flex items-center justify-between gap-3 alignment'>
				<Field
					type='search'
					name='search'
					className='form-control max-w-xs alignment_holder'
					placeholder='Search title, roles...'
					component='input'
				/>

				<div className='mb-3 flex gap-3 forced_break alignment_full'>
					<div className='flex gap-3 alignment_full'>
						<Field
							type='checkbox'
							name='mytask'
							label='My Tasks'
							id='mytask'
							component={CustomCheckBox}
						/>

						<div className='alignment_full'>
							<AssigneeDropdown />
						</div>
					</div>
					<div className='flex gap-3 alignment_full'>
						<div className='alignment_full'>
							<TagsDropdown />
						</div>
						<div className='alignment_full'>
							<FilterDropdown />
						</div>
					</div>
				</div>
			</div>
		</form>
	)
}

export default reduxForm({
	form: 'kanban_filter',
})(FilterForm)
