import {
	useRestoreDeletedDocumentMutation,
	useRestoreDeletedPackageMutation,
} from 'modules/documents/document-package-api'
import { DOCUMENT_TEMPLATE_APP_ROUTES } from 'modules/documents/routes'
import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import DeleteConfirmAlert from 'theme/ui/delete-confirm-alert'
import notification from 'utilities/notification'
import { useMediaQuery } from 'react-responsive'

const Actions = ({ isActionActive, type, data }) => {
	const isMobile = useMediaQuery({query: '(max-width: 1024px'})
	const shouldShowActions = isMobile || isActionActive
	const [isOpen, setIsOpen] = useState({
		open: false,
		id: undefined,
	})

	const onOpen = (id) => {
		setIsOpen({
			open: true,
			id,
		})
	}

	const onClose = () =>
		setIsOpen({
			open: false,
			id: undefined,
		})
	const [restoreDeletedDocument, { isLoading: isDocumentLoading }] =
		useRestoreDeletedDocumentMutation()
	const [restoreDeletedPackage, { isLoading: isPackageLoading }] =
		useRestoreDeletedPackageMutation()

	const onRestore = async () => {
		try {
			const id = isOpen?.id
			const restoreAction = type === 'folder' ? restoreDeletedPackage : restoreDeletedDocument

			const response = await restoreAction(id)

			if (response?.data?.status === 200) {
				notification('success', response?.data?.message)
			} else {
				notification('error', 'Failed to restore item.')
			}
		} catch (error) {
			notification('error', 'An error occurred while restoring the item.')
		} finally {
			onClose()
		}
	}

	return (
		<Fragment>
			<div
				className='float-start items-center justify-end space-x-2  text-right'
				onClick={(event) => event.stopPropagation()}>
				{shouldShowActions ? (
					<div className='flex flex-row gap-1'>
						<button
							type='button'
							className='action-btn btn-primary-outline'
							onClick={() => onOpen(data?.id)}>
							<Fragment>
								<i className='uil-repeat' style={{ fontSize: 16 }} />
								<span>Restore</span>
							</Fragment>
						</button>
						{type !== 'folder' && (
							<Link
								to={DOCUMENT_TEMPLATE_APP_ROUTES.findOne(data?.id)}
								className='action-btn btn-primary-outline'>
								<i className='uil-eye'></i>
								<span className='text-sm'>View</span>
							</Link>
						)}
					</div>
				) : undefined}
			</div>
			<DeleteConfirmAlert
				isOpen={isOpen?.open}
				title={'Restore Record?'}
				subtitle={'Are you sure you want to restore this record?'}
				onClose={onClose}
				isLoading={isDocumentLoading || isPackageLoading}
				onConfirm={onRestore}
				variant={'primary'}
			/>
		</Fragment>
	)
}

export default Actions
