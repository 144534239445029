import { useLazyGetNylasQuery } from 'modules/authentication/auth-api'
import Message from 'theme/ui/message'
import notification from 'utilities/notification'

const NylasNotification = ({ setShowMessage }) => {
	const [fetchNylas, { isLoading }] = useLazyGetNylasQuery()

	const fetchApi = async () => {
		try {
			const response = await fetchNylas().unwrap()

			if (response.status === 200) {
				const redirectUrl = response?.data
				if (redirectUrl) {
					window.location.href = redirectUrl
				}
			}
		} catch (error) {
			notification('error', error?.Message)
		}
	}
	return (
		<div className='p-2'>
			<Message variant={'info'} onClose={() => setShowMessage(false)}>
				<i className='uil uil-question-circle me-2'></i>
				<span className='font-bold'>Connect your email</span>
				<p className='ms-4 mt-2'>
					All workflow-related emails to your advisors and clients are currently being
					sent from the Experivise email address. If you’d prefer these emails to appear
					as coming directly from you, please connect your own email account here. Once
					connected, all workflow-related messages will be sent from your linked email
					address.
				</p>

				<button
					onClick={fetchApi}
					disabled={isLoading}
					className=' mx-4 mb-2 me-2 rounded-lg !border !border-gray-400 bg-white px-3 py-1  font-medium text-gray-900 hover:bg-gray-100'>
					{isLoading ? 'Loading..' : 'Link My Email Inbox'}
				</button>
			</Message>
		</div>
	)
}

export default NylasNotification
