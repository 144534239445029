import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import { useMemo } from 'react'
import { useDebounce } from 'use-debounce'
import { toQueryString } from 'utils/commonFunctions'

const ReviewerQuery = (search) => {
	const [debounceSearch] = useDebounce(search, 600)

	const defaultParams = { page: 1, limit: 20 }
	const searchParams = useMemo(() => {
		return debounceSearch ? { ...defaultParams, search: debounceSearch } : defaultParams
	}, [defaultParams, debounceSearch])

	const query = toQueryString(searchParams)
	const URL = `${apiEndpoints.user.users}?${query}`

	const { data, isLoading } = useQuery(URL)
	const reviewers = Array.isArray(data?.data?.users) ? data?.data?.users : []
	const options = useMemo(
		() =>
			Array.isArray(reviewers)
				? reviewers.map((reviewer) => ({
						label: (
							<span
								className='line-clamp-1 min-w-fit max-w-fit'
								title={`${reviewer?.first_name} ${reviewer?.last_name}`}>
								{reviewer?.first_name} {reviewer?.last_name}
							</span>
						),
						value: reviewer?.id,
				  }))
				: [],
		[reviewers],
	)

	return {
		options,
		isLoading,
	}
}

export default ReviewerQuery
