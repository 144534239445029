import classNames from 'classnames'
import CreateHouseholdModal from 'modules/households/household-create/household-create'
import { useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button } from 'theme/ui/forms'
import { isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'
import { CLIENT_APP_ROUTES } from '../routes'

const Header = () => {
	const location = useLocation()
	const isInvitePermission = isAllowTo(permissions?.client?.create)
	const isImportPermission = isAllowTo(permissions?.client?.import)
	const isGroupsPermission = isAllowTo(permissions?.groups?.create)
	const [isOpen, setIsOpen] = useState(false)
	const tabs = useMemo(
		() => [
			{
				label: 'Clients',
				href: '/clients',
				permission: isAllowTo(permissions?.client?.list),
			},
			// {
			// 	label: 'Invited clients',
			// 	href: '/invited-clients',
			// 	permission: isAllowTo(permissions?.client?.invitedList),
			// },
			{
				label: 'Groups',
				href: '/groups',
				permission: isAllowTo(permissions?.groups?.list),
			},
		],
		[],
	)

	const activeTab = useMemo(
		() => tabs.find((tab) => tab?.href === location?.pathname),
		[location?.pathname],
	)

	return (
		<div className='flex w-full flex-col'>
			<div className='mobile_alignment mb-3 flex w-full justify-between'>
				<div className='mb-3 max-w-[733px]'>
					<h4 className='page-title'>{activeTab?.label}</h4>
				</div>
				{activeTab?.href == '/groups' ? (
					<div className='flex items-center space-x-2'>
						{isGroupsPermission ? (
							<Button type='button' onClick={() => setIsOpen(true)}>
								Create Group
							</Button>
						) : undefined}
					</div>
				) : (
					<div className='flex items-center space-x-2'>
						{isImportPermission ? (
							<Link to={CLIENT_APP_ROUTES.import()}>
								<Button type='button' variant='primary-outline'>
									Import CSV
								</Button>
							</Link>
						) : undefined}

						{isInvitePermission ? (
							<Link to={CLIENT_APP_ROUTES.invite()}>
								<Button type='button'>Create Client</Button>
							</Link>
						) : undefined}
					</div>
				)}
			</div>
			<div className='-mb-[12px] space-x-2'>
				{tabs
					.filter((item) => item?.permission)
					.map((tab, i) => (
						<Link
							to={tab.href}
							key={i}
							className={classNames(
								'border-b-2 px-[10px] py-[10px] text-sm font-bold text-gray-400',
								{
									'border-main text-main': tab?.href == location?.pathname,
								},
							)}>
							{tab.label}
						</Link>
					))}
			</div>
			<CreateHouseholdModal isOpen={isOpen} setIsOpen={setIsOpen} />
		</div>
	)
}

export default Header
